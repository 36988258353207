import { useRef, useState } from "react";
import { ResponseGeneral, User } from "../common/types";

import * as EmailValidator from 'email-validator';

import "../pages/Account.scss";
import { toast } from "bulma-toast";
import Input from "./mini_components/Input";
import { madePost } from "../common/post";
import { onCatch } from "../common/functions";
import { Link, useNavigate } from "react-router-dom";
import { setItem } from "../common/useStorage";
import { useTranslation } from "react-i18next";

import "../common/translation";

const Register = (props: {
    onLogin: () => any,
    onRegisterSuccess: () => any
}) => {

    const [isCreating, setIsCreating] = useState(false);
    const [isValidForm, setIsValidForm] = useState({ isValid: false, isSending: false });
    const [selectedElement, setSelectedElement] = useState({ value: "" as "fire" | "soil" | "wind" | "water" | "tv" | "", isSelected: false });

    const navigate = useNavigate();

    const { t } = useTranslation();

    const nameRef = useRef({} as HTMLInputElement);
    const cellphoneRef = useRef({} as HTMLInputElement);
    const locationRef = useRef({} as HTMLInputElement);
    const birthdayRef = useRef({} as HTMLInputElement);
    const emailRef = useRef({} as HTMLInputElement);

    const passwordRef = useRef({} as HTMLInputElement);
    const repeatPasswordRef = useRef({} as HTMLInputElement);

    const acceptRef = useRef({} as HTMLInputElement);

    const validateForm = () => {
        try {
            if (
                nameRef.current.value.length > 0 &&
                cellphoneRef.current.value.length > 0 &&
                locationRef.current.value.length > 0 &&
                birthdayRef.current.value.length > 0 &&

                emailRef.current.value.length > 0 &&
                EmailValidator.validate(emailRef.current.value) &&

                passwordRef.current.value.length > 0 &&
                repeatPasswordRef.current.value.length > 0 &&

                passwordRef.current.value === repeatPasswordRef.current.value &&

                acceptRef.current.checked
            ) {
                setIsValidForm({ isValid: true, isSending: false });
            } else {
                setIsValidForm({ isValid: false, isSending: false });
            }
        } catch (e) {
        }
    }

    const onElementSelect = (element: "fire" | "soil" | "wind" | "water" | "tv") => {
        //Send form
        setSelectedElement({ value: element, isSelected: true });
        setIsValidForm({ isValid: true, isSending: true });
        toast({ message: "Tu cuenta se está creando", type: "is-info" })

        madePost(
            {
                key: "create-account",

                name: nameRef.current.value,
                cellphone: cellphoneRef.current.value,
                location: locationRef.current.value,
                birthday: birthdayRef.current.value,
                email: emailRef.current.value,
                password: passwordRef.current.value,

                element: element
            },
            "account.php"
        ).then(r => {
            let res = r as ResponseGeneral;

            if (res.RESULT) {
                toast({ message: "Tu cuenta se creó satisfactoriamente", type: "is-success" });

                setItem("USER", JSON.stringify(res.VALUE));

                props.onRegisterSuccess()
            } else {
                toast({ message: res.EXCEPTION_MESSAGE, type: "is-danger" });
                setSelectedElement({ value: "", isSelected: false });
                setIsValidForm({ isValid: true, isSending: false })
            }
        }).catch(onCatch)
    }

    return <>
        <section className="register-account ">
            <div className=" py-6">

                <h2 className="title is-5 has-text-weight-light mb-0">{t('create')}</h2>
                <h1 className="title is-3  has-text-weight-bold">{t('account')}</h1>

                <div className="field">
                    <label className="label">{t('fullName')}</label>
                    <div className="control">
                        <input className="input is-info" type="text" placeholder={t('fullName')}
                            ref={nameRef}
                            onInput={validateForm} />
                    </div>
                </div>

                <div className="field">
                    <label className="label">{t('cellphone')}</label>
                    <div className="control">
                        <input className="input is-info" type="number" placeholder={t('cellphone')}
                            ref={cellphoneRef}
                            onInput={validateForm} />
                    </div>
                </div>

                <div className="field">
                    <label className="label">{t('location')}</label>
                    <div className="control">
                        <input className="input is-info" type="string" placeholder={t('location')}
                            ref={locationRef}
                            onInput={validateForm} />
                    </div>
                </div>

                <div className="field">
                    <label className="label">{t('birthdate')}</label>
                    <div className="control">
                        <input className="input is-info" type="date" placeholder={t('birthdate')}
                            ref={birthdayRef}
                            onInput={validateForm} />
                    </div>
                </div>

                <Input innerRef={emailRef} title={t('email')} placeholder={t('email')} type="email" onInput={validateForm} onBlur={() => {
                    if (!EmailValidator.validate(emailRef.current.value)) {
                        toast({ message: t('invalidEmail'), type: "is-danger" })
                    }
                }} />

                <Input innerRef={passwordRef} title={t('password')} placeholder={t('password')} type="password" onInput={validateForm} />

                <Input innerRef={repeatPasswordRef} title={t('repeatPassword')} placeholder={t('repeatPassword')} type="password" onInput={validateForm}
                    onBlur={() => {
                        try {
                            if (repeatPasswordRef.current.value !== passwordRef.current.value) {
                                toast({ message: t('passwordMismatch'), type: "is-danger" })
                            }

                        } catch (e) {

                        }
                    }} />

                <div className="field mb-4">
                    <div className="control">
                        <label className="checkbox">
                            <input type="checkbox" id="accept-terms" className="mr-1" ref={acceptRef} onChange={validateForm} />
                            <label htmlFor="accept-terms">{t('acceptTerms')} <Link to={"/policy"} className="has-text-info"> {t('termsAndConditions')}</Link></label>
                        </label>
                    </div>
                </div>

                <div className="field is-grouped">
                    <div className="control">
                        <button className="button is-info" disabled={!(isValidForm.isValid && !isValidForm.isSending)} onClick={() => setIsCreating(true)} >{t('createAccountButton')}</button>
                    </div>
                    <div className="control">
                        <button className="button is-info is-regular is-light" onClick={props.onLogin}>{t('loginButton')}</button>
                    </div>
                </div>


            </div>
        </section>
        <>
            <div className={"modal modal-register " + (isCreating ? "is-active" : "")}>
                <div className="modal-background"></div>
                <div className="modal-card">

                    <section className="modal-card-body p-6">
                        <button className="delete" aria-label="close" disabled={selectedElement.isSelected} onClick={() => setIsCreating(false)}></button>

                        <div className="columns is-flex is-justify-content-center mb-5">
                            <div className="">
                                <h2 className="title is-5 has-text-weight-light mb-0 has-text-centered">{t('selectYour')}</h2>
                                <h1 className="title is-3 has-text-weight-bold has-text-centered">{t('element')}</h1>
                            </div>
                        </div>

                        <div className="columns elements-to-select">
                            <div className={"column " + (selectedElement.isSelected ? "only-selected" : "") + (selectedElement.value === "fire" ? " active" : "")} onClick={() => {
                                if (!selectedElement.isSelected) {
                                    onElementSelect("fire")
                                }
                            }}>
                                <div>
                                    <img src="/assets/images/elements/fire.png" alt="" className="columns  my-1 mx-0" />
                                    <h2 className="title is-5 has-text-weight-regular has-text-centered mb-0 fire">{t('fire')}</h2>
                                </div>
                            </div>
                            <div className={"column " + (selectedElement.isSelected ? "only-selected" : "") + (selectedElement.value === "soil" ? " active" : "")} onClick={() => {
                                if (!selectedElement.isSelected) {
                                    onElementSelect("soil")
                                }
                            }}>
                                <div>
                                    <img src="/assets/images/elements/soil.png" alt="" className="columns  my-1 mx-0" />
                                    <h2 className="title is-5 has-text-weight-regular has-text-centered mb-0 soil">{t('soil')}</h2>
                                </div>
                            </div>
                            <div className={"column " + (selectedElement.isSelected ? "only-selected" : "") + (selectedElement.value === "wind" ? " active" : "")} onClick={() => {
                                if (!selectedElement.isSelected) {
                                    onElementSelect("wind")
                                }
                            }}>
                                <div>
                                    <img src="/assets/images/elements/wind.png" alt="" className="columns  my-1 mx-0" />
                                    <h2 className="title is-5 has-text-weight-regular has-text-centered mb-0 wind">{t('wind')}</h2>
                                </div>
                            </div>
                            <div className={"column " + (selectedElement.isSelected ? "only-selected" : "") + (selectedElement.value === "water" ? " active" : "")} onClick={() => {
                                if (!selectedElement.isSelected) {
                                    onElementSelect("water")
                                }
                            }}>
                                <div>
                                    <img src="/assets/images/elements/water.png" alt="" className="columns  my-1 mx-0" />
                                    <h2 className="title is-5 has-text-weight-regular has-text-centered mb-0 water">{t('water')}</h2>
                                </div>
                            </div>
                            <div className={"column " + (selectedElement.isSelected ? "only-selected" : "") + (selectedElement.value === "tv" ? " active" : "")} onClick={() => {
                                if (!selectedElement.isSelected) {
                                    onElementSelect("tv")
                                }
                            }}>
                                <div>
                                    <img src="/assets/images/elements/propachatv.png" alt="" className="columns  my-1 mx-0" />
                                    <h2 className="title is-5 has-text-weight-regular has-text-centered mb-0 tv">{t('tv')}</h2>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    </>
}
export default Register;