import { toast } from "bulma-toast";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { cloneObject, onCatch } from "../common/functions";
import { madePost } from "../common/post";
import { Project, ResponseGeneral, User } from "../common/types";
import { getUser, isLogged } from "../common/user";
import { getItem, setItem } from "../common/useStorage";
import { home } from "../common/variables";
import { Title } from "../components/Components";
import Footer from "../components/Footer";
import Loader from "../components/mini_components/Loader";

import "../common/translation";


import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from "swiper";

import "./Proyecto.scss";
import { useTranslation } from "react-i18next";
const Proyecto = () => {
    let { name } = useParams<{ name: string }>();

    const {t} = useTranslation();

    const [project, setProject] = useState({ value: {} as Project, isLoaded: false });
    const [participants, setParticipants] = useState({ values: [] as { user_code: number, name: string }[], isLoaded: false });
    //const [confirmed, setConfirmed] = useState({ values: [] as any[], isLoaded: false });
    const [isParticipating, setIsParticipating] = useState(false);
    const [isConfirmed, setISConfirmed] = useState(false);
    const [iamhereSetted, setIamhereSetted] = useState(false);

    const contentInfo = useRef({} as HTMLDivElement);

    const navigate = useNavigate();

    useEffect(() => {
        if (!project.isLoaded) {
            madePost(
                {
                    key: "get-project",
                    code: name!.split("-").pop()
                },
                "projects.php"
            ).then(r => {
                let res = r as ResponseGeneral;

                if (res.RESULT) {
                    let pr: Project = res.VALUE;
                    try {
                        pr.images = JSON.parse(pr.images as unknown as any);
                        if (typeof pr.confirmed == "string") {
                            pr.confirmed = JSON.parse(pr.confirmed) as any[];

                            //                          setConfirmed({ values: pr.confirmed as any[], isLoaded: true })
                        } else {
                            //                        setConfirmed({ values: pr.confirmed as any[], isLoaded: true })
                        }
                    } catch (er) {

                    }
                    setProject({ value: pr, isLoaded: true });
                }
            })
        } else {
            if (!participants.isLoaded) {
                madePost(
                    {
                        key: "get-participants-from",
                        project_code: project.value.project_code
                    },
                    "projects.php"
                ).then((r => {
                    let res = r as ResponseGeneral;

                    if (res.RESULT) {
                        setParticipants({ values: res.VALUE, isLoaded: true });
                    }
                }))
            }
        }

        if (project.isLoaded && isLogged()) {
            try {
                let confir: string[] = JSON.parse(project.value.confirmed as unknown as string);

                if (confir.includes(getUser()?.user_code.toString()!)) {
                    setISConfirmed(true);
                }
            } catch (err) { }
        }
    }, [project])

    useEffect(() => {
        if (participants.isLoaded) {
            setIsParticipating((participants.values.findIndex((e: { name: string, user_code: any }) => { return getUser()?.user_code == e.user_code })) != -1)
        }
    }, [participants])

    const adminParticipate = () => {
        madePost(
            {
                key: (isParticipating ? "cancel-participation" : "confirm-participation"),
                user_code: getUser()!.user_code,
                project_code: project.value.project_code
            },
            "projects.php"
        ).then(r => {
            let res = r as ResponseGeneral;

            if (res.RESULT) {
                try {
                    toast({ message: (!isParticipating ? "Has confirmado tu participación" : "Cancelaste tu participación"), type: "is-success" })

                    if (isParticipating) {
                        let cl = cloneObject(participants.values);
                        let user: User = getUser() as User;
                        cl = cl.filter((e: { name: string, user_code: any }) => { return e.user_code != user.user_code });

                        setParticipants({ values: cl, isLoaded: participants.isLoaded })
                    } else {
                        let cl = cloneObject(participants.values);
                        let user: User = getUser() as User;
                        cl.push({ name: user.name, user_code: user.user_code });

                        setParticipants({ values: cl, isLoaded: participants.isLoaded })
                    }

                    setIsParticipating(!isParticipating);
                } catch (err) {
                    
                }

            } else {
                toast({ message: "No se pudo realizar, contactate con: " + project.value.contact_info + "", type: "is-danger" });
            }
        }).catch(onCatch)
    }

    const confirmParticipation = () => {
        if (isLogged()) {
            // eslint-disable-next-line no-restricted-globals
            let r = confirm("Estás seguro de " + (isParticipating ? "cancelar" : "confirmar") + " tu participación? " + (isParticipating ? "" : " Al participar aceptas nuestros términos de uso y privacidad"));

            if (r) {
                if (!isParticipating) {
                    //control if has space in proj 
                    //console.debug(parseInt(project.value.limit_participants), JSON.parse(project.value.participant as unknown as any).length)
                    if (project.value.limit_participants != 0) {
                        if (JSON.parse(project.value.participant as unknown as any).length >= (parseInt(project.value.limit_participants))) {
                            //limit exceed
                            toast({ message: "Límite de participantes excedido, contacta con el organizador", type: "is-danger" })
                        } else {
                            adminParticipate();
                        }
                    } else {
                        adminParticipate();
                    }

                } else {
                    adminParticipate();
                }
            }
        } else {
            toast({ message: "Para administrar tu participación en una actividad debes iniciar sesión en tu cuenta", type: "is-info" });

            let actualPath = window.location.pathname

            navigate("/account");

            window.addEventListener("logged", (e: any) => {
                navigate(actualPath)
            })
        }
    }

    const iamhere = () => {
        setIamhereSetted(true);
        madePost(
            {
                key: "addiamhere",
                ucode: getUser()!.user_code,
                pcode: project.value.project_code
            },
            "projects.php"
        ).then(r => {
            if ((r as ResponseGeneral).RESULT) {
                toast({ message: "Se ha notificado tu participación a los organizadores", type: "is-info" });
            } else {
                setIamhereSetted(false);
                toast({ message: "Ha ocurrido un error, intenta nuevamente más tarde", type: "is-danger" });
            }
        }).catch((err) => {
            setIamhereSetted(false);
            toast({ message: "Ha ocurrido un error, intenta nuevamente más tarde", type: "is-danger" });
        })
    }

    useEffect(() => {
        if (project.isLoaded) {
            setTimeout(() => {
                try {
                    contentInfo.current.style.marginBottom = "-" + ((contentInfo.current.getBoundingClientRect().height / 2) - 60) + "px";
                } catch (er) {
                    contentInfo.current.style.marginBottom = "-12rem";
                }
            }, 100)
        }
    }, [project])

    return <div className="proyecto-page ">
        {
            project.isLoaded ?
                <>
                    <img src={project.value.images[0]} alt="" />
                    <div className="content-info" ref={contentInfo}>
                        <div>
                            <h4>{project.value.name}</h4>

                            <div>
                                <div>
                                    <small>{project.value.date.split(" ")[0].split("-").reverse().map((e: string, ind: number) => { if (ind !== 2) { return e + "/"; } else { return e; } })} a las {project.value.date.split(" ")[1].slice(0, 5)}</small>
                                    <small>Coordinación: <b><i>{project.value.contact_info}</i></b></small>
                                </div>

                                <small>+<strong>{project.value.points} Puntos</strong> </small>
                            </div>

                            <hr />

                            <p dangerouslySetInnerHTML={{ __html: project.value.description }}></p>

                            {
                                <div className="controllers-project">
                                    {
                                        isConfirmed ?
                                            <>
                                                <button className={"button is-success"} style={{ cursor: "default" }}>
                                                    Ya participé
                                                </button>
                                            </> :
                                            <>
                                                {
                                                    !iamhereSetted && isParticipating && (!project.value.isNowAfter && !project.value.isNowBefore) ?
                                                        <button className="is-success button" onClick={iamhere}>
                                                            Ya estoy allí
                                                        </button>
                                                        :
                                                        <></>
                                                }
                                                <button className={"button " + (isParticipating ? "is-danger" : "is-info")} onClick={confirmParticipation} >
                                                    {isParticipating ? "Cancelar mi participación" : "Quiero participar"}
                                                </button>
                                            </>
                                    }
                                    {/* <button className="button is-success"><i className="bi bi-qr-code"></i></button> */}
                                </div>

                            }
                        </div>
                    </div>

                    <div className="project-info-content">
                        {
                            participants.isLoaded ?
                                participants.values.length > 0 ?
                                    <div className="participants-container">
                                        <small>Participan: </small>
                                        {
                                            (participants.values).slice(0, 5).map((p: { user_code: any, name: any }, ind: number) => {
                                                if (p.name != null && p.user_code != null) {
                                                    return <img key={"image-participant-" + ind} style={{ zIndex: (ind + 100) }} src={home + "profiles/" + p.user_code + "-" + p.name.toLowerCase().replaceAll(" ", "-") + ".jpeg"} onError={(ev: any) => {
                                                        ev.target.style.display = "none";
                                                    }} alt="" />
                                                }
                                                return <></>
                                            })
                                        }
                                        <small> {" +" + participants.values.length}</small>
                                    </div> :
                                    <></>
                                :
                                <></>
                        }
                        <hr />

                        {/*
                            project.value.images.length > 1 ?
                                <>
                                    <Title sup="Fotografias de la" sub="Actividad" color="green" />

                                    <div className="images-project">
                                        {
                                            project.value.images.slice(1).map((img: string) => {
                                                return <img src={img} alt="Actividades propacha, imagen" />
                                            })
                                        }
                                    </div>
                                </>
                                :
                                <></>
                                */
                        }

                        <Swiper
                            style={{ maxWidth: "calc(80vw)", width: "100%" }}
                            slidesPerView={1}
                            spaceBetween={50}
                            breakpoints={{
                                600: {
                                    slidesPerView: 2
                                },
                                900: {
                                    slidesPerView: 3
                                }
                            }}
                            autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                            pagination={{
                                clickable: true,
                            }}
                            modules={[Autoplay, Pagination]}

                            className="our-projects-swiper"
                        >
                            {
                                project.value.images.slice(1).map((img: string) => {
                                    return <SwiperSlide >
                                        <img src={img} alt="Actividades propacha, imagen" />
                                    </SwiperSlide>
                                })
                            }
                        </Swiper>

                    </div>


                </>
                :
                <Loader />
        }

        <hr style={{ width: "100%" }} className="my-5 mt-6" />

        <Footer />
    </div >
}

export default Proyecto;