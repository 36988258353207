import { toast } from "bulma-toast";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import { cloneObject, onCatch } from "../common/functions";
import { madePost } from "../common/post";
import { Project, ResponseGeneral, User } from "../common/types";
import { home } from "../common/variables";
import InputRich from "../components/mini_components/InputRich";

import { QRCodeCanvas } from 'qrcode.react';

import "./Admin.scss";
import { saveAs } from "file-saver";
import Loader from "../components/mini_components/Loader";
import { Input } from "../components/Comps";
import { getUser } from "../common/user";
import { useNavigate } from "react-router-dom";
import { Title } from "../components/Components";
import Footer from "../components/Footer";

const ProjectCardAdmin = (props: {
    project: Project
    onEdit: (pr: Project) => any
}) => {
    const [project, setProject] = useState(props.project);
    const [users, setUsers] = useState({ confirm: [] as any, participate: [] as any, refered: [] as any, isView: false, isLoaded: false });

    const [lists, setLists] = useState({ users: [] as { visible: 1 | 0, code: any, name: string, date: string, email: string, cellphone: string, user_code: number, valid: 1 | 0 }[], isLoaded: false });

    useEffect(() => {
        try {
            let cl: Project = cloneObject(project);
            cl.images = JSON.parse(cl.images as unknown as string);

            setProject(cl)
        } catch (err) {

        }

    }, [props])

    const reload = (callback: () => any) => {
        setLists({ users: lists.users, isLoaded: false })
        madePost(
            {
                key: "get-lists-user-project-prev-event",
                pcode: project.project_code,
            },
            "projects.php"
        ).then((r => {
            let res = r as ResponseGeneral;

            if (res.RESULT) {
                setLists({ users: res.VALUE, isLoaded: true });
                setTimeout(() => {
                    callback()
                }, 200)
            }
        })).catch(onCatch)
    }

    const hidde = () => {
        // eslint-disable-next-line no-restricted-globals
        let conf = confirm("Está seguro de " + (project.active == 1 ? "esconder" : "mostrar") + " este elemento de la lista de proyectos en el sitio web?");

        if (conf) {
            madePost(
                {
                    key: project.active == 1 ? "hidde-project" : (project.active == 0 ? "unhidde-project" : ""),
                    project_code: project.project_code
                },
                "projects.php"
            ).then(r => {
                let res = r as ResponseGeneral;

                if (res.RESULT) {
                    toast({ message: "Proyecto modificado correctamente, puedes habilitarlo en cualquier momento", type: "is-success" })

                    let cl: Project = cloneObject(project);
                    cl.active = (project.active == 1 ? 0 : 1);

                    setProject(cl);
                } else {
                    toast({ message: "Ha ocurrido un error, contacta a soporte", type: "is-danger" })
                }
            }).catch(onCatch);
        }
    }

    const viewAnalytics = () => {

        let prepareRefered = (value: any) => {
            let userss: any[] = [];
            let confirmed = value.filter((e: any) => { return e.type == "participant" });

            confirmed.map((e: any) => {
                let es = e;
                let finded = value.find((eee: any) => { return eee.type != "participant" && eee.user_code == e.user_code });

                es["confirm"] = finded ? true : false;

                //

                es["date_started"] = finded ? (finded["date_started"] == 'null' ? null : finded["date_started"]) : null;
                es["date_concluded"] = finded ? (finded["date_concluded"] == 'null' ? null : finded["date_concluded"]) : null;
                es["points"] = finded ? (finded["points"] == 'null' ? null : finded["points"]) : null;

                userss.push(es)
            })

            return userss;
        }

        if (!users.isLoaded) {
            madePost(
                {
                    key: 'get-users-from-event',
                    participant: project.participant,
                    confirmed: project.confirmed,
                    pcode: project.project_code
                },
                "projects.php"
            ).then(r => {
                let res = r as ResponseGeneral;

                if (res.RESULT) {
                    setUsers({ confirm: res.VALUE.filter((e: any) => { return e.type == "participant" }), participate: res.VALUE.filter((e: any) => { return e.type != "participant" }), isLoaded: true, isView: true, refered: prepareRefered(res.VALUE) });
                } else {
                    toast({ message: "Ha ocurrido un error, contacta a soporte", type: "is-danger" })
                }
            }).catch(onCatch);
        }

        if (!lists.isLoaded) {
            madePost(
                {
                    key: "get-lists-user-project-prev-event",
                    pcode: project.project_code,
                },
                "projects.php"
            ).then((r => {
                let res = r as ResponseGeneral;

                if (res.RESULT) {
                    setLists({ users: res.VALUE, isLoaded: true });
                }
            })).catch(onCatch)
        }

        setUsers({ confirm: users.confirm, participate: users.participate, isLoaded: users.participate, isView: true, refered: users.refered });
    }

    const ListElementPrevEv = (pp: { u: any, idx: number }) => {
        const [u, setU] = useState(pp.u);
        const [isChanged, setIsChanged] = useState({ is: false, newVal: pp.u.date.split(" ")[1] });

        const onConfirm = () => {
            madePost(
                {
                    key: "manage-assistance-prev",
                    code: pp.u.code,
                    pcode: project.project_code,
                    ucode: pp.u.user_code,
                    date: pp.u.date.split(" ")[0] + " " + isChanged.newVal,
                    type: "confirm"
                },
                "projects.php"
            ).then(r => {
                let uui = JSON.parse(JSON.stringify(u));
                uui["valid"] = 1;

                setU(uui);
            }).catch(onCatch)
        }
        const onDecline = () => {
            madePost(
                {
                    key: "manage-assistance-prev",
                    code: pp.u.code,
                    pcode: project.project_code,
                    ucode: pp.u.user_code,
                    date: pp.u.date.split(" ")[0] + " " + isChanged.newVal,
                    type: "decline"
                },
                "projects.php"
            ).then(r => {
                let uui = JSON.parse(JSON.stringify(u));
                uui["visible"] = 0;

                setU(uui);
            }).catch(onCatch)
        }

        if (u.visible == 0) {
            return <></>
        }

        return <div key={"list-el-" + pp.idx}>
            <div></div>
            <span>
                {u.name}
                <small>{u.cellphone}</small>
            </span>
            {
                u.valid == 0 ?
                    <>
                        <div style={{ display: "flex", flexFlow: "nowrap column", alignItems: "flex-start", margin: "0 1rem 0 auto" }}>
                            <small style={{ margin: "0" }}>Inicio en (Cambiar si corresponde)</small>
                            <input onChange={(ev: any) => {
                                setIsChanged({ is: true, newVal: ev.target.value })
                            }} style={{ border: "none" }} type="time"
                                defaultValue={isChanged.newVal} />
                        </div>

                        <div style={{ display: "flex", alignItems: "stretch", justifyContent: "flex-end", gap: ".5rem" }}>
                            <button className="button is-success" onClick={onConfirm}><i className="bi bi-check text-white"></i></button>
                            <button className="is-danger button" onClick={onDecline}><i className="bi bi-x"></i></button>
                        </div>
                    </>
                    :
                    <>
                        <div style={{ display: "flex", flexFlow: "nowrap row", alignItems: "center", marginLeft: "auto", justifyContent: "flex-end" }}>
                            <span style={{ margin: "0 .25rem" }}>{u.date}</span>
                            <i className="bi bi-check"></i>
                        </div>
                    </>
            }
        </div>
    }

    const UsersConfirm = () => {
        const [visible, setVisible] = useState(users.refered);
        const [tab, setTab] = useState(0);
        return <div className="participate-confirm-admin">
            <i className="bi bi-x" onClick={() => {
                setUsers({ confirm: users.confirm, participate: users.participate, isLoaded: users.participate, isView: false, refered: users.refered });
            }}></i>
            <div className="is-flex is-flex-row " style={{ border: "none", paddingBottom: "0", marginBottom: "0" }}>
                <h5 onClick={() => { setTab(0) }} style={{ margin: "0", padding: ".5rem 1rem", borderRight: "1px solid rgba(0, 0, 0, 0.2)", cursor: "pointer", borderBottom: (tab == 0? "2px solid rgba(0,0,0,0.2)" : "none") }}>
                    Listas
                </h5>
                <h5 onClick={() => { setTab(1) }} style={{ margin: "0", padding: ".5rem 1rem", cursor: "pointer", borderBottom: (tab == 1? "2px solid rgba(0,0,0,0.2)" : "none") }}>
                    Participantes
                </h5>

            </div>
            {
                tab == 1 ?
                    <>
                        <div style={{ justifyContent: "flex-end" }}>
                            <div>
                                <small><i className="bi bi-check"></i> Participó</small>
                                <small><i className="bi bi-emoji-smile"></i> Confirmó</small>
                            </div>
                        </div>
                        <div>
                            <input className="input is-info is-small" type="search" placeholder="Buscar" onInput={(ev: any) => {
                                if (ev.target.value.length > 0) {
                                    let finded = users.refered.filter((en: any) => {
                                        return en.name.toLowerCase().includes(ev.target.value.toLowerCase()) || en.cellphone.includes(ev.target.value);
                                    });
                                    setVisible(finded);
                                } else {
                                    setVisible(users.refered);
                                }
                            }} />
                        </div>
                        {
                            users.isLoaded ?
                                visible.map((e: any) => {
                                    return <div>
                                        <div>

                                            <i className="bi bi-emoji-smile"></i>

                                        </div>
                                        <span>
                                            {e.name}
                                            <small><i>{e.cellphone}</i></small>
                                            <p style={{ marginBottom: "0" }}>
                                                {
                                                    e["date_started"] != null ?
                                                        <small style={{ display: "inline-block", marginRight: ".5rem", fontSize: ".7rem" }}><b>Comenzó: </b>{(new Date(e["date_started"])).toLocaleTimeString()}</small>
                                                        :
                                                        <></>
                                                }
                                                {
                                                    e["date_concluded"] != null ?
                                                        <small style={{ display: "inline-block", marginRight: ".5rem", fontSize: ".7rem" }}><b>Concluyó: </b>{(new Date(e["date_concluded"])).toLocaleTimeString()}</small>
                                                        :
                                                        <></>
                                                }
                                                {
                                                    e["points"] != null ?
                                                        <small style={{ display: "inline-block", fontSize: ".7rem" }}><b>Puntos: +</b>{e["points"]}</small>
                                                        :
                                                        <></>
                                                }
                                            </p>
                                        </span>
                                        {
                                            e.confirm ?
                                                <i className="bi bi-check" style={{ marginLeft: "auto" }}></i>
                                                :
                                                <></>
                                        }
                                    </div>
                                })
                                :
                                <Loader />
                        }
                    </>
                    :
                    <>
                        {
                            lists.isLoaded ?
                                <>
                                    <div className="" style={{ justifyContent: "flex-end" }}>
                                        <button className="button is-info" onClick={() => {
                                            reload(() => { });

                                        }}>
                                            <span>Actualizar</span>
                                            <i className="bi bi-arrow-clockwise" style={{ color: "white", fontSize: "1.5rem" }}></i>
                                        </button>

                                    </div>
                                    {
                                        lists.users.map((u: any, idx: number) => {
                                            if (u.visible == 0) {
                                                return <></>
                                            } else {
                                                return <ListElementPrevEv u={u} idx={idx} />
                                            }
                                        })
                                    }
                                </>
                                :
                                <Loader />
                        }
                    </>
            }

        </div>
    }

    return <div className="card-project-admin">
        <img src={project.images[0]} alt="" />

        <QRCodeCanvas value={home + "qr-project?code=" + project.code + "&proj=" + project.project_code} style={{ zIndex: -1000, position: "absolute", top: "-50000px" }} id={"qr-code-" + project.project_code} />
        {
            users.isView ?
                <div className={"modal modal-admin-con is-active"}>
                    <UsersConfirm />

                </div>
                :
                <></>
        }


        <div>
            <div>
                <span>{project.name}</span>
                <p dangerouslySetInnerHTML={{ __html: (project.description.length > 260 ? project.description.slice(0, 160) + "[...]" : project.description) }}></p>
            </div>

            <div className="is-flex is-flex-direction-column is-align-items-end">
                <div className="mb-2">
                    <button className="button is-secondary mr-2" onClick={viewAnalytics}>
                        <i className="bi bi-graph-up"></i>
                    </button>
                    {
                        project.consolidate == 0 ?
                            <button className="button is-secondary mr-2" onClick={() => {
                                // eslint-disable-next-line no-restricted-globals
                                let c = confirm("Esta seguro de dar por concluido la actividad? Se penalizará a los no usuarios que confirmaron pero no participaron");

                                if (c) {
                                    madePost(
                                        {
                                            key: "complete-project",
                                            project_code: project.project_code
                                        },
                                        "projects.php"
                                    ).then(r => {
                                        let res = r as ResponseGeneral;

                                        if (res.RESULT) {
                                            toast({ message: "Realizado correctamente", type: "is-success" });

                                            let p: Project = JSON.parse(JSON.stringify(project));
                                            p.consolidate = 1;

                                            setProject(p);
                                        } else {
                                            toast({ message: ("Ha ocurrido un error: " + res.EXCEPTION_MESSAGE), type: "is-danger" })
                                        }
                                    }).catch(onCatch)
                                }
                            }}>
                                <i className="bi bi-check-lg"></i>
                            </button>
                            :
                            <></>
                    }
                    {
                        project.consolidate == 0 ?
                            <button className="button is-info" onClick={async () => {
                                const canvas: HTMLCanvasElement = document.getElementById("qr-code-" + project.project_code) as HTMLCanvasElement;

                                canvas.toBlob((blob: any) => {
                                    saveAs(blob, ("QR del proyecto " + project.name));
                                });
                            }}>
                                <i className="bi bi-qr-code"></i>
                            </button>
                            :
                            <></>
                    }

                </div>
                <div>
                    <button className="button is-success mr-2" onClick={() => {
                        props.onEdit(project)
                    }}>Editar</button>
                    <button className={"button " + (project.active == 1 ? "is-secondary" : "is-info")} onClick={hidde}>{project.active == 1 ? "Ocultar" : "Mostrar"}</button>
                </div>
            </div>
        </div>
    </div>
}

const ManageForm = (props: {
    project: Project,
    action: string,
    onCancel: () => any,
    onSave: (pr: Project, action: "edit" | "create") => any
}) => {
    const [project, setProject] = useState(props.project);

    let [isEdit] = useState(props.action == "edit" ? true : false);

    const [isValid, setIsValid] = useState(false);
    const [nameDefined, setNameDefined] = useState(isEdit ? true : false);

    const nameRef = useRef({} as HTMLInputElement);
    const descriptionRef = useRef({} as HTMLInputElement);
    const dateRef = useRef({} as HTMLInputElement);
    const pointsRef = useRef({} as HTMLInputElement);
    const durationRef = useRef({} as HTMLInputElement);
    const contactInfoRef = useRef({} as HTMLInputElement);
    const limitRef = useRef({} as HTMLInputElement);


    useEffect(() => {
        analize();
    }, [project])

    const save = () => {
        madePost(
            {
                key: (isEdit ? "update-project" : "create-project"),

                project_code: isEdit ? project.project_code : -1,

                name: nameRef.current.value,
                description: descriptionRef.current.value,
                date: dateRef.current.value.replaceAll("T", " "),
                points: pointsRef.current.value,
                duration: durationRef.current.value,
                contact: contactInfoRef.current.value,
                images: JSON.stringify(project.images.filter((im: string) => { return im.length > 0 })),
                limit: (limitRef.current.value == "0"
                    || limitRef.current.value == undefined
                    || limitRef.current.value == null ? 0 : limitRef.current.value)
            },
            "projects.php"
        ).then(r => {
            let res = r as ResponseGeneral;

            if (res.RESULT) {
                toast({ message: "Proyecto " + (isEdit ? "editado" : "creado") + " satisfactoriamente", type: "is-success" });

                let edr: { NEW_CODE: any, CODE: any } = { NEW_CODE: 0, CODE: "" };

                try {
                    let edr: { NEW_CODE: any, CODE: any } = JSON.parse(JSON.stringify(res.VALUE)) as { NEW_CODE: any, CODE: any };
                } catch (err) {

                }

                let pr: Project = {
                    project_code: isEdit ? project.project_code : edr.NEW_CODE,

                    name: nameRef.current.value,
                    description: descriptionRef.current.value,
                    date: dateRef.current.value.replaceAll("T", " "),
                    points: parseInt(pointsRef.current.value),
                    duration: parseInt(durationRef.current.value),
                    contact_info: contactInfoRef.current.value,
                    images: project.images,
                    code: isEdit ? project.code : edr.CODE,
                    qr: "",
                    active: 1,
                    participant: "[]",
                    confirmed: "[]",
                    limit_participants: limitRef.current.value
                }

                props.onSave(pr, (isEdit ? "edit" : "create"));

            } else {
                toast({ message: res.EXCEPTION_MESSAGE, type: "is-danger" })
            }

        }).catch(err => {
            onCatch(err);
        })
    }

    const analize = () => {

        try {
            if (nameRef.current.value.length > 0) {
                setNameDefined(true);
            } else {
                setNameDefined(false);
            }

            if (nameRef.current.value.length > 0 &&
                descriptionRef.current.value.length > 0 &&
                dateRef.current.value.length > 0 &&
                pointsRef.current.value.length > 0 &&
                durationRef.current.value.length > 0 &&
                contactInfoRef.current.value.length > 0 &&
                project.images.length > 0) {
                setIsValid(true)
            } else {
                setIsValid(false);
            }
        } catch (err) { }
    }

    return <div className="container edit-project-admin">
        <div className="field">
            <label className="label">Nombre del proyecto</label>
            <div className="control">
                <input onInput={analize} ref={nameRef} disabled={isEdit} className="input" type="text" placeholder="Nombre" defaultValue={project.name ? project.name : ""} />
            </div>
        </div>

        <div className="field">
            <label className="label">Descripción</label>

            <div className="control">
                <InputRich onInput={analize} innerRef={descriptionRef} placeholder={"Descripción"} defaultValue={project.description ? project.description : ""} />
            </div>
        </div>

        <div className="field">
            <label className="label">Fecha y hora</label>

            <div className="control">
                <input onInput={analize} ref={dateRef} type="datetime-local" className="input" defaultValue={project.date ? project.date.replaceAll(" ", "T") : (new Date() as any).toDateInputValue() + "T12:00"} />
            </div>
        </div>

        <div className="field">
            <label className="label">Puntos (Lo ganará el voluntario al concluir el proyecto)</label>

            <div className="control">
                <input onInput={analize} ref={pointsRef} type="number" className="input" defaultValue={project.points ? project.points : 1} />
            </div>
        </div>

        <div className="field">
            <label className="label">Duración (En horas)</label>

            <div className="control">
                <input onInput={analize} ref={durationRef} type="number" className="input" defaultValue={project.duration ? project.duration : 1} />
            </div>
        </div>

        <div className="field">
            <label className="label">Límite de participantes (0 para evento sin límite de participantes)</label>

            <div className="control">
                <input onInput={analize} ref={limitRef} type="number" className="input" defaultValue={0} />
            </div>
        </div>

        <div className="field">
            <label className="label">Información de contacto (Número de celular, correo o nota)</label>

            <div className="control">
                <input onInput={analize} ref={contactInfoRef} type="text" className="input" placeholder="Contacto" defaultValue={project.contact_info ? project.contact_info : ""} />
            </div>
        </div>

        {
            nameDefined ?
                <div className="field">
                    <label className="label">Imagenes</label>
                    <div className="images-project-admin-mod">
                        <span className="pb-2">Imagen de portada</span>
                        {
                            project.images.length > 0 ?
                                <div className="is-flex img-cover">
                                    <img src={project.images[0]} alt="" />
                                    <div>
                                        <input type="file" accept=".png, .jpg, .jpeg"
                                            onChange={(ev: any) => {
                                                if (ev.target.files.length > 0) {
                                                    if (ev.target.files[0].size <= 3145728) {
                                                        toast({ message: "Subiendo imagen", type: "is-info" });

                                                        madePost(
                                                            {
                                                                key: "upload-image",
                                                                image: ev.target.files[0],
                                                                ext: ev.target.files[0].name.split(".").pop(),
                                                                project_name: nameRef.current.value,
                                                                num: project.images.length - 1
                                                            },
                                                            "projects.php"
                                                        ).then(r => {
                                                            let res = r as ResponseGeneral;

                                                            if (res.RESULT) {
                                                                let cl: Project = cloneObject(project);

                                                                cl.images[0] = res.VALUE;

                                                                setProject(cl);

                                                                toast({ message: "Imagen subida satisfactoriamente", type: "is-success" })
                                                            } else {
                                                                toast({ message: res.EXCEPTION_MESSAGE, type: "is-danger" })
                                                            }
                                                        }).catch(onCatch)
                                                    } else {
                                                        toast({ message: "La imagen no puede pesar más de 3MB", type: "is-danger" })
                                                    }

                                                }
                                            }} />
                                        <i className="bi bi-x"></i>
                                    </div>

                                </div>
                                :
                                <button className="button is-success">Agregar imagen de portada</button>
                        }

                        {
                            project.images.length > 1 ?
                                <>
                                    <span className="my-2 pt-5">Imagenes de la actividad</span>

                                    <div className="cards-manager-admin-images">
                                        {
                                            project.images.slice(1).map((img: string, index: number) => {
                                                if (img.length > 0) {
                                                    return <div className="is-flex img-cover my-2">
                                                        <img src={img} alt="" />
                                                        <div>
                                                            <i className="bi bi-x" onClick={() => {
                                                                let cl: Project = cloneObject(project);
                                                                cl.images = cl.images.filter((i: string, ind: number) => {
                                                                    return i !== img
                                                                });

                                                                setProject(cl);
                                                            }}></i>
                                                        </div>

                                                    </div>
                                                }

                                                return <div className="is-flex img-cover my-2 add-image-card">
                                                    <i className="bi bi-plus"></i>

                                                    <input type="file" accept=".png, .jpg, .jpeg" onChange={(ev: any) => {
                                                        if (ev.target.files.length > 0) {
                                                            if (ev.target.files[0].size <= 3145728) {
                                                                toast({ message: "Subiendo imagen", type: "is-info" });

                                                                madePost(
                                                                    {
                                                                        key: "upload-image",
                                                                        image: ev.target.files[0],
                                                                        ext: ev.target.files[0].name.split(".").pop(),
                                                                        project_name: nameRef.current.value,
                                                                        num: project.images.length - 1
                                                                    },
                                                                    "projects.php"
                                                                ).then(r => {
                                                                    let res = r as ResponseGeneral;

                                                                    if (res.RESULT) {
                                                                        let cl: Project = cloneObject(project);
                                                                        let imgs: string[] = [];
                                                                        cl.images.map((img: string) => {
                                                                            if (img.length > 0) {
                                                                                imgs.push(img)
                                                                            }
                                                                        })
                                                                        imgs.push(res.VALUE)
                                                                        imgs.push("");

                                                                        cl.images = imgs;

                                                                        setProject(cl);

                                                                        toast({ message: "Imagen subida satisfactoriamente", type: "is-success" })
                                                                    } else {
                                                                        toast({ message: res.EXCEPTION_MESSAGE, type: "is-danger" })
                                                                    }
                                                                }).catch(onCatch)
                                                            } else {
                                                                toast({ message: "La imagen no puede pesar más de 3MB", type: "is-danger" })
                                                            }

                                                        }
                                                    }} />
                                                    <div>
                                                        <i className="bi bi-x" onClick={() => {
                                                            let cl: Project = cloneObject(project);
                                                            cl.images = cl.images.filter((i: string, ind: number) => {
                                                                return i !== img
                                                            });

                                                            setProject(cl);
                                                        }}></i>
                                                    </div>

                                                </div>
                                            })
                                        }
                                    </div>

                                </>
                                :
                                <>
                                    <span className="my-2 pt-5">Imagenes de la actividad</span>
                                    <div className="is-flex img-cover my-2 add-image-card">
                                        <i className="bi bi-plus"></i>

                                        <input type="file" accept=".png, .jpg, .jpeg" onChange={(ev: any) => {
                                            if (ev.target.files.length > 0) {
                                                if (ev.target.files[0].size <= 3145728) {
                                                    toast({ message: "Subiendo imagen", type: "is-info" });

                                                    madePost(
                                                        {
                                                            key: "upload-image",
                                                            image: ev.target.files[0],
                                                            ext: ev.target.files[0].name.split(".").pop(),
                                                            project_name: project.name,
                                                            num: project.images.length - 1
                                                        },
                                                        "projects.php"
                                                    ).then(r => {
                                                        let res = r as ResponseGeneral;
                                                        if (res.RESULT) {
                                                            let cl: Project = cloneObject(project);
                                                            let imgs: string[] = [];
                                                            cl.images.map((img: string) => {
                                                                if (img.length > 0) {
                                                                    imgs.push(img)
                                                                }
                                                            })
                                                            imgs.push(res.VALUE)
                                                            imgs.push("");

                                                            cl.images = imgs;

                                                            setProject(cl);

                                                            toast({ message: "Imagen subida satisfactoriamente", type: "is-success" })
                                                        } else {
                                                            toast({ message: res.EXCEPTION_MESSAGE, type: "is-danger" })
                                                        }
                                                    }).catch(onCatch)
                                                } else {
                                                    toast({ message: "La imagen no puede pesar más de 3MB", type: "is-danger" })
                                                }

                                            }
                                        }} />

                                    </div>
                                </>
                        }
                    </div>
                </div>
                :
                <div className="is-flex is-flex-direction-column my-4">
                    <label>Define el nombre de proyecto para agregar contenido multimedia</label>
                    <label><strong>NOTA: </strong>Una vez definido el nombre no se recomienda cambiarlo</label>
                </div>
        }

        <div className="is-flex is-justify-content-flex-end my-5">
            <button className="button is-secodary mr-3" onClick={() => {
                props.onCancel()
            }}>Cancelar</button>
            <button className="button is-info" onClick={save} disabled={!isValid}>Guardar</button>
        </div>
    </div>
}

const Admin = () => {
    const [projects, setProjects] = useState({ values: [] as Project[], isLoaded: false });
    const [action, setAction] = useState({ action: "show", value: {} as Project });

    const navigate = useNavigate();

    useEffect(() => {
        getProjects()
    }, [])

    useEffect(() => {
        let user = getUser();
        if (user) {
            if (user?.role != "admin") {
                navigate("/");
            }
        } else {
            navigate("/");
        }
    }, []);


    const getProjects = () => {
        madePost(
            {
                key: "get-projects-admin"
            },
            "projects.php"
        ).then((r) => {
            let res = r as ResponseGeneral;

            if (res.RESULT) {
                setProjects({ values: res.VALUE, isLoaded: true });
            } else {
                toast({ message: res.EXCEPTION_MESSAGE, type: "is-danger" })
            }
        }).catch(onCatch)
    }

    return <div className="admin-page">

        <div className="content">
            {
                action.action === "show" ?
                    <>
                        <div className="title-admin">
                            <Title sup="Administrar los" sub="Proyectos" color="green" />


                            <button className="button is-info" onClick={() => {
                                setAction({ action: "create", value: { images: ["/assets/images/default-image.jpg"] } as Project });
                            }}>Crear projecto</button>
                        </div>
                        {
                            projects.isLoaded ?
                                <div className="projects">
                                    {
                                        projects.values.map((project: Project) => {
                                            return <ProjectCardAdmin project={project} onEdit={(pr: Project) => {
                                                setAction({ action: "edit", value: pr })
                                            }} />
                                        })
                                    }
                                </div>
                                :
                                <Loader />
                        }
                    </>
                    :
                    <ManageForm project={action.value}
                        action={action.action}
                        onCancel={() => {
                            setAction({ action: "show", value: action.value });
                        }}
                        onSave={(p: Project, action: "edit" | "create") => {
                            let ps: Project[] = cloneObject(projects.values);

                            if (action === "create") {
                                ps.push(p);
                            } else {
                                let i = ps.findIndex((ep: Project) => { return ep.project_code === p.project_code });
                                ps[i] = p;
                            }

                            setProjects({ values: ps, isLoaded: projects.isLoaded });

                            setAction({ action: "show", value: p });
                        }}
                    />
            }
        </div>

        <Footer />
    </div>
}

export default Admin;