import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { EN } from "./translations/en";
import { ES } from "./translations/es";

import LanguageDetector from "i18next-browser-languagedetector";

i18n.use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({

        resources: {
            en: {
                translation: EN
            },
            es: {
                translation: ES
            }
        },
    });