import "./Components.scss";

export const TitlePage = (props: { title: string, subtitle: string, center?: boolean }) => {
    return <div className={"title-page-component "+(props.center? "center" : "")}>
        <h1 style={{ fontFamily: "Aclonica !important" }}>{props.title}</h1>
        <span>{props.subtitle}</span>
    </div>
}

export const TitleSection = (props: { children: any, center?: boolean }) => {
    return <h2 className="title-section-component" style={props.center? {display: "block", width: "100%", textAlign: "center"}: {}}>{props.children}</h2>
}

export const Title = (props: { sup: string, sub: string, color: "light" | "dark" | "green" | "blue" }) => {
    /**
     * NEEDs p as title and span as subtitle
     */
    return <div className="title-component" color={props.color}>
        <p>{props.sup}</p>
        <span>{props.sub}</span>
    </div>
}