import { useEffect, useRef, useState } from "react";
import { Title } from "../components/Components";
import Input from "../components/mini_components/Input";
import "./Admin.scss";
import { LinkType, ResponseGeneral } from "../common/types";
import Loader from "../components/mini_components/Loader";
import { madePost } from "../common/post";
import { onCatch } from "../common/functions";

const AdminLinks = () => {
    const [links, setLinks] = useState({ isLoad: false, values: [] as LinkType[] })

    useEffect(() => {
        madePost(
            {
                key: "get-links"
            },
            "links.php"
        ).then(r => {
            let res = r as ResponseGeneral;

            if (res) {
                setLinks({ isLoad: true, values: res.VALUE })
            } else {
                onCatch({})
            }
        }).catch(onCatch)
    }, [])

    useEffect(() => {
        console.debug(links.values)
    }, [links])

    const add = (ev: any) => {
        ev.target.disabled = true;
        madePost(
            {
                key: "add-link",
                label: text.current.value,
                value: linkInput.current.value
            },
            "links.php"
        ).then(r => {
            setLinks({ isLoad: links.isLoad, values: [{ value: linkInput.current.value, label: text.current.value, key: text.current.value, id: (links.values[links.values.length - 1].id + 1) }, ...links.values] });

            linkInput.current.value = "";
            text.current.value = "";

            ev.target.disabled = false;
        }).catch(onCatch)
    }

    const deleteL = (value: string) => {
        madePost(
            {
                key: "delete-link",
                value: value
            },
            "links.php"
        ).then(r => {
            setLinks({ isLoad: links.isLoad, values: (JSON.parse(JSON.stringify(links.values)).filter((l: LinkType) => { return l.value != value; })) });
        })
    }

    const move = (ev: any, link: LinkType, action: "up" | "down") => {
        ev.target.disabled = true;
        let el = JSON.parse(JSON.stringify(links.values)).find((l: LinkType) => { return l.id == link.id });

        if (el) {
            //elem mod
            let mod = JSON.parse(JSON.stringify(links.values)).find((l: LinkType) => {
                if (action == "up") {
                    //reduce beacause is inverted
                    return l.id > el!.id;
                } else {
                    return l.id < el!.id;
                }
            })
            if (mod) {
                let withutlinks: LinkType[] = JSON.parse(JSON.stringify(links.values)).filter((l: LinkType) => {
                    return l.id != el?.id && l.id != mod?.id;
                })

                let aux = parseInt(el.id);
                el.id = parseInt(mod!.id);
                mod!.id = aux;

                withutlinks = [...withutlinks, el!, mod!];

                withutlinks.sort((a, b) => {
                    if (a.id < b.id) {
                        return 1;
                    }
                    if (a.id > b.id) {
                        return -1;
                    }
                    return 0;
                })

                madePost(
                    {
                        key: "change-position",
                        newvalues: JSON.stringify(withutlinks)
                    },
                    "links.php"
                ).then(r => {
                    ev.target.disabled = false;
                    setLinks({ isLoad: links.isLoad, values: JSON.parse(JSON.stringify(withutlinks)) })
                }).catch(onCatch)
            }else{
                console.debug("not gound")
            }

        }
    }

    const linkInput = useRef({} as HTMLInputElement);
    const text = useRef({} as HTMLInputElement);

    return <div className="admin-links admin-page">
        <div className="content">
            <Title sup="Adminstra " sub="los enlaces" color="blue" />

            <div className="inputs" >
                <Input title="Texto de enlace" placeholder="Texto descriptivo" type="text" onInput={() => { }} innerRef={text} />
                <Input title="Enlace" placeholder="Enlace" type="text" onInput={() => { }} innerRef={linkInput} />

                <button onClick={add} className="button is-info" color="blue">Agregar <i className="bi bi-plus"></i></button>
            </div>

            {
                links.isLoad ?
                    <div className="links-p">
                        <ul className="links">
                            {
                                links.values.length > 0 ?
                                    links.values.map((l: LinkType, indx: number) => {
                                        return <li className="">
                                            <div className="txts">
                                                <span>{l.label}</span>
                                                <small><a href={l.value}><i>{l.value}</i></a></small>
                                            </div>

                                            <button disabled={indx == 0} className="button" onClick={(ev: any) => { move(ev, l, "up"); }}>
                                                <i className="bi bi-arrow-up"></i>
                                            </button>
                                            <button disabled={indx == links.values.length - 1} className="button" onClick={(ev: any) => { move(ev, l, "down"); }}>
                                                <i className="bi bi-arrow-down"></i>
                                            </button>
                                            <button className="button is-danger" onClick={() => { deleteL(l.value) }}>
                                                <i className="bi bi-x"></i>
                                            </button>
                                        </li>
                                    })
                                    :
                                    <li>No tienes elementos todavía</li>
                            }
                        </ul>
                    </div>
                    :
                    <>
                        <Loader />
                    </>
            }
        </div>
    </div>
}

export default AdminLinks;