
import { Title, TitlePage, TitleSection } from "../components/Components";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from "swiper";

import Footer from "../components/Footer";

import "./CBJLC.scss";
// Import Swiper styles
import 'swiper/swiper-bundle.css';
import { useTranslation } from "react-i18next";

import "../common/translation";
import { DonateSection } from "./CBJLC";
import { useEffect, useRef } from "react";
import anime from "animejs";

const Donar = () => {
    const { t } = useTranslation();

    const images2022 = {
        title: "Equipo Propacha",
        images: [
            {
                img: "assets/images/page-donar-1/coordinadores 1-min.jpg",
                name: "Coordinadores",
                elementImg: '/assets/images/propacha-logo-nobg.png'
            }, {
                img: "assets/images/page-donar-1/aire 1-min.jpg",
                name: "Equipo Aire",
                elementImg: "/assets/images/elements/wind.png"
            }, {
                img: "assets/images/page-donar-1/agua 1-min.jpg",
                name: "Equipo Agua",
                elementImg: "/assets/images/elements/water.png"
            }, {
                img: "assets/images/page-donar-1/fuego 1-min.jpg",
                name: "Equipo Fuego",
                elementImg: "/assets/images/elements/fire.png"
            }, {
                img: "assets/images/page-donar-1/tierra 1-min.jpg",
                name: "Equipo Tierra",
                elementImg: "/assets/images/elements/soil.png"
            }, {
                img: "assets/images/page-donar-1/redes 1-min.jpg",
                name: "Equipo Redes",
                elementImg: "/assets/images/elements/propachatv.png"
            },
        ]
    }

    const Hitos = [
        {
            title: 'Cumbre Boliviana de Jóvenes Líderes por el Clima',
            description: 'Evento a nivel nacional que busca formar un red de colectivos con propuestas desde la juventud para instancias de toma de decisiones.',
            images: [
                "https://propacha.org/img/cbjlc/all/cbjlc%20(1).jpg",
                "https://propacha.org/img/cbjlc/all/cbjlc%20(2).jpg"
            ]
        },
        {
            title: 'Proyecto Ecoturismo en Escoma Lago Titicaca',
            description: 'Proyecto que busca fomentar un turismo responsable en el Lago Titicaca  y trabajar con comunidades por la preservación del lago más alto del mundo.',
            images: [
                "https://propacha.org/img/cbjlc/all/cbjlc%20(1).jpg",
                "https://propacha.org/img/cbjlc/all/cbjlc%20(2).jpg"
            ]
        },
        {
            title: 'Arte urbano ( Túnel belisario Salina)',
            description: 'Buscamos mostrar a la población las especies nativas de nuestro municipio, pues no puedes proteger algo que no conoces y pusimos 12 especies en un concurrido espacio de la ciudad.',
            images: [
                "https://propacha.org/img/cbjlc/all/cbjlc%20(1).jpg",
                "https://propacha.org/img/cbjlc/all/cbjlc%20(2).jpg"
            ]
        },
        {
            title: 'Proyecto Centinelas del Agua',
            description: 'Proyecto con la Sub alcaldía Centro, busco concientizar y enseñar sobre el cuidado del agua y su gestión a todas las estades en colegios públicos llegando a más de 10000 niños y niñas de 35 unidades educativas ',
            images: [
                "https://propacha.org/img/cbjlc/all/cbjlc%20(1).jpg",
                "https://propacha.org/img/cbjlc/all/cbjlc%20(2).jpg"
            ]
        },
        {
            title: 'Huerto Smart',
            description: 'El primer huerto escolar Smart de la ciudad está muy pronto de ser concluido gracias al apoyo de wwf Bolivia, la embajada de suecia y fundación alternativas. Buscamos revolucionar la forma de aprendizaje e introducir a la tecnología para cultivar en espacios reducidos y promover ser autosustentable.',
            images: [
                "https://propacha.org/img/cbjlc/all/cbjlc%20(1).jpg",
                "https://propacha.org/img/cbjlc/all/cbjlc%20(2).jpg"
            ]
        },
    ];

    const aliados = {
        images: [
            {
                img: "assets/images/logos-aliados/1-min.png",
                name: "1",
                elementImg: '/assets/images/propacha-logo-nobg.png'
            }, {
                img: "assets/images/logos-aliados/2-min.png",
                name: "2",
                elementImg: '/assets/images/propacha-logo-nobg.png'
            }, {
                img: "assets/images/logos-aliados/3-min.png",
                name: "3",
                elementImg: '/assets/images/propacha-logo-nobg.png'
            }, {
                img: "assets/images/logos-aliados/4-min.png",
                name: "4",
                elementImg: '/assets/images/propacha-logo-nobg.png'
            }, {
                img: "assets/images/logos-aliados/5-min.png",
                name: "5",
                elementImg: '/assets/images/propacha-logo-nobg.png'
            }, {
                img: "assets/images/logos-aliados/6-min.png",
                name: "6",
                elementImg: '/assets/images/propacha-logo-nobg.png'
            },{
                img: "assets/images/logos-aliados/7-min.png",
                name: "7",
                elementImg: '/assets/images/propacha-logo-nobg.png'
            }, {
                img: "assets/images/logos-aliados/8-min.png",
                name: "8",
                elementImg: '/assets/images/propacha-logo-nobg.png'
            }, {
                img: "assets/images/logos-aliados/9-min.png",
                name: "9",
                elementImg: '/assets/images/propacha-logo-nobg.png'
            }, {
                img: "assets/images/logos-aliados/10-min.png",
                name: "10",
                elementImg: '/assets/images/propacha-logo-nobg.png'
            }, {
                img: "assets/images/logos-aliados/11-min.png",
                name: "11",
                elementImg: '/assets/images/propacha-logo-nobg.png'
            }, {
                img: "assets/images/logos-aliados/12-min.png",
                name: "12",
                elementImg: '/assets/images/propacha-logo-nobg.png'
            },{
                img: "assets/images/logos-aliados/13-min.png",
                name: "13",
                elementImg: '/assets/images/propacha-logo-nobg.png'
            }, {
                img: "assets/images/logos-aliados/14-min.png",
                name: "14",
                elementImg: '/assets/images/propacha-logo-nobg.png'
            }, {
                img: "assets/images/logos-aliados/15-min.png",
                name: "15",
                elementImg: '/assets/images/propacha-logo-nobg.png'
            }, {
                img: "assets/images/logos-aliados/16-min.png",
                name: "16",
                elementImg: '/assets/images/propacha-logo-nobg.png'
            }, {
                img: "assets/images/logos-aliados/17-min.png",
                name: "17",
                elementImg: '/assets/images/propacha-logo-nobg.png'
            }, {
                img: "assets/images/logos-aliados/18-min.png",
                name: "18",
                elementImg: '/assets/images/propacha-logo-nobg.png'
            }, {
                img: "assets/images/logos-aliados/19-min.png",
                name: "19",
                elementImg: '/assets/images/propacha-logo-nobg.png'
            }, {
                img: "assets/images/logos-aliados/20-min.png",
                name: "20",
                elementImg: '/assets/images/propacha-logo-nobg.png'
            },
        ]
    }


    ////////////////////

    const imageRefDer = useRef(null);
    const imageRefIzq = useRef(null);

    useEffect(() => {

        const tiltImages = () => {
            const tiltImageDer = anime({
                targets: imageRefDer.current,
                translateX: 25,
                rotate: 20,
                duration: 1000,
                easing: 'easeInOutSine',
                complete: () => {
                    setTimeout(() => {
                        anime({
                            targets: imageRefDer.current,
                            translateX: 0,
                            rotate: 0,
                            duration: 1000,
                            easing: 'easeInOutSine',
                        });
                    }, 2000);
                },
            });

            const tiltImageIzq = anime({
                targets: imageRefIzq.current,
                translateX: -25,
                rotate: -20,
                duration: 1000,
                easing: 'easeInOutSine',
                complete: () => {
                    setTimeout(() => {
                        anime({
                            targets: imageRefIzq.current,
                            translateX: 0,
                            rotate: 0,
                            duration: 1000,
                            easing: 'easeInOutSine',
                        });
                    }, 2000);
                }
            });

            setTimeout(() => {
                tiltImageDer.reverse();
                tiltImageIzq.reverse();
            }, 4000);
        };


        const tiltTimers = setInterval(tiltImages, 15000);
        return () => {
            clearInterval(tiltTimers);
        };

    }, []);

    ////////////////////

    return <div className="cbjlc-page page">
        <img src="/assets/images/cbjcl.jpg" alt="" className="head-img positioned " style={{ display: 'none' }} />

        <div className="title-page" style={{ display: 'none' }}>
            <TitlePage center title={t('whatIsCBJLC')} subtitle={t('cbjlcDescription')} />
        </div>


        <img src="/assets/svg/img-triangle.svg" className="triangle" style={{ display: 'none' }} />

        <section className="donate-tt" style={{ height: '80vh', maxHeight: '80vh', marginBottom: '2rem' }}>
            <Swiper
                style={{ maxWidth: "calc(80vw)", width: "100%", height: '100%', maxHeight: 'inherit', marginTop: '0', paddingBottom: 0 }}
                slidesPerView={1}
                spaceBetween={50}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                modules={[Autoplay]}

                loop
                loopPreventsSlide={false}
                className=""
            >

                {

                    images2022.images.map((img: { img: string, name: string, elementImg?: string }) => {
                        return <SwiperSlide style={{ height: 'inherit' }}>
                            <div className="nameEquipos" style={{ position: 'absolute', top: 0, right: 0, left: '50%', backgroundColor: 'white', paddingBottom: '1rem', paddingTop: '0.5rem', paddingLeft: '2rem', borderBottomLeftRadius: '40px' }}>
                                <span style={{ fontWeight: 'bold' }}>{img.name}</span>
                                <img style={{ width: '1.7rem', marginLeft: '0.5rem' }} src={img.elementImg} alt="" />
                            </div>
                            <img src={img.img} alt="" style={{ maxHeight: '100%', height: 'inherit', objectFit: 'cover', minHeight: '100%', borderRadius: '5px ' }} />
                        </SwiperSlide>
                    })
                }
            </Swiper>

            <div className="t2">

                <span className="mb-4" style={{ fontWeight: 900 }}><>QR del Amor</></span>
                <span className="fraceDonar" >Tu donación permite que jóvenes comprometidos realicen proyectos por el medio ambiente para tú bienestar</span>

                <div>
                    <img className="logoEscondido" style={{ position: 'absolute', width: '5rem', zIndex: -1, borderRadius: '8px' }} ref={imageRefIzq} src="/assets/images/propacha-logo.jpg" />
                    <img className="qrDonar" style={{ borderRadius: '6px', }} ref={imageRefDer} src="/assets/images/qr-donate.jpg" />
                </div>


                <div className="divider">
                    <div></div>
                    <span>ó</span>
                    <div></div>
                </div>

                <span className="mb-4" style={{ fontWeight: 900 }}><>BANCO NACIONAL DE BOLIVIA</></span>
                <span><b>CUENTA</b>: 1501007117</span>
                <span><b>TITULAR</b>: Luis Guillermo Mallea Morales <br /><i>4838993 LP.</i></span>
            </div>
            <div className="t3">
                <span className="mb-4" style={{ fontWeight: 900 }}><>Hitos</></span>
                <Swiper
                    style={{ maxWidth: "100%", width: "100%", height: 'auto', marginTop: '0', paddingBottom: 0 }}
                    slidesPerView={1}
                    spaceBetween={0}
                    autoplay={{
                        delay: 205000,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Autoplay, Pagination]}

                    className=""
                >
                    {
                        Hitos.map((el: any, idx: any) => {
                            return <SwiperSlide style={{ height: 'inherit' }} className="slide-t3">
                                <div style={{ padding: '0 2rem 0rem 2rem', gridRow: '1 / 3', overflowY: 'auto' }} className="content-slide-text">
                                    <span>{el.title}</span>
                                    <p>{el.description}</p>
                                </div>

                                <img src={`/assets/images/${idx + 1}, 0.jpg`} alt="" style={{ maxHeight: '100%', objectFit: 'cover', gridRow: '3 / 6' }} />
                                <img src={`/assets/images/${idx + 1}, 1.jpg`} alt="" style={{ maxHeight: '100%', objectFit: 'cover', gridRow: '6 / 9', borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px' }} />
                            </SwiperSlide>
                        })
                    }
                </Swiper>


                <Swiper
                    style={{ width: "100%", height: '10%', maxHeight: 'inherit', marginTop: '0', paddingBottom: 0 }}
                    spaceBetween={10}
                    slidesPerView={7} 
                    autoplay={{ delay: 1000, disableOnInteraction: false }}
                    modules={[Autoplay]}
                    loop
                    loopPreventsSlide={false}
                    className=""
                >
                    {
                        aliados.images.map((img: { img: string, name: string}) => {
                            return <SwiperSlide className="swiper-slide logo-suggars" style={{ height: 'inherit' }}>
                                <img src={img.img} alt="" style={{ maxHeight: '100%', height: 'inherit', objectFit: 'cover', minHeight: '100%', borderRadius: '50%'}} />
                            </SwiperSlide>
                        })
                    }
                </Swiper>


            </div>
        </section>

        <DonateSection onlybtn />

        <div className="history-cbjlc contrast" style={{ paddingBottom: '6rem', display: 'none' }}>
            {/* <Title sup={t('Propacha')} sub={t('Donaciones')} color="green" /> */}

            <div className="donation-info-section">
                <p>{t('planetWarning')}</p><br />
                <p>{t('ourOrigin')}</p><br />
                <p>{t('aboutUs')}</p><br />
                <h3>{t('donationImpactTitle')}</h3><br />
                <ul>
                    <li style={{ listStyle: 'disc', marginLeft: '2rem' }}>{t('donationImpact1')}</li>
                    <li style={{ listStyle: 'disc', marginLeft: '2rem' }}>{t('donationImpact2')}</li>
                    <li style={{ listStyle: 'disc', marginLeft: '2rem' }}>{t('donationImpact3')}</li>
                </ul>
            </div>

            <div className="donation-details-section">
                <p>{t('donationImportance')}</p><br />
                <p>{t('donateNow')}</p><br />
                <h3>{t('bankDetails')}</h3><br />
                <ul>
                    <li style={{ listStyle: 'disc', marginLeft: '2rem' }}>{t('bankName')}</li>
                    <li style={{ listStyle: 'disc', marginLeft: '2rem' }}>{t('accountNumber')}</li>
                    <li style={{ listStyle: 'disc', marginLeft: '2rem' }}>{t('accountHolder')}</li>
                </ul>
                <p style={{ display: 'none' }}>{t('thanksForSupport')}</p><br />
                <p>{t('futureInYourHands')}</p><br />
                <p>{t('stayUpdated')}</p><br />
            </div>

            <div className="gallery">
                <Swiper
                    style={{ maxWidth: "calc(80vw)", width: "100%" }}
                    slidesPerView={1}
                    spaceBetween={50}
                    breakpoints={{
                        600: {
                            slidesPerView: 2
                        },
                        900: {
                            slidesPerView: 3
                        }
                    }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Autoplay, Pagination]}

                    className="our-projects-swiper"
                >
                    {
                        images2022.images.map((img: any) => {
                            return <SwiperSlide >
                                <img src={img.img} alt="" />
                            </SwiperSlide>
                        })
                    }
                </Swiper>

                <span><b>{images2022.title}</b></span>
            </div>

        </div>

        <Footer />
    </div>

}

export default Donar;