
import { Title, TitlePage, TitleSection } from "../components/Components";
import Footer from "../components/Footer";

import "./CBJLC.scss";
import { useState } from "react";


import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from "swiper";
// Import Swiper styles
import 'swiper/swiper-bundle.css';
import { DonateMethods } from "../components/Header";
import { useTranslation } from "react-i18next";
import ReactPlayer from 'react-player';

import "../common/translation";

import { BrowserRouter, Link, Route } from 'react-router-dom'


export const DonateSection = (props: { contrast?: boolean, onlybtn?: boolean }) => {
    const [show, setIsShow] = useState(false);
    const { t } = useTranslation();

    const click = () => {
        if (window.screen.availWidth <= 1023) {
            //show modal
            setIsShow(true);
        } else {
            let dropdown = (document.getElementById("donations") as HTMLDivElement);

            if (!dropdown.classList.contains("is-block")) {
                dropdown.classList.add("is-block");
            } else {
                dropdown.classList.remove("is-block");
            }
        }
    }

    return <div className={"history-cbjlc donate-section " + (props.contrast ? "contrast" : "")}>
        <Title sup={t('helpWithDonation')} sub={t('donation')} color="green" />

        {
            props.onlybtn ?
                <></>
                :
                <>
                    <TitleSection>{t('howDonationHelps')}</TitleSection>
                    <p style={{ textAlign: "justify" }}>{t('donationImpactDescription')}</p>
                </>
        }

        <button className="button mt-3" color="green" onClick={click}>{t('contributeWithDonation')}</button>

        <div className={"modal " + (show ? "is-active" : "")}>
            <div className="modal-background"></div>
            <div className="modal-content">
                <DonateMethods />
            </div>
            <button className="modal-close is-large" aria-label="close" onClick={() => {
                setIsShow(false);
            }}></button>
        </div>

    </div>
}


const CBJLC = () => {
    const { t } = useTranslation();

    const imageshuerto = {
        title: "CBJLC 2023",
        images: [
            "/assets/images/logo-huerto.png",
            "/assets/images/huerto/hu3.jpg",
            "/assets/images/huerto/hu4.jpg",
            "/assets/images/huerto/hu2.jpg",
            "/assets/images/huerto/hu6.jpg",
            "/assets/images/huerto/hu5.jpg",
        ]
    };

    const imageslogos_huerto = {
        title: "NDCs",
        images: [
            "/assets/images/huerto/logos/lh1.png",
            "/assets/images/huerto/logos/lh2.png",
            "/assets/images/huerto/logos/lh3.png",
            "/assets/images/huerto/logos/lh4.png",
            "/assets/images/huerto/logos/lh5.jpg",
        ]
    };






    return <div className="cbjlc-page page">


        {/*        ///////////////////////////////////////////////////////////////                 */}

        <img src="/assets/images/h1.jpg" alt="" className="head-img positioned" id="header-huerto" />

        <div className="title-page">
            <TitlePage center title={"Huerto Smart"} subtitle={""} />
        </div>


        <img src="/assets/svg/img-triangle.svg" className="triangle" />

        <DonateSection onlybtn />

        <div className="history-cbjlc contrast" style={{}}>
            <Title sup={"Huerto".split(" ")[0]} sub={"smart".split(" ")[0]} color="green" />
            <p>
                <div style={{ height: "auto", width: "100%", display: "flex", justifyContent: "space-evenly", flexWrap: "wrap", margin: "2rem 0" }}>
                    
                    <img src="/assets/images/huerto/hh1.jpg" style={{zIndex: 2, height: "20rem", width: "auto", objectFit: "contain", marginBottom:"0.5rem"}} alt="" />
                    <a target="_blank" style={{ display: 'flex', flexFlow: 'nowrap column', alignItems: 'center', justifyContent: 'center', gap: '0rem' }}>
                        <div className="lineablanca" style={{ height: "15rem", width: "60vw", backgroundColor: "white", position: "absolute", zIndex: 1,  }}></div>
                        <img src="/assets/images/logo-huerto.png" style={{zIndex: 2, width: '20rem', height: '10rem', mixBlendMode: undefined, borderRadius: '.5rem', objectFit: "cover" }} />
                    </a>
                    <img src="/assets/images/huerto/hh2.jpg" style={{zIndex: 2, height: "20rem", width: "auto", objectFit: "contain" }} alt="" />
                </div>



                {"La Paz:  La primera ciudad con un plan para redefinir la educación y la agricultura usando tecnología en escuelas públicas."}
                <br />
                {"( SEG Smart Educational Gardens)"}
                <br /><br />
                {"La Ciudad en el Borde de la Seguridad Alimentaria"}
                <br />
                {"Un Desafío alimenticio en el Corazón de Bolivia: La Paz, la tercera ciudad más grande de Bolivia con una población de más de 2 millones de personas, enfrenta desafíos únicos en su lucha por la seguridad alimentaria. Recientemente, el precio fluctuante de productos básicos de la canasta familiar como el tomate superó los 20 bolivianos por kilogramo, evidenciando la falta de seguridad productiva y la vulnerabilidad de la ciudad a interrupciones en la producción y distribución de alimentos. Según el Atlas Municipal de Seguridad Alimentaria (Alternativas, 2022), el municipio de La Paz produce menos del 7.5% de la producción agrícola total de la región, dependiendo en gran medida de las áreas rurales. Entre 2022 y 2023, una sequía severa paralizó la producción de papa, destacando la susceptibilidad de la ciudad al cambio climático. Los eventos climáticos extremos y la falta de producción local agravan la inseguridad alimentaria, poniendo en riesgo el acceso a alimentos frescos y asequibles. La nutrición en nuestros pequeños."}
                <br /><br />
                {"Según el Atlas Municipal de Seguridad Alimentaria (Alternativas, 2022), el municipio de La Paz produce menos del 7.5% de la producción agrícola total de la región, dependiendo en gran medida de las áreas rurales. Entre 2022 y 2023, una sequía severa paralizó la producción de papa, destacando la susceptibilidad de la ciudad al cambio climático. Los eventos climáticos extremos y la falta de producción local agravan la inseguridad alimentaria, poniendo en riesgo el acceso a alimentos frescos y asequibles. La nutrición en nuestros pequeños."}


            </p>


            <div className="gallery " style={{ width: '100%' }}>
                <Swiper
                    style={{ maxWidth: "calc(80vw)", width: "100%" }}
                    slidesPerView={1}
                    spaceBetween={50}
                    breakpoints={{
                        700: {
                            slidesPerView: 2
                        },
                        800: {
                            slidesPerView: 3
                        },
                        900: {
                            slidesPerView: 4
                        }
                    }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Autoplay, Pagination]}

                    className="our-projects-swiper"
                >
                    {
                        imageshuerto.images.map((img: string) => {
                            return <SwiperSlide >
                                <img src={img} alt="" style={{maxHeight:"9rem",}} />
                            </SwiperSlide>
                        })
                    }
                </Swiper>
            </div>

            <p>
                {"Los niños en áreas urbanas son particularmente vulnerables, con un 29% sufriendo de sobrepeso según un estudio de UNICEF y el Ministerio de Salud de Bolivia. Bolivia tiene un alto riesgo de obesidad, ocupando el puesto 15 de 19 países en consumo de verduras y el 25 de 33 en consumo de frutas. Huertos Urbanos Sostenibles, Educativos ¡y SMART!"}
                <br />
                {"Ante estos desafíos, Movimiento Propacha  con su socio Cruz Verde SRL y el Gobierno Autonomo municipal de La Paz, planteó como solución la implementación de huertos urbanos, aplicando soluciones tecnológicas como la hidroponía, el riego por goteo, uso de luces led, charolas germinadoras, aplicaciones móviles, cerebros controladores. Un laboratorio productivo a tu alcance. El segundo y tercer huerto de la organización se están siendo implementados en las Unidad Educativa Hugo Dávila, en el Macrodistrito Central de La Paz y en La unidad educativa Callapa en el macro distrito San Antonio, zonas con escasa cobertura verde."}
                <br />
                {"El desarrollo de huertos urbanos y proyectos comunitarios es una respuesta necesaria para mejorar la seguridad alimentaria en La Paz. Estos esfuerzos no solo proporcionan acceso a alimentos frescos y saludables, sino que también educan y empoderan a la comunidad. La Paz, con su espíritu resiliente e iniciativas innovadoras, está dando pasos significativos hacia un futuro más verde y seguro. Aunque el camino es desafiante, la colaboración comunitaria y las soluciones creativas están demostrando ser claves para enfrentar la inseguridad alimentaria y construir una ciudad más sustentable y saludable."}
                <br /><br />
            </p>


            <div className="logos-huerto"
                style={{ width: "100%", height: "auto", display: "flex", flexDirection: "row", justifyContent: "space-evenly", flexWrap: "wrap" }}
            >
                <img src="/assets/images/huerto/logos/lh1.png" alt="" style={{marginBottom:"0.5rem"}} />
                <img src="/assets/images/huerto/logos/lh2.png" alt="" style={{marginBottom:"0.5rem"}} />
                <img src="/assets/images/huerto/logos/lh3.png" alt="" style={{marginBottom:"0.5rem"}} />
                <img src="/assets/images/huerto/logos/lh4.png" alt="" style={{ objectFit: "contain", marginBottom:"0.5rem" }} />
                <img src="/assets/images/huerto/logos/lh6.png" alt="" style={{marginBottom:"0.5rem"}} />
            </div>

            <p>
                <br /><br />
                {"Proyección "}
                <br />
                {"Buscamos hacer de La Paz la primera ciudad con un sistema integrado que convierta a las unidades educativas en centros de producción agricola, y que está producción sea comercializada en las mismas unidades educativas para sus sustentabilidad y tener ingresos parta la producción continua todo el año."}
                <br /><br />
            </p>
            <div>
            </div>
        </div>


        <Footer />
    </div>

}

export default CBJLC;