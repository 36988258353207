import { isset } from "./functions";
import { User } from "./types";
import { getItem, setItem } from "./useStorage"
import { home, homePublic } from "./variables";

export const getUser = (): User | null => {
    let user: User | null = {} as User;
    let response = getItem("USER");

    try {
        user = JSON.parse(response!);
        //SET IMAGE
        user!.image = home + "profiles/" + (user?.user_code) + "-" + (user?.name.toLowerCase().replaceAll(" ", "-")) + ".jpeg";

    } catch (err) {
        user = null;
    }

    return user;
}

export const setNewElement = (element: "fire" | "wind" | "soil" | "water" | "tv") => {
    let user = getUser();

    user!.element = element;

    setItem("USER", JSON.stringify(user));
}

export const isLogged = (): boolean => {
    let u = getUser();

    return u !== null;
}