export const EN = {
    "whoWeAre": "Who we are",
    "aboutUsDesc": "We are an environmental organization made up by...",
    "whatIsProPacha": "What we do",
    "proPachaOrigin": "ProPacha was born out through the union of two terms:",
    "proDefinition": "“Pro” -. It is an adjective that means “in favor of or supporting”",
    "pachaDefinition": "“Pacha”.- represents space (matter) and time (spirit) at once...",
    "proPachaMeaning": "Therefore, ProPacha is an environmental organization in favor of harmony...",
    "donationTitle": "SUPPORT US WITH YOUR DONATION",
    "donationImpact": "With your donation, we carry out actions of real impact...",
    "originStoryTitle": "Origin Story",
    "originStoryDesc": "The ProPacha Movement was born at the beginning of 2019...",
    "ecoMovementDesc": "We are an Eco-Environmental Movement made up by volunteers...",
    "teamMembers": "+25 Members of our team",
    "volunteers": "+300 Volunteers",
    "missionTitle": "MISSION",
    "missionDesc": "We are an organization made up by young ecologists...",
    "visionTitle": "VISION",
    "visionDesc": "To become an eco-environmental movement recognized...",
    "founders": "Founders",
    "luisPosition": "Co-founder, Managing Director",
    "isabelPosition": "Co-founder, Legal Director",
    "albaPosition": "Co-founder, Human Resources Director and Communication",
    "members": "Members",
    "allProjects": "ALL OUR PROJECTS",
    "sendaVerdeInfo": "Visit Senda Verde (just members)...",
    "oxygenRouteInfo": "Oxygen Route, Saturday 25th, March...",
    "ecoFairInfo": "Ecological Fair “PLANET F”...",
    "coursesTitle": "Propacha Courses",
    "activismCourse": "Mastering the art of ENVIRONMENTAL ACTIVISM",
    "swissSupport": "With the support of the Dialogue Project and the Collaborative help...",
    "newAccount": "New account",
    "username": "User name",
    "passwordRequirements": "Your password must have at least 8 characters, 1 digit, 1 lower case...",
    "accountCreated": "We have sent you an email to ______...",
    "our": "Our",
    "projects": "Projects",
    "registerAndStart": "Register and start",
    "welcomeMessage": "We welcome you to the space where Pacha is heard",
    "wantToCollaborate": "WANT TO COLLABORATE OR START A PROJECT",
    "withUs": "WITH US?",
    "organizationDescription": "We are an organization of young ecologists who address the climate crisis through actions for the protection, conservation, and restoration of the environment, working from multiple approaches with a common goal: to save the planet.",
    "propachaBolivia": "Propacha Bolivia",
    "results": "Results",
    "activities": "Activities",
    "activeVolunteers": "Active Volunteers",
    "completedProjects": "Completed Projects",
    "recognitions": "Recognitions",
    "become": "Become",
    "volunteer": "Volunteer",
    "createAccount": "Create your account and participate in our activities through the following button",
    "joinTeam": "Join the team",
    "howWeHelp": "How do we seek to help?",
    "fire": "Fire",
    "water": "Water",
    "earth": "Earth",
    "air": "Air",
    "networksTV": "Networks TV",
    "fireTeam": "Fire Team",
    "fireDesc": "We link theoretical research and direct non-violent activism to fight against climate change",
    "waterTeam": "Water Team",
    "waterDesc": "We inform, raise awareness, and educate through activities and workshops to have a healthy, sustainable, and less consumerist lifestyle",
    "earthTeam": "Earth Team",
    "earthDesc": "We promote projects for the care of the environment and workshops on knowledge of flora and fauna",
    "airTeam": "Air Team",
    "airDesc": "Focused on ecotourism, eco-trekings, nature connection trips, architectural projects, and transformation of green spaces",
    "networksTVTeam": "Networks TV Team",
    "networksTVDesc": "We generate audiovisual content on environmental topics and current issues on climate change",
    "soil": "Soil",
    "wind": "Air",
    "tvNetwork": "Networks TV",
    "soilTeam": "Soil Team",
    "windTeam": "Air Team",
    "tvNetworkTeam": "Networks TV Team",
    "soilDesc": "We promote projects for the care of the environment and workshops on knowledge of flora and fauna",
    "windDesc": "Focused on ecotourism, eco-trekking, nature connection trips, architectural projects, and green space transformation",
    "tvNetworkDesc": "We generate audiovisual content on environmental topics and current issues on climate change",
    "nameLabel": "Name",
    "namePlaceholder": "Name",
    "emailLabel": "Email",
    "emailPlaceholder": "Email",
    "cellLabel": "Cellphone",
    "cellPlaceholder": "Cellphone",
    "messageLabel": "Message",
    "messagePlaceholder": "Message",
    "sendButton": "Send",
    "helpWithDonation": "Help us with a",
    "donation": "Donation",
    "howDonationHelps": "How does a donation help Propacha?",
    "donationImpactDescription": "With your donation, we carry out actions with a real impact. We want change, we have the desire, talent, and people, but with your contribution, we will have the piece that will allow us to go further. If you also want to be part of this adventure, check out the activities we have and join the movement.",
    "contributeWithDonation": "Contribute to change with your donation",
    "QR": "QR",
    "Transferencia": "Transfer",
    "Cuenta": "Account",
    "Titular": "Account Holder",
    "Banco": "Bank",
    "Banco Nacional de Bolivia": "National Bank of Bolivia",
    "Propacha logo": "Propacha Logo",
    "Inicio": "Home",
    "Quienes somos": "Who We Are",
    "Que hacemos": "What We Do",
    "Actividades": "Activities",
    "CBJLC": "CBJLC",
    "Cursos": "Courses",
    "Donar": "Donate",
    "PROPACHA": "PROPACHA",
    "Proyectos": "Projects",
    "¿Quiénes somos?": "Who are we?",
    "¿Qué hacemos?": "What do we do?",
    "Todos los derechos reservados - Propacha 2023": "All rights reserved - Propacha 2023",
    "titleQuienesSomos": "Who are we?",
    "descriptionQuienesSomos": "We are a youth volunteer environmental organization that carries out triple impact projects for the awareness and preservation of the environment. We promote Ecological Conservation and Restoration and the use of sustainable practices to combat climate change in Bolivia and on the planet.",
    "titleQueEs": "What is",
    "subtitlePropacha": "Propacha?",
    "definitionPro": "“pro” - An adjective meaning 'supporter or favorable'.",
    "definitionPacha": "“Pacha” represents space [matter] and at the same time time [spirit], it poses a space-time and relative origin of all things. Because everything is connected.",
    "definitionHarmony": "A scenario, in which the form/idea of matter-spirit, come together to coexist, that fair link between the material and immaterial in space-time. So, ProPacha is an organization that is in favor of that harmony between nature and man, between culture and progress.",
    "historyTitleSup": "Our",
    "historyTitleSub": "History",
    "historyParagraph1": "The ProPacha Movement was born in early 2019 through meetings of different environmental groups and independent individuals who expressed their concern about the current climate crisis and their willingness to make a change and raise awareness in society through activities and experiences that connect us with nature. We joined forces and exchanged knowledge for a common cause, we cleaned up the trash from a hill and became an inseparable team.",
    "historyParagraph2": "We are an eco-environmental volunteer movement that seeks to promote care and respect for nature, fight against climate change, and generate harmonious coexistence with the earth and all living beings.",
    "historyTeamMembers": "Members of our team",
    "historyVolunteers": "Volunteers",
    "aboutUsTitleSup": "About",
    "aboutUsTitleSub": "Us",
    "missionDescription": "We are an organization of young ecologists who carry out actions and projects for the protection and conservation of flora and fauna",
    "visionDescription": "To be an eco-environmental movement of national reference, where we can work on different macro-level projects in coordination with institutions, organizations, companies, and change agents in society.",
    "titlePageTitle": "What we do",
    "titlePageSubtitle": "We carry out activities that have a positive impact on society and the environment, through triple impact projects. We have 5 multidisciplinary teams that allow us to carry out research actions, raise awareness, implement urban gardens, protect flora and fauna, eco-tourism, and environmental law.",
    "titleSectionElements": "Our elements",
    "tabFire": "Fire",
    "tabWater": "Water",
    "tabSoil": "Earth",
    "tabAir": "Air",
    "tabTV": "Networks TV",
    "teamFireTitle": "Fire Team",
    "teamFireDescription": "Considered a symbol of knowledge and spirituality since ancient times. In this way and symbolism, this axis of action is characterized by cultivating knowledge by strengthening actions and projects. We seek to promote conscious and responsible activism, from the principle of “act by knowing and know by acting” where the main objective is to link theoretical research and direct action under the same horizon.",
    "teamWaterTitle": "Water Team",
    "teamWaterDescription": "The element that represents wisdom, fluidity, and trust. Focused on raising awareness and environmental education through sustainable practices for the care of Mother Earth. Our function is to inform, raise awareness, and educate to strengthen the philosophy of life in harmony with the environment and care for nature, promoting activities and workshops that promote sustainable and healthy practices. We talk about conscious eating, sustainable lifestyle, urban gardens, technology, and eco-innovation.",
    "teamSoilTitle": "Earth Team",
    "teamSoilDescription": "It represents solid matter and the structure of the universe, which includes all its flora and fauna, all creation. It is the conduit through which the other elements are part. We seek to raise awareness of what is around us and the world we live in. We are dedicated to conducting knowledge workshops on fauna and flora and development projects in favor of the environment.",
    "teamAirTitle": "Air Team",
    "teamAirDescription": "It is the element of freedom. The air is everywhere and its essence is to relate, enjoy, learn, and know the environment and nature. This means that our actions must serve to care for the environment, but without ceasing to be fun and exciting, visiting interesting places or that need solutions to different problems related to the environment but we also transform the city with tangible works that affect the lives of citizens and give them a message. Our activities are eco-trekings, awareness trips to nature, ecotourism, architectural projects, and transformation of green spaces.",
    "teamTVTitle": "Networks TV Team",
    "teamTVDescription": "The creative department that displays audiovisual content and beyond being an element, it is the omnipresent energy in the organization. It seeks to inform, educate, raise awareness, entertain, and inspire the general public to carry out environmental practices, activities, projects, joint actions, events, and news about the environment through television media and digital content platforms, such as Facebook, Instagram, Tiktok, and YouTube. ProPacha Movement is made up of young people and aims to socialize everything it does through the most used platforms in Bolivia and internationally.",
    "titleSup": "Become",
    "titleSub": "A Volunteer",
    "volunteerDescription": "Become a volunteer by creating your account and participating in our activities through the following button",
    "joinTeamButton": "Join the team",
    "titleSupProjects": "All the",
    "titleSubProjects": "Projects",
    "projectsComingSoon": "Available soon",
    "whatIsCBJLC": "WHAT IS CBJLC?",
    "cbjlcDescription": "The CBJLC is a space for dialogue, proposal building, and youth action, bringing together various organizations from all over the country around environmental issues.",
    "cbjlcYear": "CBJLC 2023",
    "proudOrganization": "Our organization is very proud to be able to bring young people from all over Bolivia, from the 9 departments, urban and rural areas, to this important meeting. Where the youth agenda is exposed based on solutions and proposals from young leaders committed to the environment.",
    "accessInstitutionalMemory": "Get to know the",
    "youthDeclaration": "Youth Declaration Bolivian Summit of Young Leaders for the Climate 2023",
    "ndcAnalysis": "This publication includes an analysis of Bolivia's Nationally Determined Commitments (NDC in English) for the period 2021-2030 carried out from civil society in the main identified sectors such as water, energy, agriculture, and forests. The document includes an analysis of each of the goals set in these sectors, and evaluates whether they are viable based on the current situation. The inputs synthesized in this unprecedented document in the country were collected from a cycle of 6 national events where more than 500 people participated in the regions of La Paz, Santa Cruz, Cochabamba, Tarija, Sucre, and Cobija, highlighting the broad participation of Bolivian youth throughout the process.",
    "learnMore": "Learn more",
    "secondEditionDescription": "This second edition will showcase a concept that focuses on supporting already established Collectives that have been constantly working in favor of environmental conservation.",
    "cbjlcObjective": "The objective of the CBJLC 2022 is to generate value chains that allow them to carry out actions that are relevant to society and also test their abilities to be agents of change.",
    "summitDate": "The summit took place between October 8 and 9, 2022, with successful participation.",
    "collectiveSelection": "10 collectives from all over Bolivia were selected to enter the strengthening program. It will be available soon.",
    "preparationForNext": "We are preparing for the CBJLC in April 2023.",
    "waitForIt": "Wait for it!",
    "firstSummitDescription": "In October 2021, Movimiento ProPacha organized the 1st National Summit of Young Climate Leaders (CBJLC), where around 250 young people from urban and rural areas took part in various stages of this process, which culminated in the development of a National Youth Climate Agenda expressed in the Institutional Memory.",
    "youthClimateAgenda": "The Youth Climate Agenda - CBJLC 2021 made significant progress in the path of diplomatic relations between governmental spheres and young people. Since then, we have actively participated in:",
    "eventOrganization": "Organization of a series of face-to-face events throughout the country to discuss Bolivia's new NDCs",
    "participationInCommission": "Participation in the Technical Commission Land and Territory in the National Senate to consider the proposal for a National Climate Change Law.",
    "start": "START",
    "session": "SESSION",
    "email": "Email",
    "password": "Password",
    "loginButton": "Login",
    "createAccountButton": "Create Account",
    "forgotPassword": "Forgot your password?",
    "create": "CREATE",
    "account": "ACCOUNT",
    "fullName": "Full Name",
    "cellphone": "Cellphone",
    "location": "Location",
    "birthdate": "Birthdate",
    "repeatPassword": "Repeat Password",
    "invalidEmail": "Invalid Email",
    "passwordMismatch": "Passwords do not match",
    "acceptTerms": "I accept the",
    "termsAndConditions": "terms and conditions of Propacha",
    "selectYour": "SELECT YOUR",
    "element": "ELEMENT",
    "tv": "PROPACHA TV",
    "moreAction": "More action",
    "lessWords": "less words",
    "planetWarning": "The planet is reaching a point of no return, and our survival is becoming increasingly difficult worldwide. From wherever you are, you choose whether you contribute to destruction or change.",
    "ourOrigin": "Our organization was born from the dream of young people with a motivation and a passion to make a change where no one acted. The propacha movement is the union of the talent and hearts of young people looking to work to achieve changes in society to take care of Mother Earth.",
    "aboutUs": "We are a non-profit organization that has been working since 2019 to protect the environment in Bolivia. We are a team of 40 members and more than 300 volunteers who are committed to creating a more sustainable future for our country.",
    "donationImpactTitle": "Your donation will help us continue working to:",
    "donationImpact1": "Make triple impact projects a reality to preserve and care for the environment",
    "donationImpact2": "Support initiatives with different institutions and organizations",
    "donationImpact3": "Our sustainability as an organization.",
    "donationImportance": "Your donation is important to us. With your help, we can continue working to protect Bolivia's environment for future generations.",
    "donateNow": "Donate now and be part of the change. Love the Earth, live propacha.",
    "bankDetails": "Bank details:",
    "bankName": "Bank: Banco Nacional de Bolivia",
    "accountNumber": "Account: 1501007117",
    "accountHolder": "Account Holder: Luis Guillermo Mallea Morales.",
    "thanksForSupport": "Thank you for your support!",
    "futureInYourHands": "The future of Bolivia's environment is in your hands! With your donation, we can continue working to create a more sustainable future for our country.",
    "stayUpdated": "To stay updated and know that your donation made a difference for the environment, follow us on our social media.",
    "Propacha": "Propacha",
    "Donaciones": "Donations",
    "recognitionsAndAwards": "Recognitions and awards",
    "currentMembers": "Current members",
    "activitiesDoneForEnvironment": "Activities done",
    "alliances": "Alliances"
}
