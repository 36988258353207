import { Link } from "react-router-dom";
import "./Footer.scss";

import { ReactComponent as FB } from '../icons/fb.svg';
import { ReactComponent as Instagram } from '../icons/instagram.svg';
import { ReactComponent as Linkedin } from '../icons/linkedin.svg';
import { ReactComponent as Tiktok } from '../icons/tiktok.svg';
import { ReactComponent as Twitter } from '../icons/twitter.svg';
import { useTranslation } from "react-i18next";

import "../common/translation";

const Footer = () => {
    const { t } = useTranslation();

    return <footer id="footer">
        <h2>{t('PROPACHA')}</h2>
        <h5>2023</h5>

        <div className="links">
            <Link to="/inicio">{t('Inicio')}</Link>
            <Link to="/proyectos">{t('Proyectos')}</Link>
            <Link to="/nosotros">{t('¿Quiénes somos?')}</Link>
            <Link to="/proyecto">{t('¿Qué hacemos?')}</Link>
            {/* <Link to="/faq">Preguntas frecuentes</Link> */}
        </div>

        <div className="network-links">
            <a href="https://www.facebook.com/movimientopropacha" target="_blank">
                <FB />
            </a>
            <a href="https://www.instagram.com/movimientopropacha/" target="_blank">
                <Instagram />
            </a>
            <a href="https://bo.linkedin.com/company/propacha" target="_blank">
                <Linkedin />
            </a>
            <a href="https://www.tiktok.com/@propacha_oficial" target="_blank">
                <Tiktok />
            </a>
            <a href="https://mobile.twitter.com/propacha" target="_blank">
                <Twitter />
            </a>
        </div>

        <p>{t('Todos los derechos reservados - Propacha 2023')}</p>
    </footer>
}

export default Footer;