import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { madePost } from "../common/post";
import { Project, ResponseGeneral } from "../common/types";
import { colors, homeAPI } from "../common/variables";
import { Title, TitlePage, TitleSection } from "../components/Components";
import Loader from "../components/mini_components/Loader";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from "swiper";

import "../common/translation";

// Import Swiper styles
import 'swiper/swiper-bundle.css';
// Import Swiper styles
import 'swiper/swiper-bundle.css';
import "./ProyectoPropacha.scss";

import "./Inicio.scss";
import Footer from "../components/Footer";

import anime from "animejs/lib/anime.es.js"
import { Link, useNavigate } from "react-router-dom";
import { animateNumber } from "../common/functions";
import { Activity } from "./ProyectoPropacha";
import { DonateSection } from "./CBJLC";
import { useTranslation } from "react-i18next";
import { hide, right } from "@popperjs/core";
import Modal from "../components/modal";



export const OurProyects = ({ t }: { t: any }) => {
    const [isLoad, setIsLoad] = useState(false);
    const [values, setValues] = useState([] as any[]);

    const prepareProjects = () => {
        madePost(
            {
                key: "get-projects"
            },
            "projects.php"
        ).then(r => {
            let res = r as ResponseGeneral;

            if (res.RESULT) {
                let vals: any[] = [];
                res.VALUE.forEach((e: Project, index: number) => {
                    vals.push(<SwiperSlide>
                        <img src={JSON.parse(e.images as unknown as string)[0]} alt={"Proyecto propacha " + index.toString()} />
                        <div dangerouslySetInnerHTML={{ __html: e.description }}></div>
                    </SwiperSlide>);
                })

                setValues(vals);
            }

            setIsLoad(true);
        })
    }

    useEffect(() => {
        if (!isLoad) {
            prepareProjects();
        }
    }, [isLoad])

    const navigate = useNavigate();

    return <div className="our-projects-home" style={{ backgroundImage: 'url("/assets/images/bg/our-projects.jpg")' }}>
        <div className="title-section">
            <Title sup={t('our')} sub={t('projects')} color="light" />
            <button color="red" className="button" onClick={() => {
                navigate("/account");
            }}>{t('registerAndStart')}</button>
        </div>

        {
            isLoad ?
                <Swiper
                    spaceBetween={50}
                    slidesPerView={1}
                    breakpoints={{
                        // when window width is >= 640px
                        800: {
                            slidesPerView: 2,
                        },
                    }}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Autoplay, Pagination]}

                    className="our-projects-swiper"
                >
                    {values.map((e: any) => {
                        return e;
                    })}
                </Swiper>
                :
                <Loader color="white" />
        }
    </div>
}



const Inicio = () => {
    const [tabActive, setTabActive] = useState(0);

    const _1ref = useRef({} as HTMLSpanElement);
    const _2ref = useRef({} as HTMLSpanElement);
    const _3ref = useRef({} as HTMLSpanElement);
    const _4ref = useRef({} as HTMLSpanElement);

    const _5ref = useRef({} as HTMLSpanElement);
    const _6ref = useRef({} as HTMLSpanElement);
    const _7ref = useRef({} as HTMLSpanElement);
    const _8ref = useRef({} as HTMLSpanElement);

    const [isAnim, setIsAnim] = useState(true);

    const navigate = useNavigate();
    const { t } = useTranslation();

    useLayoutEffect(() => {
        animateNumber(_1ref.current, 30);
        animateNumber(_2ref.current, 300);
        animateNumber(_3ref.current, 5);
        animateNumber(_4ref.current, 9);

        animateNumber(_5ref.current, 9);
        animateNumber(_6ref.current, 40);
        animateNumber(_7ref.current, 100);
        animateNumber(_8ref.current, 20);

        /*anime({
            targets: '.title-page',
            translateX: [0, 100, 0],
            scale: 1,
            easing: "cubicBezier(.5, .05, .1, .3)",
        });*/
    }, [])

    const change = () => {
        if (isAnim) {
            setTimeout(() => {
                setTabActive((tabActive < 4 ? (tabActive + 1) : 0))
            }, 10000)
        }
    }

    useEffect(() => {
        anime({
            targets: '.tabs-component .content .is-active',
            translateY: 0,
            scale: 1
        });
        anime({
            targets: '.tabs-component .content .disabled',
            translateY: 200,
            scale: 1
        });

        change()
    }, [tabActive])

    const toogleTab = (tab: number) => {
        setIsAnim(false);
        setTabActive(tab);
    }

    let events: Activity[] = [
        {
            element: "water",
            title: "",
            description: "",
            images: [
                "https://propacha.org/img/water/water%20(1).jpg", "https://propacha.org/img/water/water%20(2).jpg", "https://propacha.org/img/water/water%20(3).jpg", "https://propacha.org/img/water/water%20(4).jpg", "https://propacha.org/img/water/water%20(5).jpg", "https://propacha.org/img/water/water%20(6).jpg", "https://propacha.org/img/water/water%20(7).jpg", "https://propacha.org/img/water/water%20(8).jpg", "https://propacha.org/img/water/water%20(9).jpg", "https://propacha.org/img/water/water%20(10).jpg", "https://propacha.org/img/water/water%20(11).jpg", "https://propacha.org/img/water/water%20(12).jpg", "https://propacha.org/img/water/water%20(13).jpg"
            ]
        },
        {
            element: "soil",
            title: "",
            description: "",
            images: [
                "https://propacha.org/img/soil/soil%20(1).jpg",
                "https://propacha.org/img/soil/soil%20(2).jpeg",
                "https://propacha.org/img/soil/soil%20(3).jpeg",
                "https://propacha.org/img/soil/soil%20(4).jpeg",
            ]
        },
        {
            element: "wind",
            title: "",
            description: "",
            images: ["https://propacha.org/img/wind/wind%20(1).jpg", "https://propacha.org/img/wind/wind%20(2).jpg", "https://propacha.org/img/wind/wind%20(3).jpg", "https://propacha.org/img/wind/wind%20(4).jpg", "https://propacha.org/img/wind/wind%20(5).jpg", "https://propacha.org/img/wind/wind%20(6).jpg", "https://propacha.org/img/wind/wind%20(7).jpg", "https://propacha.org/img/wind/wind%20(8).jpg", "https://propacha.org/img/wind/wind%20(9).jpg", "https://propacha.org/img/wind/wind%20(10).jpg", "https://propacha.org/img/wind/wind%20(11).jpg", "https://propacha.org/img/wind/wind%20(12).jpg", "https://propacha.org/img/wind/wind%20(13).jpg", "https://propacha.org/img/wind/wind%20(14).jpg", "https://propacha.org/img/wind/wind%20(15).jpg", "https://propacha.org/img/wind/wind%20(16).jpg", "https://propacha.org/img/wind/wind%20(17).jpg", "https://propacha.org/img/wind/wind%20(18).jpg", "https://propacha.org/img/wind/wind%20(19).jpg", "https://propacha.org/img/wind/wind%20(20).jpg", "https://propacha.org/img/wind/wind%20(21).jpg", "https://propacha.org/img/wind/wind%20(22).jpg", "https://propacha.org/img/wind/wind%20(23).jpg", "https://propacha.org/img/wind/wind%20(24).jpg", "https://propacha.org/img/wind/wind%20(25).jpg", "https://propacha.org/img/wind/wind%20(26).jpg", "https://propacha.org/img/wind/wind%20(27).jpg", "https://propacha.org/img/wind/wind%20(28).jpg"]
        },
        {
            element: "tv",
            title: "",
            description: "",
            images: ["https://propacha.org/img/tv/tv%20(1).jpg", "https://propacha.org/img/tv/tv%20(2).jpg", "https://propacha.org/img/tv/tv%20(3).jpg", "https://propacha.org/img/tv/tv%20(4).jpg", "https://propacha.org/img/tv/tv%20(5).jpg", "https://propacha.org/img/tv/tv%20(6).jpg", "https://propacha.org/img/tv/tv%20(7).jpg", "https://propacha.org/img/tv/tv%20(8).jpg", "https://propacha.org/img/tv/tv%20(9).jpg", "https://propacha.org/img/tv/tv%20(10).jpg", "https://propacha.org/img/tv/tv%20(11).jpg", "https://propacha.org/img/tv/tv%20(12).jpg", "https://propacha.org/img/tv/tv%20(13).jpg", "https://propacha.org/img/tv/tv%20(14).jpg", "https://propacha.org/img/tv/tv%20(15).jpg", "https://propacha.org/img/tv/tv%20(16).jpg"]
        },
        {
            element: "fire",
            title: "",
            description: "",
            images: ["https://propacha.org/img/fire/fire%20(0).jpg", "https://propacha.org/img/fire/fire%20(1).jpg", "https://propacha.org/img/fire/fire%20(2).jpg", "https://propacha.org/img/fire/fire%20(3).jpg", "https://propacha.org/img/fire/fire%20(4).jpg", "https://propacha.org/img/fire/fire%20(5).jpg", "https://propacha.org/img/fire/fire%20(6).jpg", "https://propacha.org/img/fire/fire%20(7).jpg", "https://propacha.org/img/fire/fire%20(8).jpg", "https://propacha.org/img/fire/fire%20(9).jpg", "https://propacha.org/img/fire/fire%20(10).jpg", "https://propacha.org/img/fire/fire%20(11).jpg", "https://propacha.org/img/fire/fire%20(12).jpg", "https://propacha.org/img/fire/fire%20(13).jpg", "https://propacha.org/img/fire/fire%20(14).jpg", "https://propacha.org/img/fire/fire%20(15).jpg", "https://propacha.org/img/fire/fire%20(16).jpg", "https://propacha.org/img/fire/fire%20(17).jpg", "https://propacha.org/img/fire/fire%20(18).jpg", "https://propacha.org/img/fire/fire%20(19).jpg"]
        }
    ];

    const aliados = {
        images: [
            {
                img: "assets/images/logos-aliados/1-min.png",
                name: "1",
                elementImg: '/assets/images/propacha-logo-nobg.png'
            }, {
                img: "assets/images/logos-aliados/2-min.png",
                name: "2",
                elementImg: '/assets/images/propacha-logo-nobg.png'
            }, {
                img: "assets/images/logos-aliados/3-min.png",
                name: "3",
                elementImg: '/assets/images/propacha-logo-nobg.png'
            }, {
                img: "assets/images/logos-aliados/4-min.png",
                name: "4",
                elementImg: '/assets/images/propacha-logo-nobg.png'
            }, {
                img: "assets/images/logos-aliados/5-min.png",
                name: "5",
                elementImg: '/assets/images/propacha-logo-nobg.png'
            }, {
                img: "assets/images/logos-aliados/6-min.png",
                name: "6",
                elementImg: '/assets/images/propacha-logo-nobg.png'
            }, {
                img: "assets/images/logos-aliados/7-min.png",
                name: "7",
                elementImg: '/assets/images/propacha-logo-nobg.png'
            }, {
                img: "assets/images/logos-aliados/8-min.png",
                name: "8",
                elementImg: '/assets/images/propacha-logo-nobg.png'
            }, {
                img: "assets/images/logos-aliados/9-min.png",
                name: "9",
                elementImg: '/assets/images/propacha-logo-nobg.png'
            }, {
                img: "assets/images/logos-aliados/10-min.png",
                name: "10",
                elementImg: '/assets/images/propacha-logo-nobg.png'
            }, {
                img: "assets/images/logos-aliados/11-min.png",
                name: "11",
                elementImg: '/assets/images/propacha-logo-nobg.png'
            }, {
                img: "assets/images/logos-aliados/12-min.png",
                name: "12",
                elementImg: '/assets/images/propacha-logo-nobg.png'
            }, {
                img: "assets/images/logos-aliados/13-min.png",
                name: "13",
                elementImg: '/assets/images/propacha-logo-nobg.png'
            }, {
                img: "assets/images/logos-aliados/14-min.png",
                name: "14",
                elementImg: '/assets/images/propacha-logo-nobg.png'
            }, {
                img: "assets/images/logos-aliados/15-min.png",
                name: "15",
                elementImg: '/assets/images/propacha-logo-nobg.png'
            }, {
                img: "assets/images/logos-aliados/16-min.png",
                name: "16",
                elementImg: '/assets/images/propacha-logo-nobg.png'
            }, {
                img: "assets/images/logos-aliados/17-min.png",
                name: "17",
                elementImg: '/assets/images/propacha-logo-nobg.png'
            }, {
                img: "assets/images/logos-aliados/18-min.png",
                name: "18",
                elementImg: '/assets/images/propacha-logo-nobg.png'
            }, {
                img: "assets/images/logos-aliados/19-min.png",
                name: "19",
                elementImg: '/assets/images/propacha-logo-nobg.png'
            }, {
                img: "assets/images/logos-aliados/20-min.png",
                name: "20",
                elementImg: '/assets/images/propacha-logo-nobg.png'
            },
        ]
    }

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const handleMouseMove = (event: React.MouseEvent) => {
        const image = event.target as HTMLImageElement;
        const boundingBox = image.getBoundingClientRect();

        const x = ((event.clientX - boundingBox.left) / boundingBox.width) * 100;
        const y = ((event.clientY - boundingBox.top) / boundingBox.height) * 100;

        image.style.setProperty('--x', `${x}%`);
        image.style.setProperty('--y', `${y}%`);
    };


    /*
    const ListaEventos = () => {
        const [active, setActive] = useState(false);

        return <li className={tabActive == 0 ? "is-active" : "disabled"}>
            <small className="fire-txt">{t('fireTeam')}</small>
            <div className="button-and-element">
                <span style={{ color: "#FF7500" }}>{t('fire').toUpperCase()} </span>
                <Link to="/proyectos" className="navbar-item button is-green my-4 btn-donar">{t('Actividades')}</Link>
            </div>
            <p>{t('fireDesc')}</p>

            <div className="lista-eventos">
                <div className={`modal ${active ? "is-active" : ""}`}>
                    <div className="modal-background"></div>
                    <div className="modal-content">
                        www
                    </div>
                    <button onClick={() => { setActive(false) }} className="modal-close is-large" aria-label="close"></button>
                </div>
                <li>
                    <div id="act">
                        <p onClick={() => { setActive(true) }} color="green">{t('Proyecto Plan Piloto para la Reactivación Turística Ambiental en el  Municipio de Escoma')}</p>
                        <div className="img-desc">
                            <div className="container-imgs">
                                <img className=" is-col-span-2" src="/assets/images/actividades/2. ESCOMA-min.jpg" alt="" />

                                <img className="" src="/assets/images/actividades/2. ESCOMA-min.jpg" alt="" />
                                <img className="" src="/assets/images/actividades/2. ESCOMA-min.jpg" alt="" />

                            </div>
                            <div className="desc-act">
                                <p>Nuestro elemento fue parte del ciclo de eventos nacionales destinados a la construcción de la propuesta de Ley de Emergencia Climática desde el año 2021. El proyecto de Ley tuvo como objetivo ser un instrumento normativo regulatorio de carácter operativo, ser elaborado con participación de diferentes sectores de la sociedad, y que posicione el concepto urgente de emergencia climática frente a las autoridades. Este proceso fue liderado por la Plataforma Boliviana Frente al Cambio Climático (PBFCC) donde el Movimiento Propacha participó en una coalición interinstitucional con diversas organizaciones referentes del país. Actualmente, el anteproyecto se encuentra en tratamiento dentro de la Asamblea Legislativa, y se espera que pueda ser aprobado e implementado en los próximos meses.</p>
                            </div>
                        </div>
                    </div>
                </li>

            </div>

        </li>
    }
    */


    /////////////////////////

    const imageRefDer = useRef(null);
    const imageRefIzq = useRef(null);

    useEffect(() => {
        const tiltImages = () => {
            const tiltImageDer = anime({
                targets: imageRefDer.current,
                translateX: 25,
                rotate: 20,
                duration: 1000,
                easing: 'easeInOutSine',
                complete: () => {
                    setTimeout(() => {
                        anime({
                            targets: imageRefDer.current,
                            translateX: 0,
                            rotate: 0,
                            duration: 1000,
                            easing: 'easeInOutSine',
                        });
                    }, 2000);
                },
            });

            const tiltImageIzq = anime({
                targets: imageRefIzq.current,
                translateX: -25,
                rotate: -20,
                duration: 1000,
                easing: 'easeInOutSine',
                complete: () => {
                    setTimeout(() => {
                        anime({
                            targets: imageRefIzq.current,
                            translateX: 0,
                            rotate: 0,
                            duration: 1000,
                            easing: 'easeInOutSine',
                        });
                    }, 2000);
                }
            });

            setTimeout(() => {
                tiltImageDer.reverse();
                tiltImageIzq.reverse();
            }, 4000);
        };


        const tiltTimers = setInterval(tiltImages, 15000);
        return () => {
            clearInterval(tiltTimers);
        };

    }, []);

    //////////////////////////////
    const imagesHome = {
        images: [
            {
                img: "assets/images/logos-aliados/1-min.png",
            }, {
                img: "assets/images/logos-aliados/2-min.png",
            }, {
                img: "assets/images/logos-aliados/3-min.png",
            },
        ]
    }
    //////////////////////////////

    return <div className="home-page page" style={{ overflowX: 'hidden', maxWidth: '100vw' }}>


        <div className="ImagesHomeC head-img positioned home-v2" style={{display:"flex", objectFit:"cover"}}>
            <img src="/assets/images/fondo-3.jpeg" alt="" className="fondoo" style={{objectFit:"cover"}}/>
            {/*
            <Swiper
                style={{ height: "100vh" }}
                slidesPerView={1}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                modules={[Autoplay, Pagination]}

                className="head-img positioned"
                loop={true}
            >
                <SwiperSlide style={{ height: "100vh !important" }}>
                    <img " className="imgHome" alt="Imagen 1" style={{ objectFit: 'cover', maxHeight: "unset !important", height: "100vh !important" }} />
                </SwiperSlide>


                <SwiperSlide style={{ height: "100vh !important" }}>
                    <img src="/assets/images/fondo-2.jpeg" alt="Imagen 2" style={{ objectFit: 'cover', maxHeight: "unset !important", height: "100vh !important" }} />
                </SwiperSlide>
                <SwiperSlide style={{ height: "100vh !important" }}>
                    <img src="/assets/images/fondo-1.jpeg" alt="Imagen 3" style={{ objectFit: 'cover', maxHeight: "unset !important", height: "100vh !important" }} />
                </SwiperSlide>
                
            </Swiper> */}
        </div>



        <div className="title-page">
            <TitlePage title={t('welcomeMessage')} subtitle={t('organizationDescription')} />
        </div>

        <div className="content-page">
            <div className="before">
                <span>Propacha Bolivia</span>
                <img src="/assets/images/propacha-logo-nobg.png" />

            </div>
            <div className="challenges-home">
                <span color="green">{t('results')}</span>

                <Swiper
                    spaceBetween={0}
                    slidesPerView={1}
                    breakpoints={{
                        800: {
                            slidesPerView: 1,
                        },
                    }}
                    autoplay={{
                        delay: 10000,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Autoplay, Pagination]}
                    className="our-projects-swiper"
                    style={{ maxWidth: "calc(40vw)", width: "100%" }}
                >

                    <SwiperSlide>
                        <div className="content-slide-c" style={{ overflowY: 'auto' }}>
                            <span>
                                <span ref={_1ref} color="green">5</span>
                                <p>{t('activities')}</p>
                            </span>
                            <span>
                                <span ref={_2ref} color="green">300</span>
                                <p>{t('activeVolunteers')}</p>
                            </span>
                            <span>
                                <span ref={_3ref} color="green">5</span>
                                <p>{t('completedProjects')}</p>
                            </span>
                            <span>
                                <span ref={_4ref} color="green">9</span>
                                <p>{t('recognitions')}</p>
                            </span>
                        </div>
                    </SwiperSlide>

                    <SwiperSlide>
                        <div className="content-slide-c" style={{ overflowY: 'auto' }}>
                            <span>
                                <span ref={_5ref} color="green">9</span>
                                <p>{t('recognitionsAndAwards')}</p>
                            </span>
                            <span>
                                <span ref={_6ref} color="green">40</span>
                                <p>{t('currentMembers')}</p>
                            </span>
                            <span>
                                <span ref={_7ref} color="green">100</span>
                                <p>{t('activitiesDoneForEnvironment')}</p>
                            </span>
                            <span>
                                <span ref={_8ref} color="green">20</span>
                                <p>{t('alliances')}</p>
                            </span>
                        </div>

                    </SwiperSlide>
                </Swiper>

            </div>



            <div className="Aliados-carrusel">
                <h2 className="tit title-section-component"  >Nuestros aliados</h2>
                <Swiper
                    style={{ width: "100%", height: '100%', maxHeight: 'inherit', marginTop: '0', paddingBottom: '1rem' }}
                    spaceBetween={10}
                    slidesPerView={9}
                    autoplay={{ delay: 1200, disableOnInteraction: false }}
                    modules={[Autoplay]}
                    loop
                    loopPreventsSlide={false}
                    className=""
                    // revisarrrrrrrrrrrr
                    // breakpoints={{
                    //    640: { slidesPerView: 2},
                    //  }}
                    breakpoints={{
                        0: { slidesPerView: 5 },
                        350: { slidesPerView: 7 },
                        800: { slidesPerView: 10 },
                        1100: { slidesPerView: 12 },
                    }}
                >
                    {
                        aliados.images.map((img: { img: string, name: string }) => {
                            return <SwiperSlide className="swiper-slide logo-suggars" style={{ height: 'inherit' }}>
                                <img src={img.img} alt="" style={{ maxHeight: '100%', height: 'inherit', objectFit: 'cover', minHeight: '100%', marginLeft: '0.5rem' }} />
                            </SwiperSlide>
                        })
                    }
                </Swiper>
            </div>


            <div className="form-home pt-0">
                <Title sup={t('become')} sub={t('volunteer')} color="green" />

                <p style={{ margin: "0", display: "block" }}>{t('createAccount')}</p>
                <button className="is-button button mt-3" onClick={() => {
                    navigate("/account");
                }}>{t('joinTeam')}</button>
            </div>



            <div className="proyecto-propacha-page page">

                <div className="how-help-home">
                    <TitleSection>{t('titleSectionElements')}</TitleSection>
                    <div className="tabs-component">
                        <div className="tabs">
                            <ul>
                                <li className={tabActive == 0 ? "is-active fire-txt" : ""} onClick={() => toogleTab(0)}><a>{t('tabFire')} <img src="/assets/images/elements/fire.png" /></a></li>
                                <li className={tabActive == 1 ? "is-active water-txt" : ""} onClick={() => toogleTab(1)}><a>{t('tabWater')} <img src="/assets/images/elements/water.png" /></a></li>
                                <li className={tabActive == 2 ? "is-active soil-txt" : ""} onClick={() => toogleTab(2)}><a>{t('tabSoil')} <img src="/assets/images/elements/soil.png" /></a></li>
                                <li className={tabActive == 3 ? "is-active wind-txt" : ""} onClick={() => toogleTab(3)}><a>{t('tabAir')} <img src="/assets/images/elements/wind.png" /></a></li>
                                <li className={tabActive == 4 ? "is-active tv-txt" : ""} onClick={() => toogleTab(4)}><a>{t('tabTV')} <img src="/assets/images/elements/propachatv.png" /></a></li>
                            </ul>
                        </div>
                        <div className="content">
                            <ul style={{margin:"0"}}>
                                <li className={tabActive == 0 ? "is-active" : "disabled"}>
                                    {/* */}
                                    {/* <img src="assets/images/fondo-6.jpeg" alt="" style={{marginBottom:"1rem"}}/> */}
                                    <small className="fire-txt">{t('teamFireTitle')}</small>
                                    <span>{t('tabFire')}</span>
                                    <p>{t('teamFireDescription')}</p>
                                    
                                </li>
                                <li className={tabActive == 1 ? "is-active" : "disabled"}>
                                    <small className="water-txt">{t('teamWaterTitle')}</small>
                                    <span>{t('tabWater')}</span>
                                    <p>{t('teamWaterDescription')}</p>
                                </li>
                                <li className={tabActive == 2 ? "is-active" : "disabled"}>
                                    <small className="soil-txt">{t('teamSoilTitle')}</small>
                                    <span>{t('tabSoil')}</span>
                                    <p>{t('teamSoilDescription')}</p>
                                </li>
                                <li className={tabActive == 3 ? "is-active" : "disabled"}>
                                    <small className="wind-txt">{t('teamAirTitle')}</small>
                                    <span>{t('tabAir')}</span>
                                    <p>{t('teamAirDescription')}</p>
                                </li>
                                <li className={tabActive == 4 ? "is-active" : "disabled"}>
                                    <small className="tv-txt">{t('teamTVTitle')}</small>
                                    <span>{t('tabTV')}</span>
                                    <p>{t('teamTVDescription')}</p>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>

                <div className="gallery">
                    <ul>
                        {
                            ["fire", "water", "soil", "wind", "tv"].map((el: string, index: number) => {
                                return <li className={tabActive == index ? "is-active" : "disabled"}>
                                    {
                                        events.filter((e: Activity) => {
                                            return e.element == el;
                                        }).map((e: Activity) => {
                                            return <div className="activity">
                                                <Swiper
                                                    style={{ maxWidth: "calc(80vw)", width: "100%" }}
                                                    slidesPerView={1}
                                                    spaceBetween={50}
                                                    breakpoints={{
                                                        600: {
                                                            slidesPerView: 2
                                                        },
                                                        900: {
                                                            slidesPerView: 3
                                                        }
                                                    }}
                                                    autoplay={{
                                                        delay: 2500,
                                                        disableOnInteraction: false,
                                                    }}
                                                    modules={[Autoplay]}

                                                    className="our-projects-swiper"
                                                >
                                                    {
                                                        e.images.map((img: string) => {
                                                            return <SwiperSlide >
                                                                <img src={img} alt="" />
                                                            </SwiperSlide>
                                                        })
                                                    }
                                                </Swiper>

                                                <span><b>{e.title}</b></span>
                                                <p>{e.description}</p>
                                            </div>
                                        })
                                    }
                                </li>
                            })
                        }
                    </ul>
                </div>

            </div>

            
           <OurProyects t={t} /> 

            <div className="form-home contrast donate-section">
                <DonateSection />
            </div>

            <div className="form-home">
                <Title sup={t('wantToCollaborate')} sub={t('withUs')} color="green" />

                <form className="mt-5" action={homeAPI + "contact.php"} method="POST">
                    <div className="field">
                        <label className="label">{t('nameLabel')}</label>
                        <div className="control">
                            <input name="name" required className="input" type="text" placeholder={t('namePlaceholder')} />
                        </div>
                    </div>
                    <div className="field">
                        <label className="label">{t('emailLabel')}</label>
                        <div className="control">
                            <input name="email" required className="input" type="email" placeholder={t('emailPlaceholder')} />
                        </div>
                    </div>
                    <div className="field">
                        <label className="label">{t('cellLabel')}</label>
                        <div className="control">
                            <input name="cell" required className="input" type="text" placeholder={t('cellPlaceholder')} />
                        </div>
                    </div>
                    <div className="field">
                        <label className="label">{t('messageLabel')}</label>
                        <div className="control">
                            <textarea name="message" required className="textarea" placeholder={t('messagePlaceholder')}></textarea>
                        </div>
                    </div>

                    <input style={{ display: "none" }} type="text" value={"form-alianzas"} name="key" />

                    <div className="is-flex is-justify-content-end">
                        <button type="submit" color="green" className="button">{t('sendButton')}</button>
                    </div>
                </form>
            </div>

        </div>



        <Footer />
    </div>

}

export default Inicio; 