import { toast } from "bulma-toast";
import { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { onCatch } from "../common/functions";
import { madePost } from "../common/post";
import { ResponseGeneral, User } from "../common/types";
import { getUser } from "../common/user";
import { setItem } from "../common/useStorage";
import { home } from "../common/variables";
import Footer from "../components/Footer";

const QRProject = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const code = params.get("code");
    const project = params.get("proj");

    const user = useRef<User | null>(getUser());
    const [isLogged, setIsLogged] = useState(user !== null);

    const navigate = useNavigate();

    const confirmIt = () => {
        madePost(
            {
                key: "confirm-project-presence",
                project_code: project,
                code: code,
                user_code: getUser()?.user_code
            },
            "projects.php"
        ).then(r => {
            let res = r as ResponseGeneral;

            if (res.RESULT) {
                toast({ message: ("Gracias por participar! se te adicionarán " + res.VALUE.points + " puntos a tu cuenta"), type: "is-success" });

                let us: User = getUser() as User;

                us.points = parseInt(us.points.toString()) + parseInt(res.VALUE.points.toString());
                us.hours = parseInt(us.hours.toString()) + parseInt(res.VALUE.hours.toString());

                setItem("USER", JSON.stringify(us));

                navigate("/proyecto/actividad-" + project);
            } else {
                toast({ message: res.EXCEPTION_MESSAGE, type: "is-danger" });
            }
        }).catch(onCatch)
    }

    return <div className="qr-project-page">

        {
            (code && project) ?
                <div className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center py-6">
                    {
                        isLogged ?
                            <>
                                <label className="mb-3">Quieres confirmar tu participación en este proyecto?</label>
                                <button className="button is-success" onClick={()=>{
                                    confirmIt()
                                }}>Confirmar</button>
                            </>
                            :
                            <>
                                <label className="mb-3">Para confirmar tu participación debes iniciar sesión</label>
                                <button className="button is-success" onClick={() => {
                                    let actualPath = window.location.pathname

                                    navigate("/account");

                                    window.addEventListener("logged", (e: any) => {
                                        navigate(actualPath)
                                    })
                                }}>Iniciar sesión</button>
                            </>
                    }
                </div>
                :
                <div className="is-flex is-justify-content-center py-6">
                    <label className="is-danger button">Ha ocurrido un error, porfavor revisa el enlace e intenta nuevamente</label>
                </div>
        }

        <Footer />
    </div>

}

export default QRProject;