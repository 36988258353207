import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { ResponseGeneral, User } from "../common/types";
import { Title, TitlePage, TitleSection } from "../components/Components";
import Footer from "../components/Footer";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from "swiper";
// Import Swiper styles
import 'swiper/swiper-bundle.css';

import "./Nosotros.scss";
import { madePost } from "../common/post";
import Loader from "../components/mini_components/Loader";
import { animateNumber } from "../common/functions";
import { DonateSection } from "./CBJLC";
import { useTranslation } from "react-i18next";

const Nosotros = () => {
    const _1ref = useRef({} as HTMLSpanElement);
    const _2ref = useRef({} as HTMLSpanElement);

    const { t } = useTranslation();

    useLayoutEffect(() => {
        animateNumber(_1ref.current, 25);
        animateNumber(_2ref.current, 300);
    }, [])

    const TeamSwiper = () => {
        const [team, setTeam] = useState([
            {
                user_code: 0,
                name: "Luis Guillermo Mallea Morales",
                birthday: "",
                cellphone: "",
                location: "",
                email: "",
                password: "",
                element: "fire",
                image: "https://propacha.org/profiles/gmallea.jpg",
                points: 0,
                hours: 0,
                role: "fundador",
                description: "",
                position: "Cofundador, Director general"
            },
            {
                user_code: 0,
                name: "Isabel Andrea Aliaga Franco",
                birthday: "",
                cellphone: "",
                location: "",
                email: "",
                password: "",
                element: "fire",
                image: "https://propacha.org/profiles/5-isabel-andrea-aliaga-franco.jpeg?5",
                points: 0,
                hours: 0,
                role: "fundador",
                description: "",
                position: "Cofundadora, Directora Legal"
            },
            {
                user_code: 0,
                name: "Pamela Luna Mallea",
                birthday: "",
                cellphone: "",
                location: "",
                email: "",
                password: "",
                element: "fire",
                image: "https://propacha.org/profiles/pluna.jpg",
                points: 0,
                hours: 0,
                role: "coordinador",
                description: "",
                position: "Coordinadora de RedesTV & Agua",
            },
            {
                user_code: 0,
                name: "Alba Durà Royuela",
                birthday: "",
                cellphone: "",
                location: "",
                email: "",
                password: "",
                element: "fire",
                image: "https://propacha.org/profiles/adura.jpg",
                points: 0,
                hours: 0,
                role: "fundador",
                description: "",
                position: "Cofundadora, Directora en Recursos Humanos y Comunicación",
            },
            {
                user_code: 0,
                name: "Juan Orgaz Espinoza",
                birthday: "",
                cellphone: "",
                location: "",
                email: "",
                password: "",
                element: "fire",
                image: "https://propacha.org/profiles/jorgaz.jpg",
                points: 0,
                hours: 0,
                role: "coordinador",
                description: "",
                position: "Coordinador de Fuego",
            },
            {
                user_code: 0,
                name: "Christian Copa Alvarez",
                birthday: "",
                cellphone: "",
                location: "",
                email: "",
                password: "",
                element: "fire",
                image: "https://propacha.org/profiles/3-christian-copa-alvarez.jpeg?5",
                points: 0,
                hours: 0,
                role: "coordinador",
                description: "",
                position: "Desarrollador & Coordinador"
            },
            {
                user_code: 0,
                name: "Gabriel A. Pacheco",
                birthday: "",
                cellphone: "",
                location: "",
                email: "",
                password: "",
                element: "fire",
                image: "https://propacha.org/profiles/gpacheco.jpg",
                points: 0,
                hours: 0,
                role: "miembro",
                description: "",
                position: "Miembro activo"
            },
            {
                user_code: 0,
                name: "Daniel D. Alvarez Arcani",
                birthday: "",
                cellphone: "",
                location: "",
                email: "",
                password: "",
                element: "fire",
                image: "https://propacha.org/profiles/dalvarez.jpg",
                points: 0,
                hours: 0,
                role: "miembro",
                description: "",
                position: "Miembro activo"
            },
            {
                user_code: 0,
                name: "Cristian Iturralde Aparicio",
                birthday: "",
                cellphone: "",
                location: "",
                email: "",
                password: "",
                element: "fire",
                image: "https://propacha.org/profiles/citurralde.jpg",
                points: 0,
                hours: 0,
                role: "coordinador",
                description: "",
                position: "Coordinador de fuego"
            },
            {
                user_code: 0,
                name: "Ana Lucia Encinas",
                birthday: "",
                cellphone: "",
                location: "",
                email: "",
                password: "",
                element: "fire",
                image: "https://propacha.org/profiles/alucia.jpg",
                points: 0,
                hours: 0,
                role: "coordinador",
                description: "",
                position: "Coordinadora de Agua"
            },
            {
                user_code: 0,
                name: "Adhemar Zeballos Narvaez",
                birthday: "",
                cellphone: "",
                location: "",
                email: "",
                password: "",
                element: "fire",
                image: "https://propacha.org/profiles/azeballos.jpg",
                points: 0,
                hours: 0,
                role: "miembro",
                description: "",
                position: "Miembro activo"
            },
            {
                user_code: 0,
                name: "Wanda Romina García Sánchez",
                birthday: "",
                cellphone: "",
                location: "",
                email: "",
                password: "",
                element: "fire",
                image: "https://propacha.org/profiles/wromina.jpg",
                points: 0,
                hours: 0,
                role: "coordinador",
                description: "",
                position: "Coordinadora de Tierra"
            }
        ] as User[]);
        const [isLoad, setIsLoad] = useState(true);

        useEffect(() => {
            if (!isLoad) {
                madePost(
                    {
                        key: "get-users"
                    },
                    "users.php"
                ).then(r => {
                    let res = r as ResponseGeneral;

                    if (res.RESULT) {
                        let us: User[] = [];
                        res.VALUE.forEach((u: User) => {
                            us.push(u)
                        })

                        setTeam(us);
                        setIsLoad(true);
                    }
                })
            }
        }, [isLoad])

        return <div className="swiper-team" style={{ maxWidth: "100vw" }}>
            {["fundador", "coordinador", "miembro"].map((e: string) => {
                return <div className="container-team">
                    <span className="title-swiper-descriptive">{
                        e == "fundador" ?
                            "Fundadores"
                            :
                            e == "coordinador" ?
                                "Coordinadores"
                                :
                                "Miembros"
                    }</span>
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={1}
                        breakpoints={{
                            600: {
                                slidesPerView: 2
                            },
                            900: {
                                slidesPerView: 3
                            }
                        }}
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: false,
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={true}
                        modules={[Autoplay, Pagination, Navigation]}
                        className="us-swiper"
                    >
                        {
                            isLoad ?
                                team.filter((t: User) => {
                                    return t.role == e;
                                }).map((u: User) => {

                                    return <SwiperSlide className="team-int">
                                        <div className="content">
                                            <img src={u.image} onError={(ev: any) => {
                                                ev.target.src = "/assets/images/default-image.jpg";
                                            }} />
                                            <div>
                                                <span><b>{u.name}</b></span>
                                                <span>{u.position}</span>
                                                <p>{u.description}</p>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                })
                                :
                                <Loader color="green" />
                        }
                    </Swiper>
                </div>
            })}
        </div>
    }

    return <div className="nosotros-page page">
        <img src="/assets/images/fondo-2.jpeg" alt="" className="head-img positioned" />

        <div className="title-page">
            <TitlePage
                title={t('titleQuienesSomos')}
                subtitle={t('descriptionQuienesSomos')}
            />
        </div>

        <img src="/assets/svg/img-triangle.svg" className="triangle" />

        <div className="propacha-is ">
            <Title
                sup={t('titleQueEs')}
                sub={t('subtitlePropacha')}
                color="green"
            />

            <p style={{ textAlign: "justify" }}>
                {t('definitionPro')}
                <br /><br />
                {t('definitionPacha')}
                <br />
                <br />
                {t('definitionHarmony')}
            </p>
        </div>

        <div className="history contrast">
            <DonateSection />
        </div>

        <div className="history">
            <Title sup={t('historyTitleSup')} sub={t('historyTitleSub')} color="green" />

            <p style={{ textAlign: "justify" }}>
                {t('historyParagraph1')}
            </p>
            <p style={{ textAlign: "justify" }}>
                {t('historyParagraph2')}
            </p>

            <div className="skills">
                <div color="element-blue">
                    <span ref={_1ref}>+25</span>
                    <p>{t('historyTeamMembers')}</p>
                </div>
                <div color="element-green">
                    <span ref={_2ref}>+300</span>
                    <p>{t('historyVolunteers')}</p>
                </div>
            </div>
        </div>


        <div className="about-us contrast">
            <Title sup={t('aboutUsTitleSup')} sub={t('aboutUsTitleSub')} color="green" />

            <div className="about">
                <div>
                    <h3 color="green">{t('missionTitle')}</h3>
                    <p>
                        {t('missionDescription')}
                    </p>
                </div>
                <div className="hr"></div>
                <div>
                    <h3 color="green">{t('visionTitle')}</h3>
                    <p>
                        {t('visionDescription')}
                    </p>
                </div>
            </div>
        </div>


        <div className="out-team contrast">
            <img src="/assets/images/bienvenida-img-1.jpg" alt="Equipo propacha" />

            <TeamSwiper />
        </div>

        <Footer />
    </div>
}


export default Nosotros;