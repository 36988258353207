import { useEffect, useState } from "react";
import { enviarMensajes, enviarMensajeVoluntario, obtainEventos, obtainPatrocinadores, obtainProyectos, validarCorreo } from "../api";
import { seccion_actividades_2 } from "../themes/seccion-2-actividades";
import Swal from 'sweetalert2';

import '../../node_modules/sweetalert2/dist/sweetalert2.css';
import "./Comps.scss";

import { useTranslation } from "react-i18next";
import "../common/translation";
import i18n from "i18next";
import { disponibleLanguages, languageicon } from "../common/variables";

export let InputBusqueda = (datos: any) => {
    let id = datos["id"];
    let placeholder = datos["placeholder"];
    let className = datos["className"];
    let type = datos["type"];
    let style = datos["style"];
    let disabled = datos["disabled"];

    return <input style={style} className={className + " input-busqueda-comp"} id={id} placeholder=
        {placeholder} type={type} disabled={disabled}></input>;
}

/**     BUSQUEDA DESPLEGABLE     */

export let BusquedaDesplegable = () => {
    const [open, setOpen] = useState(true);

    return <div className="busqueda-desplegable-comp">
        <InputBusqueda id="input-header-busqueda" placeholder="Buscar" style={{}} className="input-header" type="text" disabled={open ? false : true} />

        <img src="/assets/svg/busqueda.svg" width="30" height="30" alt="Icono de Busqueda" onClick={() => {
            let input = document.getElementById("input-header-busqueda") as HTMLInputElement;
            if (input.value.length == 0) {
                setOpen(!open);
            }

            if (open) {
                input.style.display = "flex";
                setTimeout(() => {
                    input.style.width = "100%";
                }, 100);
            } else {
                input.style.width = "0%";
                setTimeout(() => {
                    input.style.display = "none";
                }, 250);
            }
        }} />
    </div>
};

/**     TRANSITION           */
export let Transition = (datos: any) => {
    //time mms
    /**
     * 10% inicio opacidad
     * 90% final opacidad
     */

    let element = datos["element"] as HTMLElement;
    let time = datos["time"];

    const accionPercent = 0.2;

    let cont = document.getElementsByClassName("transition-comp")[0] as HTMLDivElement;
    if (cont) {
        cont.style.opacity = "0";
        cont.style.transition = "opacity .25s ease-in";
        const inicio = setTimeout(() => {
            cont.style.opacity = "1";
        }, (250));
        const final = setTimeout(() => {
            cont.style.opacity = "0";
        }, time - (250));
    }

    return <div className="transition-comp">
        {element}
    </div>

}

/**     SLIDE AUTOMATICO             */
export let SlideAutomatico = (datos: any) => {
    const [position, setPosition] = useState(0);
    const maxPosition = datos["elements"].length;
    const timetoShow = 5000;

    let elements = datos["elements"];

    useEffect(() => {
        const interval = setInterval(() => {
            setPosition(position => position < maxPosition - 1 ? position + 1 : 0);
        }, timetoShow);
        return (() => { clearInterval(interval) })
    }, []);



    return <div>
        <Transition time={timetoShow} element={elements[position]} />
    </div>
}

/*      ACTIVIDADES PROPACHA INTERCATIVO        */
export let ActividadesInteractivo = () => {
    const l1 = <>Buscamos hacer públicas denuncias que nos llegan y participar organizando las campañas que realizamos para que la Pacha sea respetada, utilizando la <strong>NO VIOLENCIA</strong></>
    const l2 = "Un proyecto que busca mejorar la vida de los animales en situación de calle en la ciudad, para darles voz y mejores condiciones, mediante campañas para la concientización de preservación de especies";
    const r1 = "El espacio de los creativos y hábiles con las manos para crear artes informativos y videos para incentivar en casa con manualidades consejos o hacer cosas por el medio ambiente y la vida útil de todos";
    const r2 = <>¡El reportero que tienes dentro!<br />Consiste en realizar contenidos, desde noticias, hasta participar en podcast y/o Propacha TV para informar sobre el medio ambiente, economia, politica, etc tanto Nacional como Internacionalmente</>;

    const bgOpacity = 0.2;

    useEffect(() => {
        let r1E = document.getElementsByClassName("R1") as HTMLCollectionOf<HTMLElement>;
        let r2E = document.getElementsByClassName("R2") as HTMLCollectionOf<HTMLElement>;
        let l1E = document.getElementsByClassName("L1") as HTMLCollectionOf<HTMLElement>;
        let l2E = document.getElementsByClassName("L2") as HTMLCollectionOf<HTMLElement>;

        let r1S = document.getElementById("R1-span") as HTMLSpanElement;
        let r2S = document.getElementById("R2-span") as HTMLSpanElement;
        let l1S = document.getElementById("L1-span") as HTMLSpanElement;
        let l2S = document.getElementById("L2-span") as HTMLSpanElement;

        let onClick = (elem: string, span: HTMLSpanElement) => {
            span.style.backgroundColor = "rgba(var(--" + elem + "-rgb)," + bgOpacity + ")";
            span.style.transition = "background-color .1s ease-in";
            setTimeout(() => {
                span.style.backgroundColor = "white";
            }, 200);

            window.location.href = "#" + span.id;
        }

        let setToArray = (elems: HTMLCollectionOf<HTMLElement>, fun: any) => {
            for (let i = 0; i < elems.length; i++) {
                elems[i].onclick = fun;
            }
        }

        setToArray(r1E, () => { onClick("r1", r1S) });
        setToArray(r2E, () => { onClick("r2", r2S) });
        setToArray(l1E, () => { onClick("l1", l1S) });
        setToArray(l2E, () => { onClick("l2", l2S) });

    }, []);

    return <div className="actividades-interactivo-comp">
        <div id="actividades-intercativo-center_hidde">
            {seccion_actividades_2}
        </div>
        <div className="actividades-interactivo-left">
            <span className="l1-actividad" id="L1-span">{l1}</span>
            <span className="l2-actividad" id="L2-span">{l2}</span>
        </div>
        <div className="actividades-intercativo-center">
            {seccion_actividades_2}
        </div>

        <div className="actividades-interactivo-right">
            <span className="r1-actividad" id="R1-span">{r1}</span>
            <span className="r2-actividad" id="R2-span">{r2}</span>
        </div>
    </div>
}

/*          NUESTROS PROYECTOS          */
export let NuestrosProyectos = () => {
    const [cargado, setCargado] = useState(false);
    const [proyectos, setProyectos] = useState([]);

    useEffect(() => {
        if (!cargado) {
            obtainProyectos().then((prs: any) => {
                let proys = prs as [];
                setProyectos(proys);
                setCargado(true);
            });
        }
    }, []);

    let card = (datos: any, index: number) => {
        let img: string = datos["img"];
        let nombre = datos["nombre"];
        let desc = datos["descripcion"];

        let imgJpeg = img.replace(".webp", ".jpeg");

        return <div className="card-proyecto" key={"card-proyecto-" + index}>
            <picture className="card-proyecto-bgimg">
                <source media="(min-width:800px)" srcSet={imgJpeg} type="image/jpeg" />
                <img src={img} alt="Tarjeta de proyectos Propacha" />
            </picture>
            <div className="card-proyecto-descripcion">
                <span>
                    {desc}
                </span>
            </div>
        </div>
    }

    let cards = () => {
        let res = [];
        for (let i = 0; i < proyectos.length; i++) {
            res.push(card(proyectos[i], i));
        }
        return res;
    }

    return <div className="nuestros-proyectos-comp">
        <div className="nuestros-proyectos-titulo">
            <span>NUESTROS</span>
            <h1>PROYECTOS</h1>
        </div>
        <div className="nuestros-proyectos-cards">
            {cargado ?
                cards()
                :
                <></>
            }
        </div>
    </div>
}

/*      INPUT       */

export let Input = (datos: any) => {
    let id = datos.id;
    let grupo = datos.grupo;
    let placeholder = datos.placeholder;
    let style: {} = datos.style;
    let type = datos.type;

    return type ? <textarea id={id} placeholder={placeholder} className={grupo + " input-component"} rows={7} style={Object.assign({}, {}, style)}></textarea> :
        <input id={id} placeholder={placeholder} className={grupo + " input-component"} style={Object.assign({}, {}, style)}></input>
}

export let Submit = (datos: any) => {
    let onClick = datos.onClick;
    let id = datos.id;
    let text = datos.text;

    return <div className="submit-component" onClick={onClick} id={id}>
        <span className="submit-component-text">{text}</span>
    </div>
}


/**     UNETE A NOSOTROS         */
export let UneteANosotros = () => {
    let enviarMensaje = () => {
        let nombre = document.getElementById("nombre-unete-form") as HTMLInputElement;
        let correo = document.getElementById("correo-unete-form") as HTMLInputElement;
        let msj = document.getElementById("mensaje-unete-form") as HTMLInputElement;

        if (validarCorreo(correo.value)) {
            if (nombre.value.length > 0 && msj.value.length > 0) {
                enviarMensajes(nombre.value, correo.value, msj.value).then((resp => {
                    let respuesta = resp as string;
                    if (respuesta === "0x0000" || respuesta.replace(/\s/g, '') === "0x0000") {
                        Swal.fire({
                            title: 'Hemos recibido tu mensaje',
                            text: 'Gracias por contactarte con nosotros',
                            icon: 'success',
                            confirmButtonText: 'Cerrar',
                            customClass: { confirmButton: "btn-sweet-alert" }
                        });
                    } else {
                        Swal.fire({
                            title: 'Algo ha salido mal :(',
                            text: 'Porfavor intentalo nuevamente o contactate con nosotros a traves de nuestro correo: business@propacha.org',
                            icon: 'error',
                            confirmButtonText: 'Cerrar',
                            customClass: { confirmButton: "btn-sweet-alert" }
                        });
                    }
                })).catch(err => {
                })
            } else {
                Swal.fire({
                    title: 'Error!',
                    text: 'Debes llenar todos los campos',
                    icon: 'error',
                    confirmButtonText: 'Volver a intentar',
                    customClass: { confirmButton: "btn-sweet-alert" }
                });
            }
        } else {
            Swal.fire({
                title: 'Error!',
                text: 'Escribe un correo electronico válido',
                icon: 'error',
                iconColor: 'var(--alert-color)',
                confirmButtonText: 'Volver a intentar',
                customClass: { confirmButton: "btn-sweet-alert" }
            });
        }
    }
    return <div className="unete-component">
        <span className="unete-title-1">UNETE A NOSOTROS</span>
        <h2 className="unete-title-2">Buscamos alianzas o inversores</h2>

        <div className="unete-form">
            <Input id="nombre-unete-form" grupo="unete-form-inputs" placeholder="Nombre" />
            <Input id="correo-unete-form" grupo="unete-form-inputs" placeholder="Correo electrónico" />
            <Input id="mensaje-unete-form" grupo="unete-form-inputs" placeholder="Mensaje" type="large" style={{ height: "200%" }} />
            <Submit id="enviar-unete-form" text="Enviar" onClick={enviarMensaje} />
        </div>
    </div>
}

/*      NUESTRAS REDES      */
export let RedesFrames = () => {
    return <div className="facebook-component" style={{ padding: 0 }}>
        {/*
        
        <iframe className="facebook-iframe" loading="lazy"
            src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fmovimientopropacha&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
            style={{border:"none",overflow:"hidden"}} scrolling="no" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share">
        </iframe>
        */}
    </div>
}


/*          EVENTOS Y TRANSMISIONES     */
export let Eventos = () => {
    const [cargado, setCargado] = useState(false);
    const [eventos, setEventos] = useState([]);

    useEffect(() => {
        if (!cargado) {
            obtainEventos().then(resp => {
                let ev = resp as [];
                setEventos(ev);
                setCargado(true);
            })
        }
    }, [])

    return <div className="eventos-seccion-component">
        <img className="bg-image-eventos" alt="Image Propacha Team" src="https://propacha.org/media/images/web/bg-propacha-seccion-redes.jpg" />
        <span className="sigue-texto-eventos-seccion">SIGUE</span>
        <span className="titulo-eventos-seccion">NUESTROS EVENTOS Y TRANSMISIONES</span>
        <div className="eventos-lista">
            {eventos.map(({ titulo, fecha, hora, lugar }) => (
                <div className="evento-lista">
                    <img src="/assets/images/icono-red-propacha.png" alt="Icono Red" />
                    <div className="evento-lista-datos">
                        <div>
                            <span className="evento-dato-titulo">{titulo}</span>
                            <span className="evento-dato-hora"> {hora}{hora !== null ? ":00 HORAS" : ""}</span>
                        </div>
                        <div>
                            <span className="evento-dato-fecha">{fecha !== null ? fecha : "PROXIMAMENTE"} {" "}</span>
                            <span className="evento-dato-lugar">{lugar}</span>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    </div>
}

/*          CONTACTANOS AHORA           */
export let ContactanosCard = () => {
    return <div className="contactanos-card-component">
        <div>
            <img src="/assets/images/icon-phone-white.png" alt="Icono de Telefono" width="100" height="100" style={{ objectFit: "contain" }} />
            <span className="t1-contactanos-card">¡CONTACTÁNOS AHORA!</span>
            <span className="t2-contactanos-card">Nuestro número es: +591 65191664</span>

            <div className="btn-llamada-contactanos" onClick={() => {
                window.location.href = "https://api.whatsapp.com/message/NBT3R7X7BDGUK1";
            }}>
                <span>Contáctanos</span>
                <img alt="Icono chevron derecha" src="/assets/svg/chevron_right_white.svg" width="20" height="20" />
            </div>
        </div>
    </div>
}

/**     QUIENES NOS APOYAN       */
export let Patrocinadores = () => {
    const [cargado, setCargado] = useState(false);
    const [patrocinadores, setPatrocinadores] = useState([]);

    useEffect(() => {
        if (!cargado) {
            obtainPatrocinadores().then(resp => {
                let pat = resp as [];
                setPatrocinadores(pat);
                setCargado(true);
            })
        }

    }, [])
    return <div className="patrocinadores-component">
        <div>
            <span>¿Quienes nos</span>
            <span>APOYAN?</span>
        </div>
        <div>
            {patrocinadores.map(({ nombre, logo }) => (
                <img alt={nombre + " Patrocinador"} src={logo} className="patrocinador-img" width="80px" height="80px" style={{ objectFit: "contain", marginTop: "1em" }} />
            ))}
        </div>

    </div>
}

/*      CONTACTANOS         */
export let Contactanos = () => {
    return <div className="contactanos-component">
        <span>CONTÁCTANOS</span>
        <a href="mailto:propachabolivia@gmail.com">propachabolivia@gmail.com</a>
        <a href="mailto:productospacha.bolivia@gmail.com">productospacha.bolivia@gmail.com</a>
        <a href="https://api.whatsapp.com/message/NBT3R7X7BDGUK1">+591 65191664</a>

        <div className="contactanos-redes-sociales">
            <img src="/assets/svg/facebook.svg" alt="logo-facebook-redes-sociales" width="30" height="30" />
            <img src="/assets/svg/instagram.svg" alt="logo-instagram-redes-sociales" width="30" height="30" />
            <img src="/assets/svg/twitter.svg" alt="logo-twitter-redes-sociales" width="30" height="30" />
            <img src="/assets/svg/linkedin.svg" alt="logo-linkedin-redes-sociales" width="30" height="30" />
            <img src="/assets/svg/youtube.svg" alt="logo-youtube-redes-sociales" width="30" height="30" />
        </div>
    </div>
}


/**     HAZTE VOLUNTARIO         */
export let HasteVoluntario = () => {
    let enviarMensaje = () => {
        let nombre = document.getElementById("nombre-voluntario-form") as HTMLInputElement;
        let correo = document.getElementById("correo-voluntario-form") as HTMLInputElement;
        let msj = document.getElementById("mensaje-voluntario-form") as HTMLInputElement;

        if (validarCorreo(correo.value)) {
            if (nombre.value.length > 0 && msj.value.length > 0) {
                enviarMensajeVoluntario(nombre.value, correo.value, msj.value).then((resp => {
                    let respuesta = resp as string;
                    if (respuesta === "0x0000" || respuesta.replace(/\s/g, '') === "0x0000") {
                        Swal.fire({
                            title: 'Hemos recibido tu mensaje',
                            text: 'Gracias por contactarte con nosotros',
                            icon: 'success',
                            confirmButtonText: 'Cerrar',
                            customClass: { confirmButton: "btn-sweet-alert" }
                        });
                    } else {
                        Swal.fire({
                            title: 'Algo ha salido mal :(',
                            text: 'Porfavor intentalo nuevamente o contactate con nosotros a traves de nuestro correo: voluntarios@propacha.org',
                            icon: 'error',
                            confirmButtonText: 'Cerrar',
                            customClass: { confirmButton: "btn-sweet-alert" }
                        });
                    }
                })).catch(err => {
                })
            } else {
                Swal.fire({
                    title: 'Error!',
                    text: 'Debes llenar todos los campos',
                    icon: 'error',
                    confirmButtonText: 'Volver a intentar',
                    customClass: { confirmButton: "btn-sweet-alert" }
                });
            }
        } else {
            Swal.fire({
                title: 'Error!',
                text: 'Escribe un correo electronico válido',
                icon: 'error',
                iconColor: 'var(--alert-color)',
                confirmButtonText: 'Volver a intentar',
                customClass: { confirmButton: "btn-sweet-alert" }
            });
        }
    }
    return <div className="voluntario-component">
        <div className="voluntariado-card">
            <span className="voluntario-title-1">HAZTE VOLUNTARIO</span>
            <h2 className="voluntario-title-2">Buscamos</h2>
            <ul>
                <li>Educar y concientizar a la población</li>
                <li>Mejorar la relación entre uno y la tierra</li>
                <li>Promover cuidades y comunidades sostenibles</li>
                <li>Crecimiento personal en conexión con la naturaleza</li>
            </ul>

            <div className="voluntario-form">
                <Input id="nombre-voluntario-form" grupo="voluntario-form-inputs" placeholder="Nombre" />
                <Input id="correo-voluntario-form" grupo="voluntario-form-inputs" placeholder="Correo electrónico" />
                <Input id="mensaje-voluntario-form" grupo="voluntario-form-inputs" placeholder="¿Por qué te gustaria ser voluntario? Cuentanos" type="large" style={{ height: "200%" }} />
                <Submit id="enviar-voluntario-form" text="Enviar" onClick={enviarMensaje} />
            </div>

        </div>
    </div>

}

/*      HANGE LANGUAGE ICON     */
export let ChangeLanguageMenu = (props: { className?: string }) => {
    const { t } = useTranslation();

    const [lng, setlng] = useState(i18n.language);

    const clickHandle = () => {
        setlng(lng == "es" ? "en" : "es");
    }

    useEffect(() => {
        i18n.changeLanguage(lng);
    }, [lng])

    const disponible_languages = disponibleLanguages;

    return <li className={"change-language-menu"} onClick={clickHandle}>
        {languageicon}
        <span>{i18n.language}</span>
        <ul className="desplegable-menu" style={{ display: 'none' }}>
            {disponible_languages.map((element: { name: string, type: string }, index: number) => {
                return <li key={"disponible_language_key_".concat(index.toString())} className="header-list-element" onClick={() => {

                }}>
                    {element.name}
                </li>
            })}
        </ul>
    </li>
}