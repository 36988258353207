
import { Title, TitlePage, TitleSection } from "../components/Components";
import Footer from "../components/Footer";

import "./ProyectoPropacha.scss";
import "./Inicio.scss";
import { useEffect, useState } from "react";
import { homeAPI } from "../common/variables";

import "../common/translation";

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from "swiper";
// Import Swiper styles
import 'swiper/swiper-bundle.css';
import anime from "animejs";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Modal from "../components/modal";
export type Activity = {
    element: 'soil' | "water" | "wind" | "tv" | "fire",
    title: string,
    description: string,
    images: string[]
}
const ProyectoPropacha = () => {

    const [tabActive, settabActive] = useState(0);
    const [isAnim, setIsAnim] = useState(true);

    const { t } = useTranslation();

    const navigate = useNavigate();

    const toogleTab = (a: number) => {
        setIsAnim(false)
        settabActive(a);
    }

    const change = () => {
        if (isAnim) {

            setTimeout(() => {
                settabActive((tabActive < 4 ? (tabActive + 1) : 0));
            }, 5000)
        } else {

        }
    }
    useEffect(() => {
        anime({
            targets: '.tabs-component .content .is-active',
            translateY: 0,
            scale: 1
        });
        anime({
            targets: '.tabs-component .content .disabled',
            translateY: 200,
            scale: 1
        });

        change();
    }, [tabActive])


    let events: Activity[] = [
        {
            element: "water",
            title: "",
            description: "",
            images: [
                "https://propacha.org/img/water/water%20(1).jpg", "https://propacha.org/img/water/water%20(2).jpg", "https://propacha.org/img/water/water%20(3).jpg", "https://propacha.org/img/water/water%20(4).jpg", "https://propacha.org/img/water/water%20(5).jpg", "https://propacha.org/img/water/water%20(6).jpg", "https://propacha.org/img/water/water%20(7).jpg", "https://propacha.org/img/water/water%20(8).jpg", "https://propacha.org/img/water/water%20(9).jpg", "https://propacha.org/img/water/water%20(10).jpg", "https://propacha.org/img/water/water%20(11).jpg", "https://propacha.org/img/water/water%20(12).jpg", "https://propacha.org/img/water/water%20(13).jpg"
            ]
        },
        {
            element: "soil",
            title: "",
            description: "",
            images: [
                "https://propacha.org/img/soil/soil%20(1).jpg",
                "https://propacha.org/img/soil/soil%20(2).jpeg",
                "https://propacha.org/img/soil/soil%20(3).jpeg",
                "https://propacha.org/img/soil/soil%20(4).jpeg",
            ]
        },
        {
            element: "wind",
            title: "",
            description: "",
            images: ["https://propacha.org/img/wind/wind%20(1).jpg", "https://propacha.org/img/wind/wind%20(2).jpg", "https://propacha.org/img/wind/wind%20(3).jpg", "https://propacha.org/img/wind/wind%20(4).jpg", "https://propacha.org/img/wind/wind%20(5).jpg", "https://propacha.org/img/wind/wind%20(6).jpg", "https://propacha.org/img/wind/wind%20(7).jpg", "https://propacha.org/img/wind/wind%20(8).jpg", "https://propacha.org/img/wind/wind%20(9).jpg", "https://propacha.org/img/wind/wind%20(10).jpg", "https://propacha.org/img/wind/wind%20(11).jpg", "https://propacha.org/img/wind/wind%20(12).jpg", "https://propacha.org/img/wind/wind%20(13).jpg", "https://propacha.org/img/wind/wind%20(14).jpg", "https://propacha.org/img/wind/wind%20(15).jpg", "https://propacha.org/img/wind/wind%20(16).jpg", "https://propacha.org/img/wind/wind%20(17).jpg", "https://propacha.org/img/wind/wind%20(18).jpg", "https://propacha.org/img/wind/wind%20(19).jpg", "https://propacha.org/img/wind/wind%20(20).jpg", "https://propacha.org/img/wind/wind%20(21).jpg", "https://propacha.org/img/wind/wind%20(22).jpg", "https://propacha.org/img/wind/wind%20(23).jpg", "https://propacha.org/img/wind/wind%20(24).jpg", "https://propacha.org/img/wind/wind%20(25).jpg", "https://propacha.org/img/wind/wind%20(26).jpg", "https://propacha.org/img/wind/wind%20(27).jpg", "https://propacha.org/img/wind/wind%20(28).jpg"]
        },
        {
            element: "tv",
            title: "",
            description: "",
            images: ["https://propacha.org/img/tv/tv%20(1).jpg", "https://propacha.org/img/tv/tv%20(2).jpg", "https://propacha.org/img/tv/tv%20(3).jpg", "https://propacha.org/img/tv/tv%20(4).jpg", "https://propacha.org/img/tv/tv%20(5).jpg", "https://propacha.org/img/tv/tv%20(6).jpg", "https://propacha.org/img/tv/tv%20(7).jpg", "https://propacha.org/img/tv/tv%20(8).jpg", "https://propacha.org/img/tv/tv%20(9).jpg", "https://propacha.org/img/tv/tv%20(10).jpg", "https://propacha.org/img/tv/tv%20(11).jpg", "https://propacha.org/img/tv/tv%20(12).jpg", "https://propacha.org/img/tv/tv%20(13).jpg", "https://propacha.org/img/tv/tv%20(14).jpg", "https://propacha.org/img/tv/tv%20(15).jpg", "https://propacha.org/img/tv/tv%20(16).jpg"]
        },
        {
            element: "fire",
            title: "",
            description: "",
            images: ["https://propacha.org/img/fire/fire%20(0).jpg", "https://propacha.org/img/fire/fire%20(1).jpg", "https://propacha.org/img/fire/fire%20(2).jpg", "https://propacha.org/img/fire/fire%20(3).jpg", "https://propacha.org/img/fire/fire%20(4).jpg", "https://propacha.org/img/fire/fire%20(5).jpg", "https://propacha.org/img/fire/fire%20(6).jpg", "https://propacha.org/img/fire/fire%20(7).jpg", "https://propacha.org/img/fire/fire%20(8).jpg", "https://propacha.org/img/fire/fire%20(9).jpg", "https://propacha.org/img/fire/fire%20(10).jpg", "https://propacha.org/img/fire/fire%20(11).jpg", "https://propacha.org/img/fire/fire%20(12).jpg", "https://propacha.org/img/fire/fire%20(13).jpg", "https://propacha.org/img/fire/fire%20(14).jpg", "https://propacha.org/img/fire/fire%20(15).jpg", "https://propacha.org/img/fire/fire%20(16).jpg", "https://propacha.org/img/fire/fire%20(17).jpg", "https://propacha.org/img/fire/fire%20(18).jpg", "https://propacha.org/img/fire/fire%20(19).jpg"]
        }
    ];


    return <div className="proyecto-propacha-page page">
        <img src="/assets/images/fondo-1.jpeg" alt="" className="head-img positioned" />

        <div className="title-page">
            <TitlePage title={t('titlePageTitle')} subtitle={t('titlePageSubtitle')} />
        </div>


        <img src="/assets/svg/img-triangle.svg" className="triangle" />

        <div className="how-help-home">
            <TitleSection>{t('howWeHelp')}</TitleSection>
            <div className="tabs-component">
                <div className="tabs">
                    <ul>
                        <li className={tabActive == 0 ? "is-active fire-txt" : ""} onClick={() => toogleTab(0)}><span>{t('fire')} <img src="/assets/images/elements/fire.png" /></span></li>
                        <li className={tabActive == 1 ? "is-active water-txt" : ""} onClick={() => toogleTab(1)}><span>{t('water')} <img src="/assets/images/elements/water.png" /></span></li>
                        <li className={tabActive == 2 ? "is-active soil-txt" : ""} onClick={() => toogleTab(2)}><span>{t('soil')} <img src="/assets/images/elements/soil.png" /></span></li>
                        <li className={tabActive == 3 ? "is-active wind-txt" : ""} onClick={() => toogleTab(3)}><span>{t('wind')} <img src="/assets/images/elements/wind.png" /></span></li>
                        <li className={tabActive == 4 ? "is-active tv-txt" : ""} onClick={() => toogleTab(4)}><span>{t('tvNetwork')} <img src="/assets/images/elements/propachatv.png" /></span></li>
                    </ul>
                </div>
                <div className="content">
                    <ul>

                        <li className={tabActive == 0 ? "is-active" : "disabled"}>
                            <small className="fire-txt">{t('fireTeam')}</small>
                            <div className="button-and-element">
                                <span style={{ color: "#FF7500" }}>{t('fire').toUpperCase()} </span>
                            </div>
                            <p>{t('fireDesc')}</p>

                            <div className="linea-horizontal"></div>

                            <Modal childrenModal={<div>
                                <img src="/assets/images/elements/fire.png" style={{ height: "3rem", width: "auto" }} alt="" />

                                <div>
                                    <h2 style={{ padding: "0.5rem", backgroundColor: "#00000005", borderRadius: "5px", color: "#00651E" }}>{t('home-fire.p1.title')}</h2>
                                </div>
                                <div>
                                    <p>{t('home-fire.p1.description')}</p>

                                    <div className="img-modal-act">
                                        <img src="/assets/images/actividades/fire-act/e-1.jpg" alt="" />
                                        <img src="/assets/images/actividades/fire-act/e-2.jpg" alt="" />
                                        <img src="/assets/images/actividades/fire-act/e-3.jpg" alt="" />
                                    </div>

                                    {/* <div>
                                            <ul>
                                                <li><b>Titulo:</b>Reto Ciudad Naturaleza</li>
                                                <li><b>Año:</b>2022 y abril  2023</li>
                                                <li><b>Lugar:</b>Bosquecillo de Auquisamaña - Zongo</li>
                                                <li><b>Beneficiarios:</b>La ciudad de La Paz, galardonada como la ciudad con gran fauna y flora.</li>
                                                <li><b>Descripción:</b>“Reto Ciudad Naturaleza” representa un esfuerzo colaborativo a nivel global para documentar las observaciones de plantas y animales en entornos urbanos y naturales. La ciudad de La Paz ha obtenido el reconocimiento mundial en dos ocasiones, demostrando su compromiso con la conservación y la biodiversidad urbana. Propacha fue parte de este concurso dos años consecutivos, aportando a este logro.</li>
                                            </ul>
                                        </div> */}
                                    <div style={{ margin: "1rem 0" }} className="linea-horizontal"></div>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <div>
                                            <h6>Integrantes del equipo:</h6>
                                            <ul>
                                                <li>Juan Orgaz Espinoza (Coordinador de Fuego)</li>
                                                <li> Dafne Rivera Flores (Coordinadora de Fuego)</li>
                                                <li>Azul Tarifa Tovar (Directora de Activismo)</li>
                                                <li>Kamila Ortiz Larrea (Directora de RRSS)</li>
                                                <li>Daniela Fernández Campos (Directora de Investigación)</li>
                                                <li>Isabel Aliaga Franco (Directora de Activismo)</li>
                                            </ul>
                                        </div>

                                        <img style={{ height: "15rem", objectFit: "cover", margin: "2rem", borderRadius: "5px" }} src="/assets/images/page-donar-1/fuego 1-min.jpg" alt="" />

                                    </div>
                                </div>
                            </div>}


                                title={t('home-fire.p1.title')}
                            >
                                <div id="act">
                                    <div className="img-desc">
                                        <div className="container-imgs">
                                            <img className=" is-col-span-2" src="/assets/images/actividades/2. ESCOMA-min.jpg" alt="" />

                                            {/* 
                                                ///////pie de imagen
                                                <img className="" src="/assets/images/actividades/2. ESCOMA-min.jpg" alt="" />
                                                <img className="" src="/assets/images/actividades/2. ESCOMA-min.jpg" alt="" />
                                                */}

                                        </div>
                                        <div className="desc-act">
                                            <p>{t('home-fire.p1.description')}</p>
                                        </div>
                                    </div>
                                </div>
                            </Modal>

                            <Modal childrenModal={<div>
                                <img src="/assets/images/elements/fire.png" style={{ height: "3rem", width: "auto" }} alt="" />

                                <div>
                                    <h2 style={{ padding: "0.5rem", backgroundColor: "#00000005", borderRadius: "5px", color: "#00651E" }}>{t('home-fire.p2.title')}</h2>
                                </div>
                                <div>
                                    <p>{t('home-fire.p2.description')}</p>

                                    <div className="img-modal-act">
                                        <img src="/assets/images/actividades/fire-act/f-1.jpeg" alt="" />
                                        <img src="/assets/images/actividades/fire-act/f-2.jpeg" alt="" />
                                        <img src="/assets/images/actividades/fire-act/f-3.jpeg" alt="" />
                                    </div>

                                    {/* <div>
                                            <ul>
                                                <li><b>Titulo:</b>Reto Ciudad Naturaleza</li>
                                                <li><b>Año:</b>2022 y abril  2023</li>
                                                <li><b>Lugar:</b>Bosquecillo de Auquisamaña - Zongo</li>
                                                <li><b>Beneficiarios:</b>La ciudad de La Paz, galardonada como la ciudad con gran fauna y flora.</li>
                                                <li><b>Descripción:</b>“Reto Ciudad Naturaleza” representa un esfuerzo colaborativo a nivel global para documentar las observaciones de plantas y animales en entornos urbanos y naturales. La ciudad de La Paz ha obtenido el reconocimiento mundial en dos ocasiones, demostrando su compromiso con la conservación y la biodiversidad urbana. Propacha fue parte de este concurso dos años consecutivos, aportando a este logro.</li>
                                            </ul>
                                        </div> */}
                                    <div style={{ margin: "1rem 0" }} className="linea-horizontal"></div>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <div>
                                            <h6>Integrantes del equipo:</h6>
                                            <ul>
                                                <li>Juan Orgaz Espinoza (Coordinador de Fuego)</li>
                                                <li> Dafne Rivera Flores (Coordinadora de Fuego)</li>
                                                <li>Azul Tarifa Tovar (Directora de Activismo)</li>
                                                <li>Kamila Ortiz Larrea (Directora de RRSS)</li>
                                                <li>Daniela Fernández Campos (Directora de Investigación)</li>
                                                <li>Isabel Aliaga Franco (Directora de Activismo)</li>
                                            </ul>
                                        </div>

                                        <img style={{ height: "15rem", objectFit: "cover", margin: "2rem", borderRadius: "5px" }} src="/assets/images/page-donar-1/fuego 1-min.jpg" alt="" />

                                    </div>
                                </div>
                            </div>}
                                title={t("home-fire.p2.title")}
                            >
                                <div id="act">
                                    <div className="img-desc">
                                        <div className="container-imgs">
                                            <img className=" is-col-span-2" src="/assets/images/actividades/fmb.jpeg" alt="" />

                                        </div>
                                        <div className="desc-act">
                                            <p>{t('home-fire.p2.description')}</p>                                            </div>
                                    </div>
                                </div>
                            </Modal>
                            <Modal childrenModal={<div>
                                <img src="/assets/images/elements/fire.png" style={{ height: "3rem", width: "auto" }} alt="" />

                                <div>
                                    <h2 style={{ padding: "0.5rem", backgroundColor: "#00000005", borderRadius: "5px", color: "#00651E" }}>{t('home-fire.p3.title')}</h2>
                                </div>
                                <div>
                                    <p>{t('home-fire.p3.description')}</p>

                                    <div className="img-modal-act">
                                        <img src="/assets/images/actividades/team-fire.jpeg" alt="" />
                                    </div>

                                    {/* <div>
                                            <ul>
                                                <li><b>Titulo:</b>Reto Ciudad Naturaleza</li>
                                                <li><b>Año:</b>2022 y abril  2023</li>
                                                <li><b>Lugar:</b>Bosquecillo de Auquisamaña - Zongo</li>
                                                <li><b>Beneficiarios:</b>La ciudad de La Paz, galardonada como la ciudad con gran fauna y flora.</li>
                                                <li><b>Descripción:</b>“Reto Ciudad Naturaleza” representa un esfuerzo colaborativo a nivel global para documentar las observaciones de plantas y animales en entornos urbanos y naturales. La ciudad de La Paz ha obtenido el reconocimiento mundial en dos ocasiones, demostrando su compromiso con la conservación y la biodiversidad urbana. Propacha fue parte de este concurso dos años consecutivos, aportando a este logro.</li>
                                            </ul>
                                        </div> */}
                                    <div style={{ margin: "1rem 0" }} className="linea-horizontal"></div>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <div>
                                            <h6>Integrantes del equipo:</h6>
                                            <ul>
                                                <li>Juan Orgaz Espinoza (Coordinador de Fuego)</li>
                                                <li> Dafne Rivera Flores (Coordinadora de Fuego)</li>
                                                <li>Azul Tarifa Tovar (Directora de Activismo)</li>
                                                <li>Kamila Ortiz Larrea (Directora de RRSS)</li>
                                                <li>Daniela Fernández Campos (Directora de Investigación)</li>
                                                <li>Isabel Aliaga Franco (Directora de Activismo)</li>
                                            </ul>
                                        </div>

                                        <img style={{ height: "15rem", objectFit: "cover", margin: "2rem", borderRadius: "5px" }} src="/assets/images/page-donar-1/fuego 1-min.jpg" alt="" />

                                    </div>
                                </div>
                            </div>}
                                title={t('home-fire.p3.title')}
                            >
                                <div id="act">
                                    <div className="img-desc">
                                        <div className="container-imgs">
                                            <img className=" is-col-span-2" src="/assets/images/actividades/team-fire.jpeg" alt="" />

                                        </div>
                                        <div className="desc-act">
                                            <p>{t('home-fire.p3.description')}</p>
                                        </div>
                                    </div>
                                </div>
                            </Modal>

                            <Modal childrenModal={<div>
                                <img src="/assets/images/elements/fire.png" style={{ height: "3rem", width: "auto" }} alt="" />

                                <div>
                                    <h2 style={{ padding: "0.5rem", backgroundColor: "#00000005", borderRadius: "5px", color: "#00651E" }}>{t('home-fire.p4.title')}</h2>
                                </div>
                                <div>
                                    <p>{t('home-fire.p4.description')}</p>

                                    <div className="img-modal-act">
                                        <img src="/assets/images/actividades/fire-act/c-1.jpg" alt="" />
                                        <img src="/assets/images/actividades/fire-act/c-2.jpg" alt="" />
                                    </div>

                                    {/* <div>
                                            <ul>
                                                <li><b>Titulo:</b>Reto Ciudad Naturaleza</li>
                                                <li><b>Año:</b>2022 y abril  2023</li>
                                                <li><b>Lugar:</b>Bosquecillo de Auquisamaña - Zongo</li>
                                                <li><b>Beneficiarios:</b>La ciudad de La Paz, galardonada como la ciudad con gran fauna y flora.</li>
                                                <li><b>Descripción:</b>“Reto Ciudad Naturaleza” representa un esfuerzo colaborativo a nivel global para documentar las observaciones de plantas y animales en entornos urbanos y naturales. La ciudad de La Paz ha obtenido el reconocimiento mundial en dos ocasiones, demostrando su compromiso con la conservación y la biodiversidad urbana. Propacha fue parte de este concurso dos años consecutivos, aportando a este logro.</li>
                                            </ul>
                                        </div> */}
                                    <div style={{ margin: "1rem 0" }} className="linea-horizontal"></div>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <div>
                                            <h6>Integrantes del equipo:</h6>
                                            <ul>
                                                <li>Juan Orgaz Espinoza (Coordinador de Fuego)</li>
                                                <li> Dafne Rivera Flores (Coordinadora de Fuego)</li>
                                                <li>Azul Tarifa Tovar (Directora de Activismo)</li>
                                                <li>Kamila Ortiz Larrea (Directora de RRSS)</li>
                                                <li>Daniela Fernández Campos (Directora de Investigación)</li>
                                                <li>Isabel Aliaga Franco (Directora de Activismo)</li>
                                            </ul>
                                        </div>

                                        <img style={{ height: "15rem", objectFit: "cover", margin: "2rem", borderRadius: "5px" }} src="/assets/images/page-donar-1/fuego 1-min.jpg" alt="" />

                                    </div>
                                </div>
                            </div>}
                                title={t("home-fire.p4.title")}
                            >
                                <div id="act">
                                    <div className="img-desc">
                                        <div className="container-imgs">
                                            <img className=" is-col-span-2" src="/assets/images/actividades/image.png" alt="" />

                                        </div>
                                        <div className="desc-act">
                                            <p>{t('home-fire.p4.description')}</p>                                            </div>
                                    </div>
                                </div>
                            </Modal>

                            <Modal childrenModal={<div>
                                <img src="/assets/images/elements/fire.png" style={{ height: "3rem", width: "auto" }} alt="" />

                                <div>
                                    <h2 style={{ padding: "0.5rem", backgroundColor: "#00000005", borderRadius: "5px", color: "#00651E" }}>{t('home-fire.p5.title')}</h2>
                                </div>
                                <div>
                                    <p>{t('home-fire.p5.description')}</p>

                                    <div className="img-modal-act">
                                        <img src="/assets/images/actividades/fire-act/es-1.jpg" alt="" />
                                    </div>

                                    {/* <div>
                                            <ul>
                                                <li><b>Titulo:</b>Reto Ciudad Naturaleza</li>
                                                <li><b>Año:</b>2022 y abril  2023</li>
                                                <li><b>Lugar:</b>Bosquecillo de Auquisamaña - Zongo</li>
                                                <li><b>Beneficiarios:</b>La ciudad de La Paz, galardonada como la ciudad con gran fauna y flora.</li>
                                                <li><b>Descripción:</b>“Reto Ciudad Naturaleza” representa un esfuerzo colaborativo a nivel global para documentar las observaciones de plantas y animales en entornos urbanos y naturales. La ciudad de La Paz ha obtenido el reconocimiento mundial en dos ocasiones, demostrando su compromiso con la conservación y la biodiversidad urbana. Propacha fue parte de este concurso dos años consecutivos, aportando a este logro.</li>
                                            </ul>
                                        </div> */}
                                    <div style={{ margin: "1rem 0" }} className="linea-horizontal"></div>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <div>
                                            <h6>Integrantes del equipo:</h6>
                                            <ul>
                                                <li>Juan Orgaz Espinoza (Coordinador de Fuego)</li>
                                                <li> Dafne Rivera Flores (Coordinadora de Fuego)</li>
                                                <li>Azul Tarifa Tovar (Directora de Activismo)</li>
                                                <li>Kamila Ortiz Larrea (Directora de RRSS)</li>
                                                <li>Daniela Fernández Campos (Directora de Investigación)</li>
                                                <li>Isabel Aliaga Franco (Directora de Activismo)</li>
                                            </ul>
                                        </div>

                                        <img style={{ height: "15rem", objectFit: "cover", margin: "2rem", borderRadius: "5px" }} src="/assets/images/page-donar-1/fuego 1-min.jpg" alt="" />

                                    </div>
                                </div>
                            </div>}
                                title={t('home-fire.p5.title')}
                            >
                                <div id="act">
                                    <div className="img-desc">
                                        <div className="container-imgs">
                                            <img className=" is-col-span-2" src="/assets/images/actividades/escuela.jpg" alt="" />

                                        </div>
                                        <div className="desc-act">
                                            <p>{t('home-fire.p5.description')}</p>
                                        </div>
                                    </div>
                                </div>
                            </Modal>

                            <Modal childrenModal={<div>
                                <img src="/assets/images/elements/fire.png" style={{ height: "3rem", width: "auto" }} alt="" />

                                <div>
                                    <h2 style={{ padding: "0.5rem", backgroundColor: "#00000005", borderRadius: "5px", color: "#00651E" }}>{t('home-fire.p6.title')}</h2>
                                </div>
                                <div>
                                    <p>{t('home-fire.p6.description')}</p>

                                    <div className="img-modal-act">
                                        <img src="/assets/images/actividades/fire-act/cn-1.jpeg" alt="" />
                                        <img src="/assets/images/actividades/fire-act/cn-2.jpeg" alt="" />
                                        <img src="/assets/images/actividades/fire-act/cn-3.jpeg" alt="" />
                                    </div>

                                    {/* <div>
                                            <ul>
                                                <li><b>Titulo:</b>Reto Ciudad Naturaleza</li>
                                                <li><b>Año:</b>2022 y abril  2023</li>
                                                <li><b>Lugar:</b>Bosquecillo de Auquisamaña - Zongo</li>
                                                <li><b>Beneficiarios:</b>La ciudad de La Paz, galardonada como la ciudad con gran fauna y flora.</li>
                                                <li><b>Descripción:</b>“Reto Ciudad Naturaleza” representa un esfuerzo colaborativo a nivel global para documentar las observaciones de plantas y animales en entornos urbanos y naturales. La ciudad de La Paz ha obtenido el reconocimiento mundial en dos ocasiones, demostrando su compromiso con la conservación y la biodiversidad urbana. Propacha fue parte de este concurso dos años consecutivos, aportando a este logro.</li>
                                            </ul>
                                        </div> */}
                                    <div style={{ margin: "1rem 0" }} className="linea-horizontal"></div>
                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <div>
                                            <h6>Integrantes del equipo:</h6>
                                            <ul>
                                                <li>Juan Orgaz Espinoza (Coordinador de Fuego)</li>
                                                <li> Dafne Rivera Flores (Coordinadora de Fuego)</li>
                                                <li>Azul Tarifa Tovar (Directora de Activismo)</li>
                                                <li>Kamila Ortiz Larrea (Directora de RRSS)</li>
                                                <li>Daniela Fernández Campos (Directora de Investigación)</li>
                                                <li>Isabel Aliaga Franco (Directora de Activismo)</li>
                                            </ul>
                                        </div>

                                        <img style={{ height: "15rem", objectFit: "cover", margin: "2rem", borderRadius: "5px" }} src="/assets/images/page-donar-1/fuego 1-min.jpg" alt="" />

                                    </div>
                                </div>
                            </div>}
                                title={t("home-fire.p6.title")}
                            >
                                <div id="act">
                                    <div className="img-desc">
                                        <div className="container-imgs">
                                            <img className=" is-col-span-2" src="/assets/images/actividades/congreso.jpeg" alt="" />

                                        </div>
                                        <div className="desc-act">
                                            <p>{t('home-fire.p6.description')}</p>                                            </div>
                                    </div>
                                </div>
                            </Modal>
                        </li>



                        <li className={tabActive == 1 ? "is-active" : "disabled"}>
                            <small className="water-txt">{t('waterTeam')}</small>
                            <div className="button-and-element">
                                <span style={{ color: "#0084CC" }}>{t('water').toUpperCase()} </span>
                                {/*<Link to="/proyectos" className="navbar-item button is-green my-4 btn-donar">{t('Actividades')}</Link>*/}
                            </div>
                            <p>{t('waterDesc')}</p>
                            <div className="linea-horizontal"></div>

                            <Modal childrenModal={<div>
                                <img src="/assets/images/elements/water.png" style={{ height: "3rem", width: "auto" }} alt="" />

                                <div>
                                    <h2 style={{ padding: "0.5rem", backgroundColor: "#00000005", borderRadius: "5px", color: "#00651E" }}>{t('home-water.p1.title')}</h2>
                                </div>
                                <div>
                                    <p>{t('home-water.p1.description')}</p>

                                    <div className="img-modal-act">
                                        <img src="/assets/images/actividades/water-act/m-1.jpg" alt="" />
                                        <img src="/assets/images/actividades/water-act/m-2.jpg" alt="" />
                                        <img src="/assets/images/actividades/water-act/m-3.jpg" alt="" />
                                    </div>

                                    {/* <div>
                                            <ul>
                                                <li><b>Titulo:</b>Reto Ciudad Naturaleza</li>
                                                <li><b>Año:</b>2022 y abril  2023</li>
                                                <li><b>Lugar:</b>Bosquecillo de Auquisamaña - Zongo</li>
                                                <li><b>Beneficiarios:</b>La ciudad de La Paz, galardonada como la ciudad con gran fauna y flora.</li>
                                                <li><b>Descripción:</b>“Reto Ciudad Naturaleza” representa un esfuerzo colaborativo a nivel global para documentar las observaciones de plantas y animales en entornos urbanos y naturales. La ciudad de La Paz ha obtenido el reconocimiento mundial en dos ocasiones, demostrando su compromiso con la conservación y la biodiversidad urbana. Propacha fue parte de este concurso dos años consecutivos, aportando a este logro.</li>
                                            </ul>
                                        </div> */}
                                   
                                </div>
                            </div>}

                                title={t('home-water.p1.title')}
                            >
                                <div id="act">
                                    <div className="img-desc">
                                        <div className="container-imgs">
                                            <img className=" is-col-span-2" src="/assets/images/actividades/macetas-water.jpg" alt="" />

                                            {/* 
                                                ///////pie de imagen
                                                <img className="" src="/assets/images/actividades/2. ESCOMA-min.jpg" alt="" />
                                                <img className="" src="/assets/images/actividades/2. ESCOMA-min.jpg" alt="" />
                                                */}

                                        </div>
                                        <div className="desc-act">
                                            <p>{t('home-water.p1.description')}</p>
                                        </div>
                                    </div>
                                </div>
                            </Modal>

                            <Modal childrenModal={<div>
                                <img src="/assets/images/elements/water.png" style={{ height: "3rem", width: "auto" }} alt="" />

                                <div>
                                    <h2 style={{ padding: "0.5rem", backgroundColor: "#00000005", borderRadius: "5px", color: "#00651E" }}>{t('home-water.p2.title')}</h2>
                                </div>
                                <div>
                                    <p>{t('home-water.p2.description')}</p>

                                    <div className="img-modal-act">
                                        <img src="/assets/images/actividades/water-act/cda-1.jpg" alt="" />
                                        <img src="/assets/images/actividades/water-act/cda-2.jpg" alt="" />
                                        <img src="/assets/images/actividades/water-act/cda-3.jpg" alt="" />
                                    </div>

                                    {/* <div>
                                            <ul>
                                                <li><b>Titulo:</b>Reto Ciudad Naturaleza</li>
                                                <li><b>Año:</b>2022 y abril  2023</li>
                                                <li><b>Lugar:</b>Bosquecillo de Auquisamaña - Zongo</li>
                                                <li><b>Beneficiarios:</b>La ciudad de La Paz, galardonada como la ciudad con gran fauna y flora.</li>
                                                <li><b>Descripción:</b>“Reto Ciudad Naturaleza” representa un esfuerzo colaborativo a nivel global para documentar las observaciones de plantas y animales en entornos urbanos y naturales. La ciudad de La Paz ha obtenido el reconocimiento mundial en dos ocasiones, demostrando su compromiso con la conservación y la biodiversidad urbana. Propacha fue parte de este concurso dos años consecutivos, aportando a este logro.</li>
                                            </ul>
                                        </div> */}
                                   
                                </div>
                            </div>}
                                title={t("home-water.p2.title")}
                            >
                                <div id="act">
                                    <div className="img-desc">
                                        <div className="container-imgs">
                                            <img className=" is-col-span-2" src="/assets/images/actividades/centinelas.jpg" alt="" />

                                        </div>
                                        <div className="desc-act">
                                            <p>{t('home-water.p2.description')}</p>                                            </div>
                                    </div>
                                </div>
                            </Modal>


                            <Modal childrenModal={<div>
                                <img src="/assets/images/elements/water.png" style={{ height: "3rem", width: "auto" }} alt="" />

                                <div>
                                    <h2 style={{ padding: "0.5rem", backgroundColor: "#00000005", borderRadius: "5px", color: "#00651E" }}>{t('home-water.p3.title')}</h2>
                                </div>
                                <div>
                                    <p>{t('home-water.p3.description')}</p>

                                    <div className="img-modal-act">
                                        <img src="/assets/images/actividades/water-act/h-1.jpg" alt="" />
                                        <img src="/assets/images/actividades/water-act/h-2.jpg" alt="" />
                                        <img src="/assets/images/actividades/water-act/h-3.jpg" alt="" />
                                    </div>

                                    {/* <div>
                                            <ul>
                                                <li><b>Titulo:</b>Reto Ciudad Naturaleza</li>
                                                <li><b>Año:</b>2022 y abril  2023</li>
                                                <li><b>Lugar:</b>Bosquecillo de Auquisamaña - Zongo</li>
                                                <li><b>Beneficiarios:</b>La ciudad de La Paz, galardonada como la ciudad con gran fauna y flora.</li>
                                                <li><b>Descripción:</b>“Reto Ciudad Naturaleza” representa un esfuerzo colaborativo a nivel global para documentar las observaciones de plantas y animales en entornos urbanos y naturales. La ciudad de La Paz ha obtenido el reconocimiento mundial en dos ocasiones, demostrando su compromiso con la conservación y la biodiversidad urbana. Propacha fue parte de este concurso dos años consecutivos, aportando a este logro.</li>
                                            </ul>
                                        </div> */}
                                   
                                </div>
                            </div>}
                                title={t('home-water.p3.title')}
                            >
                                <div id="act">
                                    <div className="img-desc">
                                        <div className="container-imgs">
                                            <img className=" is-col-span-2" src="/assets/images/actividades/hampaturi1.jpg" alt="" />

                                        </div>
                                        <div className="desc-act">
                                            <p>{t('home-water.p3.description')}</p>
                                        </div>
                                    </div>
                                </div>
                            </Modal>


                            <Modal childrenModal={<div>
                                <img src="/assets/images/elements/water.png" style={{ height: "3rem", width: "auto" }} alt="" />

                                <div>
                                    <h2 style={{ padding: "0.5rem", backgroundColor: "#00000005", borderRadius: "5px", color: "#00651E" }}>{t('home-water.p4.title')}</h2>
                                </div>
                                <div>
                                    <p>{t('home-water.p4.description')}</p>

                                    <div className="img-modal-act">
                                        <img src="/assets/images/actividades/water-act/vh-1.jpg" alt="" />
                                        <img src="/assets/images/actividades/water-act/vh-2.jpg" alt="" />
                                        <img src="/assets/images/actividades/water-act/vh-3.jpg" alt="" />
                                    </div>

                                    {/* <div>
                                            <ul>
                                                <li><b>Titulo:</b>Reto Ciudad Naturaleza</li>
                                                <li><b>Año:</b>2022 y abril  2023</li>
                                                <li><b>Lugar:</b>Bosquecillo de Auquisamaña - Zongo</li>
                                                <li><b>Beneficiarios:</b>La ciudad de La Paz, galardonada como la ciudad con gran fauna y flora.</li>
                                                <li><b>Descripción:</b>“Reto Ciudad Naturaleza” representa un esfuerzo colaborativo a nivel global para documentar las observaciones de plantas y animales en entornos urbanos y naturales. La ciudad de La Paz ha obtenido el reconocimiento mundial en dos ocasiones, demostrando su compromiso con la conservación y la biodiversidad urbana. Propacha fue parte de este concurso dos años consecutivos, aportando a este logro.</li>
                                            </ul>
                                        </div> */}
                                   
                                </div>
                            </div>}
                                title={t("home-water.p4.title")}
                            >
                                <div id="act">
                                    <div className="img-desc">
                                        <div className="container-imgs">
                                            <img className=" is-col-span-2" src="/assets/images/actividades/huerto.jpg" alt="" />

                                        </div>
                                        <div className="desc-act">
                                            <p>{t('home-water.p4.description')}</p>                                            </div>
                                    </div>
                                </div>
                            </Modal>


                            <Modal childrenModal={<div>
                                <img src="/assets/images/elements/water.png" style={{ height: "3rem", width: "auto" }} alt="" />

                                <div>
                                    <h2 style={{ padding: "0.5rem", backgroundColor: "#00000005", borderRadius: "5px", color: "#00651E" }}>{t('home-water.p5.title')}</h2>
                                </div>
                                <div>
                                    <p>{t('home-water.p5.description')}</p>

                                    <div className="img-modal-act">
                                        <img src="/assets/images/actividades/water-act/t-1.jpg" alt="" />
                                        <img src="/assets/images/actividades/water-act/t-2.jpg" alt="" />
                                        <img src="/assets/images/actividades/water-act/t-3.jpg" alt="" />
                                    </div>

                                    {/* <div>
                                            <ul>
                                                <li><b>Titulo:</b>Reto Ciudad Naturaleza</li>
                                                <li><b>Año:</b>2022 y abril  2023</li>
                                                <li><b>Lugar:</b>Bosquecillo de Auquisamaña - Zongo</li>
                                                <li><b>Beneficiarios:</b>La ciudad de La Paz, galardonada como la ciudad con gran fauna y flora.</li>
                                                <li><b>Descripción:</b>“Reto Ciudad Naturaleza” representa un esfuerzo colaborativo a nivel global para documentar las observaciones de plantas y animales en entornos urbanos y naturales. La ciudad de La Paz ha obtenido el reconocimiento mundial en dos ocasiones, demostrando su compromiso con la conservación y la biodiversidad urbana. Propacha fue parte de este concurso dos años consecutivos, aportando a este logro.</li>
                                            </ul>
                                        </div> */}
                                   
                                </div>
                            </div>}
                                title={t('home-water.p5.title')}
                            >
                                <div id="act">
                                    <div className="img-desc">
                                        <div className="container-imgs">
                                            <img className=" is-col-span-2" src="/assets/images/actividades/taller-capacidades-diferentes.jpg" alt="" />

                                        </div>
                                        <div className="desc-act">
                                            <p>{t('home-water.p5.description')}</p>
                                        </div>
                                    </div>
                                </div>
                            </Modal>


                            <Modal childrenModal={<div>
                                <img src="/assets/images/elements/water.png" style={{ height: "3rem", width: "auto" }} alt="" />

                                <div>
                                    <h2 style={{ padding: "0.5rem", backgroundColor: "#00000005", borderRadius: "5px", color: "#00651E" }}>{t('home-water.p6.title')}</h2>
                                </div>
                                <div>
                                    <p>{t('home-water.p6.description')}</p>

                                    <div className="img-modal-act">
                                        <img src="/assets/images/actividades/water-act/tt-1.jpg" alt="" />
                                    </div>

                                    {/* <div>
                                            <ul>
                                                <li><b>Titulo:</b>Reto Ciudad Naturaleza</li>
                                                <li><b>Año:</b>2022 y abril  2023</li>
                                                <li><b>Lugar:</b>Bosquecillo de Auquisamaña - Zongo</li>
                                                <li><b>Beneficiarios:</b>La ciudad de La Paz, galardonada como la ciudad con gran fauna y flora.</li>
                                                <li><b>Descripción:</b>“Reto Ciudad Naturaleza” representa un esfuerzo colaborativo a nivel global para documentar las observaciones de plantas y animales en entornos urbanos y naturales. La ciudad de La Paz ha obtenido el reconocimiento mundial en dos ocasiones, demostrando su compromiso con la conservación y la biodiversidad urbana. Propacha fue parte de este concurso dos años consecutivos, aportando a este logro.</li>
                                            </ul>
                                        </div> */}
                                   
                                </div>
                            </div>}
                                title={t("home-water.p6.title")}
                            >
                                <div id="act">
                                    <div className="img-desc">
                                        <div className="container-imgs">
                                            <img className=" is-col-span-2" src="/assets/images/actividades/taller.jpg" alt="" />

                                        </div>
                                        <div className="desc-act">
                                            <p>{t('home-water.p6.description')}</p>                                            </div>
                                    </div>
                                </div>
                            </Modal>
                        </li>


                        <li className={tabActive == 2 ? "is-active" : "disabled"}>
                            <small className="soil-txt">{t('soilTeam')}</small>
                            <div className="button-and-element">
                                <span style={{ color: "#92BA01" }}>{t('soil').toUpperCase()} </span>
                            </div>
                            <p>{t('soilDesc')}</p>
                            <div className="linea-horizontal"></div>
                            <Modal childrenModal={<div>
                                <img src="/assets/images/elements/soil.png" style={{ height: "3rem", width: "auto" }} alt="" />

                                <div>
                                    <h2 style={{ padding: "0.5rem", backgroundColor: "#00000005", borderRadius: "5px", color: "#00651E" }}>{t('home-soil.p1.title')}</h2>
                                </div>
                                <div>
                                    <p>{t('home-soil.p1.description')}</p>

                                    <div className="img-modal-act">
                                        <img src="/assets/images/actividades/soil-act/rc-1.jpeg" alt="" />
                                        <img src="/assets/images/actividades/soil-act/rc-2.jpg" alt="" />
                                        <img src="/assets/images/actividades/soil-act/rc-3.jpeg" alt="" />
                                    </div>

                                     <div>
                                            <ul>
                                                <li><b>Titulo:</b>Reto Ciudad Naturaleza</li>
                                                <li><b>Año:</b>2022 y abril  2023</li>
                                                <li><b>Lugar:</b>Bosquecillo de Auquisamaña - Zongo</li>
                                                <li><b>Beneficiarios:</b>La ciudad de La Paz, galardonada como la ciudad con gran fauna y flora.</li>
                                            </ul>
                                        </div> 
                                   
                                </div>
                            </div>}
                                title={t('home-soil.p1.title')}
                            >
                                <div id="act">
                                    <div className="img-desc">
                                        <div className="container-imgs">
                                            <img className=" is-col-span-2" src="/assets/images/actividades/reto-naturaleza.jpeg" alt="" />

                                            {/* 
                                                ///////pie de imagen
                                                <img className="" src="/assets/images/actividades/2. ESCOMA-min.jpg" alt="" />
                                                <img className="" src="/assets/images/actividades/2. ESCOMA-min.jpg" alt="" />
                                                */}

                                        </div>
                                        <div className="desc-act">
                                            <p>{t('home-soil.p1.description')}</p>
                                        </div>
                                    </div>
                                </div>
                            </Modal>

                            <Modal childrenModal={<div>
                                <img src="/assets/images/elements/soil.png" style={{ height: "3rem", width: "auto" }} alt="" />

                                <div>
                                    <h2 style={{ padding: "0.5rem", backgroundColor: "#00000005", borderRadius: "5px", color: "#00651E" }}>{t('home-soil.p2.title')}</h2>
                                </div>
                                <div>
                                    <p>{t('home-soil.p2.description')}</p>

                                    <div className="img-modal-act">
                                        <img src="/assets/images/actividades/soil-act/pg-1.jpg" alt="" />
                                        <img src="/assets/images/actividades/soil-act/pg-2.png" alt="" />
                                        <img src="/assets/images/actividades/soil-act/pg-3.png" alt="" />
                                    </div>

                                     <div>
                                            <ul>
                                                <li><b>Titulo:</b>1ra Gala de Premios Propacha</li>
                                                <li><b>Año:</b> 10 de noviembre de 2023</li>
                                                <li><b>Lugar:</b>MET Hotel, La Paz, Bolivia</li>
                                                <li><b>Beneficiarios:</b>Proyectos Propacha</li>
                                            </ul>
                                        </div> 
                                   
                                </div>
                            </div>}
                                title={t("home-soil.p2.title")}
                            >
                                <div id="act">
                                    <div className="img-desc">
                                        <div className="container-imgs">
                                            <img className=" is-col-span-2" src="/assets/images/actividades/gala1.png" alt="" />

                                        </div>
                                        <div className="desc-act">
                                            <p>{t('home-soil.p2.description')}</p>                                            </div>
                                    </div>
                                </div>
                            </Modal>

                            <Modal childrenModal={<div>
                                <img src="/assets/images/elements/soil.png" style={{ height: "3rem", width: "auto" }} alt="" />

                                <div>
                                    <h2 style={{ padding: "0.5rem", backgroundColor: "#00000005", borderRadius: "5px", color: "#00651E" }}>{t('home-soil.p3.title')}</h2>
                                </div>
                                <div>
                                    <p>{t('home-soil.p3.description')}</p>

                                    <div className="img-modal-act">
                                        <img src="/assets/images/actividades/soil-act/jr-1.jpg" alt="" />
                                        <img src="/assets/images/actividades/soil-act/jr-2.jpg" alt="" />
                                        <img src="/assets/images/actividades/soil-act/jr-3.jpg" alt="" />
                                    </div>

                                     <div>
                                            <ul>
                                                <li><b>Titulo:</b>Jornadas de reforestación con especies nativas</li>
                                                <li><b>Año:</b>2023 - 2024</li>
                                                <li><b>Lugar:</b>La Paz, Bolivia</li>
                                                <li><b>Beneficiarios:</b>Población paceña </li>
                                            </ul>
                                        </div> 
                                   
                                </div>
                            </div>}
                                title={t('home-soil.p3.title')}
                            >
                                <div id="act">
                                    <div className="img-desc">
                                        <div className="container-imgs">
                                            <img className=" is-col-span-2" src="/assets/images/actividades/reforestacion.jpg" alt="" />

                                        </div>
                                        <div className="desc-act">
                                            <p>{t('home-soil.p3.description')}</p>
                                        </div>
                                    </div>
                                </div>
                            </Modal>

                            <Modal childrenModal={<div>
                                <img src="/assets/images/elements/soil.png" style={{ height: "3rem", width: "auto" }} alt="" />

                                <div>
                                    <h2 style={{ padding: "0.5rem", backgroundColor: "#00000005", borderRadius: "5px", color: "#00651E" }}>{t('home-soil.p4.title')}</h2>
                                </div>
                                <div>
                                    <p>{t('home-soil.p4.description')}</p>

                                    <div className="img-modal-act">
                                        <img src="/assets/images/actividades/soil-act/cb-1.png" alt="" />
                                        <img src="/assets/images/actividades/soil-act/cb-2.png" alt="" />
                                        <img src="/assets/images/actividades/soil-act/cb-3.jpg" alt="" />
                                    </div>

                                     <div>
                                            <ul>
                                                <li><b>Titulo:</b>Campaña de apoyo a bomberos forestales voluntarios</li>
                                                <li><b>Año:</b>2023 </li>
                                                <li><b>Lugar:</b> Bolivia</li>
                                                <li><b>Beneficiarios:</b> Bomberos Voluntarios GEOS La Paz 2da Compañía Jucumari, Bomberos Forestales Voluntarios Jaguares y población boliviana en general. </li>
                                            </ul>
                                        </div> 
                                   
                                </div>
                            </div>}
                                title={t("home-soil.p4.title")}
                            >
                                <div id="act">
                                    <div className="img-desc">
                                        <div className="container-imgs">
                                            <img className=" is-col-span-2" src="/assets/images/actividades/bomberos1.png" alt="" />

                                        </div>
                                        <div className="desc-act">
                                            <p>{t('home-soil.p4.description')}</p>                                            </div>
                                    </div>
                                </div>
                            </Modal>

                            <Modal childrenModal={<div>
                                <img src="/assets/images/elements/soil.png" style={{ height: "3rem", width: "auto" }} alt="" />

                                <div>
                                    <h2 style={{ padding: "0.5rem", backgroundColor: "#00000005", borderRadius: "5px", color: "#00651E" }}>{t('home-soil.p5.title')}</h2>
                                </div>
                                <div>
                                    <p>{t('home-soil.p5.description')}</p>

                                    <div className="img-modal-act">
                                        <img src="/assets/images/actividades/soil-act/sv-1.jpg" alt="" />
                                        <img src="/assets/images/actividades/soil-act/sv-2.jpg" alt="" />
                                        <img src="/assets/images/actividades/soil-act/sv-3.jpg" alt="" />
                                    </div>

                                     <div>
                                            <ul>
                                                <li><b>Titulo:</b>Sembrando vida cosechando futuro 2 en conjunto con aire</li>
                                                <li><b>Año:</b>Noviembre 2021 </li>
                                                <li><b>Lugar:</b> Bioparque Municipal Vesty Pakos</li>
                                                <li><b>Beneficiarios:</b> Fauna nativa, población paceña </li>
                                            </ul>
                                        </div> 
                                   
                                </div>
                            </div>}
                                title={t('home-soil.p5.title')}
                            >
                                <div id="act">
                                    <div className="img-desc">
                                        <div className="container-imgs">
                                            <img className=" is-col-span-2" src="/assets/images/actividades/sembrando.jpg" alt="" />

                                        </div>
                                        <div className="desc-act">
                                            <p>{t('home-soil.p5.description')}</p>
                                        </div>
                                    </div>
                                </div>
                            </Modal>

                            <Modal childrenModal={<div>
                                <img src="/assets/images/elements/soil.png" style={{ height: "3rem", width: "auto" }} alt="" />

                                <div>
                                    <h2 style={{ padding: "0.5rem", backgroundColor: "#00000005", borderRadius: "5px", color: "#00651E" }}>{t('home-soil.p6.title')}</h2>
                                </div>
                                <div>
                                    <p>{t('home-soil.p6.description')}</p>

                                    <div className="img-modal-act">
                                        <img src="/assets/images/actividades/soil-act/em-1.jpg" alt="" />
                                        <img src="/assets/images/actividades/soil-act/em-2.jpg" alt="" />
                                    </div>

                                     <div>
                                            <ul>
                                                <li><b>Titulo:</b>Expomascota</li>
                                                <li><b>Año:</b>Agosto 2022 </li>
                                                <li><b>Lugar:</b> Parque La Florida</li>
                                                <li><b>Beneficiarios:</b> Fauna nativa, población paceña </li>
                                            </ul>
                                        </div> 
                                   
                                </div>
                            </div>}
                                title={t("home-soil.p6.title")}
                            >
                                <div id="act">
                                    <div className="img-desc">
                                        <div className="container-imgs">
                                            <img className=" is-col-span-2" src="/assets/images/actividades/expomascota2.jpg" alt="" />

                                        </div>
                                        <div className="desc-act">
                                            <p>{t('home-soil.p6.description')}</p>                                            </div>
                                    </div>
                                </div>
                            </Modal>
                        </li>


                        <li className={tabActive == 3 ? "is-active" : "disabled"}>
                            <small className="wind-txt">{t('windTeam')}</small>
                            <div className="button-and-element">
                                <span style={{ color: "#8DD9F1" }}>{t('wind').toUpperCase()} </span>
                            </div>
                            <p>{t('windDesc')}</p>
                            <div className="linea-horizontal"></div>

                            <Modal childrenModal={<div>
                                <img src="/assets/images/elements/wind.png" style={{ height: "3rem", width: "auto" }} alt="" />

                                <div>
                                    <h2 style={{ padding: "0.5rem", backgroundColor: "#00000005", borderRadius: "5px", color: "#00651E" }}>{t('home-wind.p1.title')}</h2>
                                </div>
                                <div>
                                    <p>{t('home-wind.p1.description')}</p>

                                    <div className="img-modal-act">
                                        <img src="/assets/images/actividades/wind-act/gg-1.jpg" alt="" />
                                        <img src="/assets/images/actividades/wind-act/gg-2.jpg" alt="" />
                                        <img src="/assets/images/actividades/wind-act/gg-3.jpg" alt="" />
                                    </div>

                                    {/* <div>
                                            <ul>
                                                <li><b>Titulo:</b>Reto Ciudad Naturaleza</li>
                                                <li><b>Año:</b>2022 y abril  2023</li>
                                                <li><b>Lugar:</b>Bosquecillo de Auquisamaña - Zongo</li>
                                                <li><b>Beneficiarios:</b>La ciudad de La Paz, galardonada como la ciudad con gran fauna y flora.</li>
                                                <li><b>Descripción:</b>“Reto Ciudad Naturaleza” representa un esfuerzo colaborativo a nivel global para documentar las observaciones de plantas y animales en entornos urbanos y naturales. La ciudad de La Paz ha obtenido el reconocimiento mundial en dos ocasiones, demostrando su compromiso con la conservación y la biodiversidad urbana. Propacha fue parte de este concurso dos años consecutivos, aportando a este logro.</li>
                                            </ul>
                                        </div> */}
                                   
                                </div>
                            </div>}
                                title={t('home-wind.p1.title')}
                            >
                                <div id="act">
                                    <div className="img-desc">
                                        <div className="container-imgs">
                                            <img className=" is-col-span-2" src="/assets/images/actividades/greengame.jpg" alt="" />

                                            {/* 
                                                ///////pie de imagen
                                                <img className="" src="/assets/images/actividades/2. ESCOMA-min.jpg" alt="" />
                                                <img className="" src="/assets/images/actividades/2. ESCOMA-min.jpg" alt="" />
                                                */}

                                        </div>
                                        <div className="desc-act">
                                            <p>{t('home-wind.p1.description')}</p>
                                        </div>
                                    </div>
                                </div>
                            </Modal>

                            <Modal childrenModal={<div>
                                <img src="/assets/images/elements/wind.png" style={{ height: "3rem", width: "auto" }} alt="" />

                                <div>
                                    <h2 style={{ padding: "0.5rem", backgroundColor: "#00000005", borderRadius: "5px", color: "#00651E" }}>{t('home-wind.p2.title')}</h2>
                                </div>
                                <div>
                                    <p>{t('home-wind.p2.description')}</p>

                                    <div className="img-modal-act">
                                        <img src="/assets/images/actividades/wind-act/cin-1.jpg" alt="" />
                                        <img src="/assets/images/actividades/wind-act/cin-2.jpg" alt="" />
                                    </div>

                                    {/* <div>
                                            <ul>
                                                <li><b>Titulo:</b>Reto Ciudad Naturaleza</li>
                                                <li><b>Año:</b>2022 y abril  2023</li>
                                                <li><b>Lugar:</b>Bosquecillo de Auquisamaña - Zongo</li>
                                                <li><b>Beneficiarios:</b>La ciudad de La Paz, galardonada como la ciudad con gran fauna y flora.</li>
                                                <li><b>Descripción:</b>“Reto Ciudad Naturaleza” representa un esfuerzo colaborativo a nivel global para documentar las observaciones de plantas y animales en entornos urbanos y naturales. La ciudad de La Paz ha obtenido el reconocimiento mundial en dos ocasiones, demostrando su compromiso con la conservación y la biodiversidad urbana. Propacha fue parte de este concurso dos años consecutivos, aportando a este logro.</li>
                                            </ul>
                                        </div> */}
                                   
                                </div>
                            </div>}
                                title={t("home-wind.p2.title")}
                            >
                                <div id="act">
                                    <div className="img-desc">
                                        <div className="container-imgs">
                                            <img className=" is-col-span-2" src="/assets/images/actividades/reto-aire.jpg" alt="" />

                                        </div>
                                        <div className="desc-act">
                                            <p>{t('home-wind.p2.description')}</p>                                            </div>
                                    </div>
                                </div>
                            </Modal>
                            <Modal childrenModal={<div>
                                <img src="/assets/images/elements/wind.png" style={{ height: "3rem", width: "auto" }} alt="" />

                                <div>
                                    <h2 style={{ padding: "0.5rem", backgroundColor: "#00000005", borderRadius: "5px", color: "#00651E" }}>{t('home-wind.p3.title')}</h2>
                                </div>
                                <div>
                                    <p>{t('home-wind.p3.description')}</p>

                                    <div className="img-modal-act">
                                        <img src="/assets/images/actividades/wind-act/md-2.jpg" alt="" />
                                        <img src="/assets/images/actividades/wind-act/md-3.jpg" alt="" />
                                        <img src="/assets/images/actividades/wind-act/md-4.jpg" alt="" />
                                    </div>

                                    {/* <div>
                                            <ul>
                                                <li><b>Titulo:</b>Reto Ciudad Naturaleza</li>
                                                <li><b>Año:</b>2022 y abril  2023</li>
                                                <li><b>Lugar:</b>Bosquecillo de Auquisamaña - Zongo</li>
                                                <li><b>Beneficiarios:</b>La ciudad de La Paz, galardonada como la ciudad con gran fauna y flora.</li>
                                                <li><b>Descripción:</b>“Reto Ciudad Naturaleza” representa un esfuerzo colaborativo a nivel global para documentar las observaciones de plantas y animales en entornos urbanos y naturales. La ciudad de La Paz ha obtenido el reconocimiento mundial en dos ocasiones, demostrando su compromiso con la conservación y la biodiversidad urbana. Propacha fue parte de este concurso dos años consecutivos, aportando a este logro.</li>
                                            </ul>
                                        </div> */}
                                   
                                </div>
                            </div>}
                                title={t('home-wind.p3.title')}
                            >
                                <div id="act">
                                    <div className="img-desc">
                                        <div className="container-imgs">
                                            <img className=" is-col-span-2" src="/assets/images/actividades/muela-diablo-1.jpg" alt="" />

                                        </div>
                                        <div className="desc-act">
                                            <p>{t('home-wind.p3.description')}</p>
                                        </div>
                                    </div>
                                </div>
                            </Modal>

                            <Modal childrenModal={<div>
                                <img src="/assets/images/elements/wind.png" style={{ height: "3rem", width: "auto" }} alt="" />

                                <div>
                                    <h2 style={{ padding: "0.5rem", backgroundColor: "#00000005", borderRadius: "5px", color: "#00651E" }}>{t('home-wind.p4.title')}</h2>
                                </div>
                                <div>
                                    <p>{t('home-wind.p4.description')}</p>

                                    <div className="img-modal-act">
                                        <img src="/assets/images/actividades/wind-act/l-1.jpg" alt="" />
                                        <img src="/assets/images/actividades/wind-act/l-2.jpg" alt="" />
                                        <img src="/assets/images/actividades/wind-act/l-3.jpg" alt="" />
                                    </div>

                                    {/* <div>
                                            <ul>
                                                <li><b>Titulo:</b>Reto Ciudad Naturaleza</li>
                                                <li><b>Año:</b>2022 y abril  2023</li>
                                                <li><b>Lugar:</b>Bosquecillo de Auquisamaña - Zongo</li>
                                                <li><b>Beneficiarios:</b>La ciudad de La Paz, galardonada como la ciudad con gran fauna y flora.</li>
                                                <li><b>Descripción:</b>“Reto Ciudad Naturaleza” representa un esfuerzo colaborativo a nivel global para documentar las observaciones de plantas y animales en entornos urbanos y naturales. La ciudad de La Paz ha obtenido el reconocimiento mundial en dos ocasiones, demostrando su compromiso con la conservación y la biodiversidad urbana. Propacha fue parte de este concurso dos años consecutivos, aportando a este logro.</li>
                                            </ul>
                                        </div> */}
                                   
                                </div>
                            </div>}
                                title={t("home-wind.p4.title")}
                            >
                                <div id="act">
                                    <div className="img-desc">
                                        <div className="container-imgs">
                                            <img className=" is-col-span-2" src="/assets/images/actividades/laguna1.jpg" alt="" />

                                        </div>
                                        <div className="desc-act">
                                            <p>{t('home-wind.p4.description')}</p>                                            </div>
                                    </div>
                                </div>
                            </Modal>

                            <Modal childrenModal={<div>
                                <img src="/assets/images/elements/wind.png" style={{ height: "3rem", width: "auto" }} alt="" />

                                <div>
                                    <h2 style={{ padding: "0.5rem", backgroundColor: "#00000005", borderRadius: "5px", color: "#00651E" }}>{t('home-wind.p5.title')}</h2>
                                </div>
                                <div>
                                    <p>{t('home-wind.p5.description')}</p>

                                    <div className="img-modal-act">
                                        <img src="/assets/images/actividades/wind-act/fr-1.jpg" alt="" />
                                        <img src="/assets/images/actividades/wind-act/fr-2.jpg" alt="" />
                                        <img src="/assets/images/actividades/wind-act/fr-3.jpg" alt="" />
                                    </div>

                                    {/* <div>
                                            <ul>
                                                <li><b>Titulo:</b>Reto Ciudad Naturaleza</li>
                                                <li><b>Año:</b>2022 y abril  2023</li>
                                                <li><b>Lugar:</b>Bosquecillo de Auquisamaña - Zongo</li>
                                                <li><b>Beneficiarios:</b>La ciudad de La Paz, galardonada como la ciudad con gran fauna y flora.</li>
                                                <li><b>Descripción:</b>“Reto Ciudad Naturaleza” representa un esfuerzo colaborativo a nivel global para documentar las observaciones de plantas y animales en entornos urbanos y naturales. La ciudad de La Paz ha obtenido el reconocimiento mundial en dos ocasiones, demostrando su compromiso con la conservación y la biodiversidad urbana. Propacha fue parte de este concurso dos años consecutivos, aportando a este logro.</li>
                                            </ul>
                                        </div> */}
                                   
                                </div>
                            </div>}
                                title={t('home-wind.p5.title')}
                            >
                                <div id="act">
                                    <div className="img-desc">
                                        <div className="container-imgs">
                                            <img className=" is-col-span-2" src="/assets/images/actividades/feria.jpg" alt="" />

                                        </div>
                                        <div className="desc-act">
                                            <p>{t('home-wind.p5.description')}</p>
                                        </div>
                                    </div>
                                </div>
                            </Modal>

                            <Modal childrenModal={<div>
                                <img src="/assets/images/elements/wind.png" style={{ height: "3rem", width: "auto" }} alt="" />

                                <div>
                                    <h2 style={{ padding: "0.5rem", backgroundColor: "#00000005", borderRadius: "5px", color: "#00651E" }}>{t('home-wind.p6.title')}</h2>
                                </div>
                                <div>
                                    <p>{t('home-wind.p6.description')}</p>

                                    <div className="img-modal-act">
                                        <img src="/assets/images/actividades/wind-act/v-1.jpg" alt="" />
                                        <img src="/assets/images/actividades/wind-act/v-2.jpg" alt="" />
                                    </div>

                                    {/* <div>
                                            <ul>
                                                <li><b>Titulo:</b>Reto Ciudad Naturaleza</li>
                                                <li><b>Año:</b>2022 y abril  2023</li>
                                                <li><b>Lugar:</b>Bosquecillo de Auquisamaña - Zongo</li>
                                                <li><b>Beneficiarios:</b>La ciudad de La Paz, galardonada como la ciudad con gran fauna y flora.</li>
                                                <li><b>Descripción:</b>“Reto Ciudad Naturaleza” representa un esfuerzo colaborativo a nivel global para documentar las observaciones de plantas y animales en entornos urbanos y naturales. La ciudad de La Paz ha obtenido el reconocimiento mundial en dos ocasiones, demostrando su compromiso con la conservación y la biodiversidad urbana. Propacha fue parte de este concurso dos años consecutivos, aportando a este logro.</li>
                                            </ul>
                                        </div> */}
                                   
                                </div>
                            </div>}
                                title={t("home-wind.p6.title")}
                            >
                                <div id="act">
                                    <div className="img-desc">
                                        <div className="container-imgs">
                                            <img className=" is-col-span-2" src="/assets/images/actividades/vesty-1.jpg" alt="" />

                                        </div>
                                        <div className="desc-act">
                                            <p>{t('home-wind.p6.description')}</p>                                            </div>
                                    </div>
                                </div>
                            </Modal>
                        </li>


                        <li className={tabActive == 4 ? "is-active" : "disabled"}>
                            <small className="tv-txt">{t('tvNetworkTeam')}</small>
                            <div className="button-and-element">
                                <span style={{ color: "#FAA512" }}>{t('tvNetwork').toUpperCase()} </span>
                            </div>
                            <p style={{ margin: "0 0 1rem 0" }}>{t('Generamos contenido audiovisual sobre temas ambientales y temas de actualidad sobre cambio climático.')}</p>
                        </li>

                        <p>Más actividades</p>
                        <div className="linea-horizontal"></div>

                    </ul>





                    <div className="gallery">
                        <ul>
                            {
                                (["fire", "water", "soil", "wind", "tv"]).map((el: string, index: number) => {
                                    return <li className={tabActive == index ? "is-active" : "disabled"}>
                                        {
                                            events.filter((e: Activity) => {
                                                return e.element == el;
                                            }).map((e: Activity) => {
                                                return <div className="activity" style={{ maxWidth: "100%" }}>
                                                    <Swiper
                                                        style={{ maxWidth: "calc(80vw)", width: "100%" }}
                                                        slidesPerView={3}
                                                        spaceBetween={50}
                                                        breakpoints={{
                                                            600: {
                                                                slidesPerView: 2
                                                            },
                                                            900: {
                                                                slidesPerView: 3
                                                            }
                                                        }}
                                                        autoplay={{
                                                            delay: 2500,
                                                            disableOnInteraction: false,
                                                        }}
                                                        pagination={{
                                                            clickable: true,
                                                        }}
                                                        modules={[Autoplay, Pagination]}
                                                        className="our-projects-swiper"
                                                    >
                                                        {
                                                            e.images.map((img: string) => {
                                                                return <SwiperSlide>
                                                                    <img src={img} alt="" />
                                                                </SwiperSlide>
                                                            })
                                                        }
                                                    </Swiper>
                                                    <span><b>{e.title}</b></span>
                                                    <p>{e.description}</p>
                                                </div>
                                            })
                                        }
                                    </li>
                                })
                            }
                        </ul>
                    </div>

                </div>
            </div>

        </div>


        {/* <div className="how-help-home">
            <TitleSection>{t('titleSectionElements')}</TitleSection>
            <div className="tabs-component">
                <div className="tabs">
                    <ul>
                        <li className={tabActive == 0 ? "is-active fire-txt" : ""} onClick={() => toogleTab(0)}><a>{t('tabFire')} <img src="/assets/images/elements/fire.png" /></a></li>
                        <li className={tabActive == 1 ? "is-active water-txt" : ""} onClick={() => toogleTab(1)}><a>{t('tabWater')} <img src="/assets/images/elements/water.png" /></a></li>
                        <li className={tabActive == 2 ? "is-active soil-txt" : ""} onClick={() => toogleTab(2)}><a>{t('tabSoil')} <img src="/assets/images/elements/soil.png" /></a></li>
                        <li className={tabActive == 3 ? "is-active wind-txt" : ""} onClick={() => toogleTab(3)}><a>{t('tabAir')} <img src="/assets/images/elements/wind.png" /></a></li>
                        <li className={tabActive == 4 ? "is-active tv-txt" : ""} onClick={() => toogleTab(4)}><a>{t('tabTV')} <img src="/assets/images/elements/propachatv.png" /></a></li>
                    </ul>
                </div>
                <div className="content">
                    <ul>
                        <li className={tabActive == 0 ? "is-active" : "disabled"}>
                            <small className="fire-txt">{t('teamFireTitle')}</small>
                            <span>{t('tabFire')}</span>
                            <p>{t('teamFireDescription')}</p>
                        </li>
                        <li className={tabActive == 1 ? "is-active" : "disabled"}>
                            <small className="water-txt">{t('teamWaterTitle')}</small>
                            <span>{t('tabWater')}</span>
                            <p>{t('teamWaterDescription')}</p>
                        </li>
                        <li className={tabActive == 2 ? "is-active" : "disabled"}>
                            <small className="soil-txt">{t('teamSoilTitle')}</small>
                            <span>{t('tabSoil')}</span>
                            <p>{t('teamSoilDescription')}</p>
                        </li>
                        <li className={tabActive == 3 ? "is-active" : "disabled"}>
                            <small className="wind-txt">{t('teamAirTitle')}</small>
                            <span>{t('tabAir')}</span>
                            <p>{t('teamAirDescription')}</p>
                        </li>
                        <li className={tabActive == 4 ? "is-active" : "disabled"}>
                            <small className="tv-txt">{t('teamTVTitle')}</small>
                            <span>{t('tabTV')}</span>
                            <p>{t('teamTVDescription')}</p>
                        </li>
                    </ul>
                </div>
            </div>

        </div>

         <div className="gallery">
            <ul>
                {
                    ["fire", "water", "soil", "wind", "tv"].map((el: string, index: number) => {
                        return <li className={tabActive == index ? "is-active" : "disabled"}>
                            {
                                events.filter((e: Activity) => {
                                    return e.element == el;
                                }).map((e: Activity) => {
                                    return <div className="activity">
                                        <Swiper
                                            style={{ maxWidth: "calc(80vw)", width: "100%" }}
                                            slidesPerView={1}
                                            spaceBetween={50}
                                            breakpoints={{
                                                600: {
                                                    slidesPerView: 2
                                                },
                                                900: {
                                                    slidesPerView: 3
                                                }
                                            }}
                                            autoplay={{
                                                delay: 2500,
                                                disableOnInteraction: false,
                                            }}
                                            pagination={{
                                                clickable: true,
                                            }}
                                            modules={[Autoplay, Pagination]}

                                            className="our-projects-swiper"
                                        >
                                            {
                                                e.images.map((img: string) => {
                                                    return <SwiperSlide >
                                                        <img src={img} alt="" />
                                                    </SwiperSlide>
                                                })
                                            }
                                        </Swiper>

                                        <span><b>{e.title}</b></span>
                                        <p>{e.description}</p>
                                    </div>
                                })
                            }
                        </li>
                    })
                }
            </ul>
        </div> */}

        <div className="form-home">
            <Title sup={t('titleSup')} sub={t('titleSub')} color="green" />

            <p style={{ margin: "1rem 0", display: "block" }}>{t('volunteerDescription')}</p>
            <button className="is-button button" onClick={() => {
                navigate("/account");
            }}>{t('joinTeamButton')}</button>


            {/*
            
            <form className="mt-5" action={homeAPI + "contact.php"} method="POST">
                <div className="field">
                    <label className="label">Nombre</label>
                    <div className="control">
                        <input name="name" required className="input" type="text" placeholder="Nombre" />
                    </div>
                </div>
                <div className="field">
                    <label className="label">Correo electronico</label>
                    <div className="control">
                        <input name="email" required className="input" type="email" placeholder="Correo electronico" />
                    </div>
                </div>
                <div className="field">
                    <label className="label">Mensaje</label>
                    <div className="control">
                        <textarea name="message" required className="textarea" placeholder="Mensaje"></textarea>
                    </div>
                </div>

                <input style={{ display: "none" }} type="text" value={"form-voluntario"} name="key" />

                <div className="is-flex is-justify-content-end">
                    <button type="submit" color="green" className="button">Enviar</button>
                </div>
            </form>
            
            */}
        </div>

        <Footer />
    </div>
}

export default ProyectoPropacha;