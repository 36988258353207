import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import post, { madePost } from "../common/post";
import { Project, ResponseGeneral } from "../common/types";
import { home } from "../common/variables";
import { Title } from "../components/Components";
import Footer from "../components/Footer";
import Loader from "../components/mini_components/Loader";
import "./Proyectos.scss";
import { useTranslation } from "react-i18next";

import "../common/translation";

export const ProjectCard = (props: {
    project: Project,
    passed?: boolean,
    participant?: boolean,
    confirmed?: boolean,
}) => {
    const [images, setImages] = useState(typeof props.project.images == "string" ? JSON.parse(props.project.images) : props.project.images);
    try {
        return <Link to={"/proyecto/" + props.project.name.toLowerCase().replaceAll(" ", "-") + "-" + props.project.project_code.toString()} className="project">
            <div className="img-name-description-project">
                <img className="img-project" src={encodeURI(images[0])} />
                <div className="name-description">
                    <span><b>{props.project.name}</b></span>
                    <br />
                    <small>{props.project.date.split(" ")[0].split("-").reverse().map((e: string, ind: number) => { if (ind !== 2) { return e + "/"; } else { return e; } })}</small>
                    <br />
                    <small>{props.passed ? (props.confirmed ? "(Participaste)" : (props.participant ? "(No participaste)" : "")) : (props.confirmed ? "(Participaste)" : (props.participant ? "(Participarás)" : ""))}</small>
                </div>
            </div>
        </Link>;
    } catch (er) {
        return <></>
    }
}

let Proyectos = () => {
    const [proyectos, setProyectos] = useState({ values: [] as any, isLoaded: false });

    const { t } = useTranslation();

    useEffect(() => {

    }, [proyectos])

    useEffect(() => {
        getProyectos();
    }, [])

    let getProyectos = () => {
        madePost(
            {
                key: "get-projects"
            },
            "projects.php"
        ).then((r: any) => {
            let res = r as ResponseGeneral;
            try {
                let prepared: Project[] = (typeof res.VALUE == "string" ? JSON.parse(res.VALUE) : res.VALUE);
                let valid: Project[] = [];

                prepared.map((project: Project) => {
                    try {
                        project.images = JSON.parse(project.images as unknown as string);
                    } catch (err) { }
                    valid.push(project);
                })
                setProyectos({ values: valid, isLoaded: true })
            } catch (err) {

            }


        })
    }
    return <div className="all-proyects-page">
        <Title sup={t('titleSupProjects')} sub={t('titleSubProjects')} color="green" />

        {
            proyectos.isLoaded ?
                <>
                    <div className="projects">
                        {
                            proyectos.values.length > 0 ?
                                proyectos.values.map((proyecto: Project) => {
                                    return <ProjectCard project={proyecto} />
                                })
                                :
                                <label>{t('projectsComingSoon')}</label>
                        }
                    </div>
                </>
                :
                <Loader className="pb-5" />
        }

        <Footer />

    </div>
};
export default Proyectos;