import anime from "animejs";
import { toast } from "bulma-toast";

export let isset = (element: any): boolean => {
    if (typeof element === undefined || element === null || element === undefined) {
        return false;
    }
    return true;
}

export let cloneObject = (element: any) => {
    return JSON.parse(JSON.stringify(element))
}

export const onCatch = (err: any) => {
    toast({message: "Ha ocurrido un error, porfavor revisa tu conexión e intenta nuevamente", type: "is-danger"})
}

export const animateNumber = (target: HTMLSpanElement, max: number) => {
    anime({
        targets: target,
        innerText: [0, max],
        easing: "cubicBezier(.5, .05, .1, .3)",
        round: true,
        duration: 3000,
        delay: 2000,
        update: function (a) {
            const value = a.animations[0].currentValue;
            target.innerHTML = "+" + value;
        }
    });
}