import { useRef, useState } from "react";

import * as EmailValidator from 'email-validator';

import { useNavigate } from "react-router-dom";
import Input from "../components/mini_components/Input";
import { madePost } from "../common/post";
import { ResponseGeneral } from "../common/types";
import { toast } from "bulma-toast";
import { onCatch } from "../common/functions";

const RecoveryPass = () => {

    const [isFormValid, setIsFormValid] = useState(false);

    const navigate = useNavigate();

    const emailRef = useRef({} as HTMLInputElement);

    const recovery = () => {
        setIsFormValid(false);

        madePost(
            {
                key: "recovery-pass",
                email: emailRef.current.value
            },
            "account.php"
        ).then(r=>{
            let res = r as ResponseGeneral;

            if(res.RESULT){
                toast({message: "Se ha enviado un una contraseña temporal a tu correo electrónico", type: "is-success"})
            }else{
                toast({message: res.EXCEPTION_MESSAGE, type: "is-danger"});
            }
        }).catch(onCatch)
    }

    return <section className="account-login  contrast">
        <div className=" py-6">

            <h2 className="title is-5 has-text-weight-light mb-0">RECUPERA</h2>
            <h1 className="title is-3  has-text-weight-bold">TU CUENTA</h1>

            <Input name="email" innerRef={emailRef} onInput={(ev: any)=>{
                if(EmailValidator.validate(ev.target.value)){
                    setIsFormValid(true);
                }else{
                    setIsFormValid(false)
                }
            }} title="Correo electrónico" placeholder="Correo electrónico" type="email"/>
            
            <div className="field is-grouped">
                <div className="control">
                    <button className="button is-info" disabled={!isFormValid} onClick={recovery}>Recuperar contraseña</button>
                </div>
                <div className="control">
                    <button className="button is-info is-light" onClick={()=>{
                        navigate("/account")
                    }}>Iniciar sesión</button>
                </div>
            </div>

        </div>
    </section>
}

export default RecoveryPass;