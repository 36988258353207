import ReactQuill, { QuillOptions } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { MutableRefObject, useRef, useState } from 'react';

const InputRich = (props: {
    placeholder: string,
    innerRef: MutableRefObject<any>,
    properties?: any,
    onInput?: () => any,
    defaultValue?: any
}) => {

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image'],
            ['clean']
        ]
    }

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image'
    ]

    return <div className="input-rich input-component" style={{ height: "auto", width: "100%", padding: 0}}>
        <ReactQuill
            {...props.properties}
            onChange={props.onInput}
            defaultValue={props.defaultValue}
            modules={modules}
            formats={formats}
            readOnly={false}
            ref={props.innerRef}
        />
    </div>
}

export default InputRich;