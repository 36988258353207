import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./Header.scss";

import { usePopper } from 'react-popper';
import { useTranslation } from "react-i18next";

import "../common/translation";
import { ChangeLanguageMenu } from "./Comps";

export const SideBTNs = () => {
    return <div>

    </div>
}

export const DonateMethods = () => {
    const [tab, setTab] = useState(0);

    const { t } = useTranslation();

    const toogleTab = (num: number) => {
        setTab(num);
    }

    return <div className="navbar-item methods-payment">
        <div className="tabs is-centered">
            <ul>
                <li className="is-active" onClick={() => {
                    toogleTab(0)
                }}><button className="button">{t('QR')}</button></li>
                <li className="hr">
                </li>
                <li onClick={() => {
                    toogleTab(1)
                }}><button className="button">{t('Transferencia')}</button></li>
            </ul>
        </div>
        <div className="content" style={{ alignSelf: "baseline" }}>
            <div className="f1" style={tab == 0 ? { display: "flex" } : { display: "none" }}>
                <img style={{ maxWidth: "15rem", maxHeight: "15rem", width: "15rem", height: "15rem" }} src="/assets/images/qr-donate.jpg" alt={t('QR Donaciones Propacha')} />
            </div>
            <div className="f2" style={tab == 1 ? { display: "flex" } : { display: "none" }}>
                <span><b>{t('Cuenta')}:</b><p>1501007117</p></span>
                <span><b>{t('Titular')}:</b><p style={{ marginBottom: "0" }}>Luis Guillermo Mallea Morales</p><p>4838993 L.P.</p></span>
                <span><b>{t('Banco')}:</b><p>{t('Banco Nacional de Bolivia')}</p></span>
            </div>
        </div>
    </div>
}

const Header = () => {
    const [open, setOpen] = useState(false);
    const [tab, setTab] = useState(0);

    const { t } = useTranslation();

    const navigate = useNavigate();

    const toogleTab = (num: number) => {
        setTab(num);
    }

    const toogle = () => {
        setOpen(!open);
    }

    const close = () => {
        setOpen(false);

        window.scrollTo(0, 0);
    }

    return (
        <header className="header-component navbar is-fixed-top" >
            <div className="navbar-brand">
                <a href="#" className="navbar-item">
                    <img src="/assets/images/propacha-logo-nobg.png" alt={t('Propacha logo')} />
                </a>

                <div className="navbar-burger" data-target="header-menu" onClick={toogle}>
                    <span ></span>
                    <span ></span>
                    <span ></span>
                </div>
            </div>
            <div id="header-menu" className={"navbar-menu " + (open ? "is-active" : "")} >
                <div className="navbar-start">
                    <Link className="navbar-item" to="/inicio" onClick={close}>{t('Inicio')}</Link>
                    <Link className="navbar-item" to="/nosotros" onClick={close}>{t('Quienes somos')}</Link>
                    <Link className="navbar-item" to="/proyecto" onClick={close}>{t('Que hacemos')}</Link>
                    <Link className="navbar-item" to="/proyectos" onClick={close}>{t('Actividades')}</Link>
                    <Link className="navbar-item" to="/cbjlc" onClick={close}>{t('Proyectos')}</Link>

                    <a className="navbar-item" href="https://cursos.propacha.org/?redirect=0" target="_blank" onClick={close}>{t('Cursos')}</a>
                </div>
                <div className="navbar-end ">
                    <div className="navbar-item has-dropdown is-hoverable" style={{ maxWidth: "100%", position: "static", justifyContent: "center" }}>
                        <Link to="/donar" onClick={close} className="navbar-item button is-green my-4 btn-donar">
                            {t('Donar')}
                        </Link>
                        <div className="navbar-dropdown" id="donations" style={{ minWidth: "unset" }}>
                            <DonateMethods />
                        </div>
                    </div>
                    <Link className="navbar-item account my-4" to="/account" onClick={close}>
                        <img src="https://fonts.gstatic.com/s/i/short-term/release/materialsymbolsrounded/account_circle/fill1/48px.svg" alt="" />
                    </Link>

                    <ChangeLanguageMenu />

                </div>
            </div>
        </header>
    )

}
export default Header;