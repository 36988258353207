import { useRef, useState } from "react";
import Input from "./mini_components/Input";

import * as EmailValidator from 'email-validator';
import { madePost } from "../common/post";
import { ResponseGeneral } from "../common/types";
import { toast } from "bulma-toast";
import { onCatch } from "../common/functions";
import { Link, useNavigate } from "react-router-dom";
import { setItem } from "../common/useStorage";

import "../common/translation";
import { useTranslation } from "react-i18next";

const Login = (props: {
    onRegister: () => any,
    onLoginSuccess: () => any
}) => {

    const { t } = useTranslation();

    const [isFormValid, setIsFormValid] = useState(false);

    const navigate = useNavigate();

    const emailRef = useRef({} as HTMLInputElement);
    const passRef = useRef({} as HTMLInputElement);

    const analizeForm = () => {
        if (emailRef.current.value.length > 0 &&
            passRef.current.value.length > 0 &&
            EmailValidator.validate(emailRef.current.value)
        ) {
            setIsFormValid(true);
        } else {
            setIsFormValid(false);
        }
    }

    const onLogin = () => {
        setIsFormValid(false);

        madePost(
            {
                key: "login-account",
                email: emailRef.current.value,
                pass: passRef.current.value
            },
            "account.php"
        ).then(r => {
            let res = r as ResponseGeneral;

            if (res.RESULT) {
                setItem("USER", JSON.stringify(res.VALUE));

                props.onLoginSuccess();
            } else {
                setIsFormValid(true);
                toast({ message: res.EXCEPTION_MESSAGE, type: "is-danger" })
            }
        }).catch(onCatch)
    }

    return <section className="account-login  contrast">
        <div className=" py-6">

            <h2 className="title is-5 has-text-weight-light mb-0">{t('start')}</h2>
            <h1 className="title is-3  has-text-weight-bold">{t('session')}</h1>

            <Input name="email" innerRef={emailRef} onInput={analizeForm} title={t('email')} placeholder={t('email')} type="email" />

            <Input name="password" innerRef={passRef} onInput={analizeForm} title={t('password')} placeholder={t('password')} type="password" />

            <div className="field is-grouped">
                <div className="control">
                    <button className="button is-info" disabled={!isFormValid} onClick={onLogin}>{t('loginButton')}</button>
                </div>
                <div className="control">
                    <button className="button is-info is-light" onClick={props.onRegister}>{t('createAccountButton')}</button>
                </div>
                <div className="control " style={{ display: "flex", alignItems: "center" }}>
                    <Link className=" " style={{ color: "rgba(0,0,0,0.5)", textDecoration: "underline" }} to="/recovery-pass">{t('forgotPassword')}</Link>
                </div>
            </div>


        </div>
    </section>
}

export default Login;