import { useState } from "react";
import "../pages/Inicio.scss"

const Modal = ({childrenModal, children,title}:{childrenModal:any, children:any, title:any}) => {
    const [active, setActive] = useState(false);

    return <div className="lista-eventos">
        <div className={`modal ${active ? "is-active" : ""}`}>
            <div className="modal-background" onClick={() => { setActive(false) }}></div>
            <div className="modal-content">
                <div>{childrenModal}</div>
            </div>
            <button onClick={() => { setActive(false) }} className="modal-close is-large" aria-label="close"></button>
        </div>
        <li>
            <p onClick={() => { setActive(true) }} color="green">{title}</p>
            {children}
        </li>

    </div>

}
export default Modal;