
import { Title, TitlePage, TitleSection } from "../components/Components";
import Footer from "../components/Footer";

import "./CBJLC.scss";
import { useState } from "react";


import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from "swiper";
// Import Swiper styles
import 'swiper/swiper-bundle.css';
import { DonateMethods } from "../components/Header";
import { useTranslation } from "react-i18next";

import "../common/translation";

import { BrowserRouter, Link, Route } from 'react-router-dom'


export const DonateSection = (props: { contrast?: boolean, onlybtn?: boolean }) => {
    const [show, setIsShow] = useState(false);
    const { t } = useTranslation();

    const click = () => {
        if (window.screen.availWidth <= 1023) {
            //show modal
            setIsShow(true);
        } else {
            let dropdown = (document.getElementById("donations") as HTMLDivElement);

            if (!dropdown.classList.contains("is-block")) {
                dropdown.classList.add("is-block");
            } else {
                dropdown.classList.remove("is-block");
            }
        }
    }

    return <div className={"history-cbjlc donate-section " + (props.contrast ? "contrast" : "")}>
        <Title sup={t('helpWithDonation')} sub={t('donation')} color="green" />

        {
            props.onlybtn ?
                <></>
                :
                <>
                    <TitleSection>{t('howDonationHelps')}</TitleSection>
                    <p style={{ textAlign: "justify" }}>{t('donationImpactDescription')}</p>
                </>
        }

        <button className="button mt-3" color="green" onClick={click}>{t('contributeWithDonation')}</button>

        <div className={"modal " + (show ? "is-active" : "")}>
            <div className="modal-background"></div>
            <div className="modal-content">
                <DonateMethods />
            </div>
            <button className="modal-close is-large" aria-label="close" onClick={() => {
                setIsShow(false);
            }}></button>
        </div>

    </div>
}


const CBJLC = () => {
    const { t } = useTranslation();

    const images2021 = {
        title: "CBJLC 2021",
        images: [
            "https://propacha.org/media/images/proyectos/fotografias/s1-6.webp",
            "https://propacha.org/media/images/proyectos/fotografias/s1-1.webp",
            "https://propacha.org/media/images/proyectos/fotografias/s1-2.webp",
            "https://propacha.org/media/images/proyectos/fotografias/s1-4.webp"
        ]
    }

    const images2022 = {
        title: "CBJLC 2022",
        images: [
            "https://propacha.org/img/cbjlc/all/cbjlc%20(1).jpg",
            "https://propacha.org/img/cbjlc/all/cbjlc%20(2).jpg",
            "https://propacha.org/img/cbjlc/all/cbjlc%20(3).jpg",
            "https://propacha.org/img/cbjlc/all/cbjlc%20(4).jpg",
            "https://propacha.org/img/cbjlc/all/cbjlc%20(5).jpg",
            //"https://propacha.org/img/cbjlc/all/cbjlc%20(6).jpg",
            "https://propacha.org/img/cbjlc/all/cbjlc%20(7).jpg",
            "https://propacha.org/img/cbjlc/all/cbjlc%20(8).jpg",
            "https://propacha.org/img/cbjlc/all/cbjlc%20(9).jpg",
            "https://propacha.org/img/cbjlc/all/cbjlc%20(10).jpg",
            "https://propacha.org/img/cbjlc/all/cbjlc%20(11).jpg",
            "https://propacha.org/img/cbjlc/all/cbjlc%20(12).jpg",
        ]
    }

    const images2023 = {
        title: "CBJLC 2023",
        images: [
            "https://propacha.org/img/cbjlc/all/1.%20Portada-min.png",
            "https://propacha.org/img/cbjlc/all/2.%20Mesa%201_%20Seguridad%20y%20Soberan%C3%ADa%20Alimentaria-min.jpg",
            "https://propacha.org/img/cbjlc/all/21.%202da%20jornada%20de%20trabajo%203-min.jpg",
            "https://propacha.org/img/cbjlc/all/22.%202da%20jornada%20de%20trabajo%204-min.jpg",
            "https://propacha.org/img/cbjlc/all/23.%202da%20jornada%20de%20trabajo%205-min.png",
            "https://propacha.org/img/cbjlc/all/24.%202da%20jornada%20de%20trabajo%206-min.jpg",
            "https://propacha.org/img/cbjlc/all/25.%202da%20jornada%20de%20trabajo%207-min.jpg",
            "https://propacha.org/img/cbjlc/all/26.%202da%20jornada%20de%20trabajo%201-min.jpg",
            "https://propacha.org/img/cbjlc/all/27.%202da%20jornada%20de%20trabajo%201-min.png",
            "https://propacha.org/img/cbjlc/all/28.%20Comit%C3%A9%20organizador%20Propacha-min.png",
            "https://propacha.org/img/cbjlc/all/29.%20Coodinador%20Juan%20Orgaz%20antes%20de%20la%20Declaratoria%20de%20la%20Juventud%202023-min.jpg",
            "https://propacha.org/img/cbjlc/all/3.%20Mesa%202_%20Desarrollo%20urbano%20sostenible-min.jpg",
            "https://propacha.org/img/cbjlc/all/30.%20Mesa%20de%20trabajo%20durante%20presentaci%C3%B3n-min.jpg",
            "https://propacha.org/img/cbjlc/all/31.%20Dando%20lectura%20a%20materiales%20obsequiados-min.png",
            "https://propacha.org/img/cbjlc/all/32.%20Premiaci%C3%B3n%20a%20mejores%20destacados-min.png",
            "https://propacha.org/img/cbjlc/all/33.%20Foto%20final%20de%20la%20CBJLC%202023-min.png",
            "https://propacha.org/img/cbjlc/all/4.%20Mesa%203_%20Agua%20y%20residuos-min.jpg",
            "https://propacha.org/img/cbjlc/all/5.%20Mesa%204_%20Bosques%20y%20biodiversidad-min.jpg",
            "https://propacha.org/img/cbjlc/all/6.%20Mesa%205_%20Energ%C3%ADa%20y%20Transici%C3%B3n%20energ%C3%A9tica-min.png",
            "https://propacha.org/img/cbjlc/all/6.%20Mesa%206_%20Econom%C3%ADa%20circular%20y%20Emprendimientos%20sostenibles-min.jpg",
            "https://propacha.org/img/cbjlc/all/7.%20Financiadores%20y%20aliados-min.jpg"
        ]
    };

    const images2023_2 = {
        title: "NDCs",
        images: [
            "/assets/images/ndcs1.jpeg",
            "/assets/images/ndcs2.jpeg",
        ]
    };






    return <div className="cbjlc-page page">


        {/*        ///////////////////////////////////////////////////////////////                 */}

        <img src="/assets/images/cbjcl.jpg" alt="" className="head-img positioned" />

        <div className="title-page">
            <TitlePage center title={t('whatIsCBJLC')} subtitle={t('cbjlcDescription')} />
        </div>


        <img src="/assets/svg/img-triangle.svg" className="triangle" />

        <DonateSection onlybtn />

        <div className="history-cbjlc contrast" style={{}}>
            <Title sup={t('cbjlcYear').split(" ")[0]} sub={t('cbjlcYear').split(" ")[1]} color="green" />

            <p>
                <br /><br />
                {t('proudOrganization')}
                <br /><br />
                <a target="_blank" href="https://propacha.org/media/files/Declaratoria%20CBJLC%202023.pdf" style={{ display: 'flex', flexFlow: 'nowrap column', alignItems: 'center', justifyContent: 'center', gap: '0rem' }}>
                    <img src="/assets/images/memo2.jpeg" alt={t('accessInstitutionalMemory')} style={{ width: '10rem', height: '10rem', mixBlendMode: undefined, borderRadius: '.5rem', marginBottom: '1rem' }} />
                    {t('accessInstitutionalMemory')} <b>{t('youthDeclaration')}</b>
                </a>
            </p>


            <div className="gallery">
                <Swiper
                    style={{ maxWidth: "calc(80vw)", width: "100%" }}
                    slidesPerView={1}
                    spaceBetween={50}
                    breakpoints={{
                        600: {
                            slidesPerView: 2
                        },
                        900: {
                            slidesPerView: 3
                        }
                    }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Autoplay, Pagination]}

                    className="our-projects-swiper"
                >
                    {
                        images2023.images.map((img: string) => {
                            return <SwiperSlide >
                                <img src={img} alt="" />
                            </SwiperSlide>
                        })
                    }
                </Swiper>

                <span><b>{images2023.title}</b></span>
            </div>

            <p>
                {t('ndcAnalysis')}
                <br /><br />
                <a href="https://jubileobolivia.org.bo/Publicaciones/Revistas-Especializadas/Analisis-de-sociedad-civil-de-los-compromisos-gubernamentales-sobre-cambio-climatico">{t('learnMore')}</a>
            </p>


            <div className="gallery " style={{ width: '100%' }}>
                <Swiper
                    style={{ maxWidth: "calc(80vw)", width: "100%" }}
                    slidesPerView={1}
                    spaceBetween={50}
                    breakpoints={{
                        600: {
                            slidesPerView: 2
                        },
                        900: {
                            slidesPerView: 3
                        }
                    }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Autoplay, Pagination]}

                    className="our-projects-swiper"
                >
                    {
                        images2023_2.images.map((img: string) => {
                            return <SwiperSlide >
                                <img src={img} alt="" />
                            </SwiperSlide>
                        })
                    }
                </Swiper>
            </div>

        </div>

        <div className="history-cbjlc">
            <Title sup="CBJLC" sub="2022" color="green" />

            <p>
                {t('secondEditionDescription')}
                <br />
                {t('cbjlcObjective')}
                <br />
                {t('summitDate')}
                <br />
                {t('collectiveSelection')}
                <br />
                {t('preparationForNext')}
                <br />
                {t('waitForIt')}
            </p>


            <div className="gallery">
                <Swiper
                    style={{ maxWidth: "calc(80vw)", width: "100%" }}
                    slidesPerView={1}
                    spaceBetween={50}
                    breakpoints={{
                        600: {
                            slidesPerView: 2
                        },
                        900: {
                            slidesPerView: 3
                        }
                    }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Autoplay, Pagination]}

                    className="our-projects-swiper"
                >
                    {
                        images2022.images.map((img: string) => {
                            return <SwiperSlide >
                                <img src={img} alt="" />
                            </SwiperSlide>
                        })
                    }
                </Swiper>

                <span><b>{images2022.title}</b></span>
            </div>



        </div>

        <div className="history-cbjlc contrast" style={{ paddingBottom: '6rem' }}>
            <Title sup="CBJLC" sub="2021" color="green" />

            <p>
                {t('firstSummitDescription')}
                <br />
                <br />
                <a className="memo-inst" target="_blank" href="https://www.researchgate.net/publication/362429721_Memoria_de_la_1_Cumbre_Boliviana_de_Jovenes_Lideres_por_el_Clima_-_CBJLC"><img src="/assets/images/memo.jpeg" alt="Accede a la memoria institucional" /></a>
                <br />
            </p>

            <p>
                {t('youthClimateAgenda')}
                <br />
                {t('eventOrganization')}
                <br />
                {t('participationInCommission')}
            </p>


            {
                /*
                
                <div className="gallery">
                <Swiper
                    style={{ maxWidth: "calc(80vw)", width: "100%" }}
                    slidesPerView={1}
                    spaceBetween={50}
                    breakpoints={{
                        600: {
                            slidesPerView: 2
                        },
                        900: {
                            slidesPerView: 3
                        }
                    }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Autoplay, Pagination]}

                    className="our-projects-swiper"
                >
                    {
                        images2021.images.map((img: string) => {
                            return <SwiperSlide >
                                <img src={img} alt="" />
                            </SwiperSlide>
                        })
                    }
                </Swiper>

                <span><b>{images2021.title}</b></span>
            </div>

                */
            }

        </div>

        <Footer />
    </div>

}

export default CBJLC;