import "./Loader.scss";
const Loader = (props: {color?: string, className?: string}) => {

    return <div className={"loader-component "+props.className} style={{marginLeft: "auto", marginRight: "auto"}}>
        <div className="lds-grid">
            <div style={{backgroundColor: (props.color? props.color : "var(--color)")}}></div>
            <div style={{backgroundColor: (props.color? props.color : "var(--color)")}}></div>
            <div style={{backgroundColor: (props.color? props.color : "var(--color)")}}></div>
            <div style={{backgroundColor: (props.color? props.color : "var(--color)")}}></div>
            <div style={{backgroundColor: (props.color? props.color : "var(--color)")}}></div>
            <div style={{backgroundColor: (props.color? props.color : "var(--color)")}}></div>
            <div style={{backgroundColor: (props.color? props.color : "var(--color)")}}></div>
            <div style={{backgroundColor: (props.color? props.color : "var(--color)")}}></div>
            <div style={{backgroundColor: (props.color? props.color : "var(--color)")}}></div>
        </div>
    </div>
}

export default Loader;