import './App.scss';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import "./variables.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import Inicio from './pages/Inicio';
import Header, { SideBTNs } from './components/Header';
import Nosotros from './pages/Nosotros';
import Account from './pages/Account';

import "bulma";
import 'font-awesome/css/font-awesome.min.css';

import 'bootstrap-icons/font/bootstrap-icons.css';
import Profile from './pages/Profile';
import Proyectos from './pages/Proyectos';
import Admin from './pages/Admin';
import ProyectoPropacha from './pages/ProyectoPropacha';
import Proyecto from './pages/Proyecto';
import { initActualDate } from './common/initialize';
import QRProject from './pages/QRProject';
import CBJLC from './pages/CBJLC';
import RecoveryPass from './pages/RecoveryPass';
import Links from './pages/Links';
import AdminLinks from './pages/AdminLinks';
import { useTranslation } from 'react-i18next';

import "./common/translation";
import Donar from './pages/Donar';
import ProyectCBJLC from './pages/ProyectCBJLC';
import ProyectHuerto from './pages/ProyectHuerto';

const App = () => {

  const [splash, setSplash] = useState(1); //1 show, 0 hidding, 2 d-none
  const { t } = useTranslation();

  //initTranslation();

  useEffect(() => {
    initActualDate();

  }, [])

  useLayoutEffect(() => {
    setTimeout(() => {
      setSplash(0);
    }, 3000);

    setTimeout(() => {
      setSplash(2);
    }, 3500);
  }, [])

  return (
    <div style={{ "--color": "rgba(0,0,0,0.5)" } as React.CSSProperties}>
      <div className={"splash-screen " + (splash == 1 ? "active" : (splash == 0 ? "" : "disabled"))}>
        <img src="https://dev.propacha.org/assets/images/propacha-logo-nobg.png" alt="" />
        <span><b>{t('moreAction')}</b>, {t('lessWords')}</span>
      </div>
      <Router>

        <Header />

        <SideBTNs />

        <Routes>

          <Route path="/nosotros" element={<Nosotros />} />
          <Route path="/proyecto" element={<ProyectoPropacha />} />
          <Route path="/inicio" element={<Inicio />} />
          <Route path="/account" element={<Account />} />
          <Route path="/profile" element={<Profile />} />

          <Route path="/proyectos" element={<Proyectos />} />
          <Route path="/proyecto/:name" element={<Proyecto />} />

          <Route path="/admin" element={<Admin />} />
          <Route path="/admin-links" element={<AdminLinks />} />

          <Route path="/cbjlc" element={<CBJLC />} />
          <Route path="/proyectcbjlc" element={<ProyectCBJLC />} />
          <Route path="/proyecthuerto" element={<ProyectHuerto />} />

          <Route path="/donar" element={<Donar />} />

          <Route path="/qr-project" element={<QRProject />} />

          <Route path="/recovery-pass" element={<RecoveryPass />} />

          <Route path="/links" element={<Links />} />

          <Route path="/" element={<Navigate to="/inicio" />} />
        </Routes>
      </Router>
    </div>

  );
}


export default App;
