export let storageAvailable = () => {
    let storage = window.localStorage,
            x = '__storage_test__';
    try {        
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    }
    catch(e) {
        return e instanceof DOMException && (
            // everything except Firefox
            e.code === 22 ||
            // Firefox
            e.code === 1014 ||
            // test name field too, because code might not be present
            // everything except Firefox
            e.name === 'QuotaExceededError' ||
            // Firefox
            e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
            // acknowledge QuotaExceededError only if there's something already stored
            storage.length !== 0;
    }
}

export let setItem = (key: string, value: string) => {
    if(storageAvailable()){
        localStorage.setItem(key, value);
        return true;
    }else{
        return false;
    }
}

export let getItem = (key: string):string | null => {
    if(storageAvailable()){
        return localStorage.getItem(key);
    }else{
        return null;
    }
}

export let clear = () => {
    if(storageAvailable()){
        localStorage.clear();
        return true;
    }else{
        return false;
    }
}