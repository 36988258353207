export const ES = {
    "whoWeAre": "Quienes somos",
    "aboutUsDesc": "Somos una organización ambiental de jóvenes voluntarios...",
    "whatIsProPacha": "¿QUÉ ES PROPACHA?",
    "proPachaOrigin": "ProPacha nace de la unión de dos conceptos:",
    "proDefinition": "“pro” -. Adjetivo con el sentido de ‘partidario o favorable'.",
    "pachaDefinition": "“Pacha” que representa el espacio [materia] y a la vez tiempo [espíritu],...",
    "proPachaMeaning": "Entonces, ProPacha es una organización que está en favor de esa armonía...",
    "donationTitle": "AYÚDANOS CON UNA DONACIÓN",
    "donationImpact": "Con tu donación realizamos acciones de verdadero impacto...",
    "originStoryTitle": "NUESTRA HISTORIA",
    "originStoryDesc": "El Movimiento ProPacha nace a principios del 2019...",
    "ecoMovementDesc": "Somos un movimiento eco ambiental de voluntarios...",
    "teamMembers": "+25 Miembros de nuestro equipo",
    "volunteers": "+300 Voluntarios",
    "missionTitle": "MISIÓN",
    "missionDesc": "Somos una organización de jóvenes ecologistas...",
    "visionTitle": "VISIÓN",
    "visionDesc": "Ser un movimiento eco-ambiental de referencia a nivel nacional...",
    "founders": "Fundadores",
    "luisPosition": "Cofundador, Director general",
    "isabelPosition": "Cofundador, Directora Legal",
    "albaPosition": "Cofundadora, Directora de Recursos Humanos y comunicación",
    "members": "Miembros",
    "allProjects": "TODOS LOS PROYECTOS",
    "sendaVerdeInfo": "Visita Senda Verde (solo miembros)",
    "oxygenRouteInfo": "Ruta del oxigeno sabado 25 de marzo",
    "ecoFairInfo": "Participa en la Feria Ecologica PLANETA F",
    "coursesTitle": "Cursos Propacha",
    "activismCourse": "Dominando el arte del ACTIVISMO AMBIENTAL",
    "swissSupport": "Con el apoyo del Proyecto Diálogo y Apoyo Colaborativo",
    "newAccount": "Nueva cuenta",
    "username": "Nombre de usuario",
    "passwordRequirements": "La contraseña debería tener al menos 8 caracter...",
    "accountCreated": "Hemos enviado un correo electrónico a ______",
    "our": "Nuestros",
    "projects": "Proyectos",
    "registerAndStart": "Registrate y comienza",
    "welcomeMessage": "Te damos la bienvenida al espacio donde la Pacha es escuchada",
    "wantToCollaborate": "¿QUIERES REALIZAR UNA ALIANZA O PROYECTO",
    "withUs": "CON NOSOTROS?",
    "organizationDescription": "Somos una organización de jóvenes ecologistas que hacen frente a la crisis climática mediante acciones para la protección, conservación y restauración del medio ambiente, trabajando desde múltiples enfoques con un objetivo en común: salvar el planeta.",
    "propachaBolivia": "Propacha Bolivia",
    "results": "Resultados",
    "activities": "Actividades",
    "activeVolunteers": "Voluntarios activos",
    "completedProjects": "Proyectos realizados",
    "recognitions": "Reconocimientos",
    "become": "Hazte",
    "volunteer": "Voluntario",
    "createAccount": "Crea tu cuenta y participa en nuestras actividades mediante el siguiente botón",
    "joinTeam": "Únete al equipo",
    "howWeHelp": "¿Cómo buscamos ayudar?",
    "fire": "Fuego",
    "water": "Agua",
    "earth": "Tierra",
    "air": "Aire",
    "networksTV": "Redes TV",
    "fireTeam": "Equipo Fuego",
    "fireDesc": "Vinculamos la investigación teórica y la acción directa de activismo no violento para luchar contra el cambio climático",
    "waterTeam": "Equipo Agua",
    "waterDesc": "Informamos, concientizamos y educamos a través de actividades y talleres para tener un estilo de vida sano, sostenible y menos consumista",
    "earthTeam": "Equipo Tierra",
    "earthDesc": "Impulsamos proyectos para el cuidado del medio ambiente y talleres sobre conocimiento de flora y fauna",
    "airTeam": "Equipo Aire",
    "airDesc": "Enfocados en el ecoturismo, eco-trekings, viajes de conexión con la naturaleza, proyectos arquitectónicos y transformación de espacios verdes",
    "networksTVTeam": "Equipo Redes TV",
    "networksTVDesc": "Generamos contenido audiovisual sobre temas medioambientales y problemáticas actuales sobre cambio climático",
    "soil": "Tierra",
    "wind": "Aire",
    "tvNetwork": "Redes TV",
    "soilTeam": "Equipo Tierra",
    "windTeam": "Equipo Aire",
    "tvNetworkTeam": "Equipo Redes TV",
    "soilDesc": "Impulsamos proyectos para el cuidado del medio ambiente y talleres sobre conocimiento de flora y fauna",
    "windDesc": "Enfocados en el ecoturismo, eco-trekings, viajes de conexión con la naturaleza, proyectos arquitectónicos y transformación de espacios verdes",
    "nameLabel": "Nombre",
    "namePlaceholder": "Nombre",
    "emailLabel": "Correo electrónico",
    "emailPlaceholder": "Correo electrónico",
    "cellLabel": "Celular",
    "cellPlaceholder": "Celular",
    "messageLabel": "Mensaje",
    "messagePlaceholder": "Mensaje",
    "sendButton": "Enviar",
    "helpWithDonation": "Ayúdanos con una",
    "donation": "Donación",
    "howDonationHelps": "¿Cómo una donación ayuda a Propacha?",
    "donationImpactDescription": "Con tu donación realizamos acciones de verdadero impacto. Queremos un cambio, tenemos las ganas, el talento y la gente, pero con tu aporte tendremos la pieza que nos permitirá llegar más lejos. Si quieres también ser parte de esta aventura, mira las actividades que tenemos y únete al movimiento.",
    "contributeWithDonation": "Contribuye al cambio con tu donación",
    "QR": "QR",
    "Transferencia": "Transferencia",
    "Cuenta": "Cuenta",
    "Titular": "Titular",
    "Banco": "Banco",
    "Banco Nacional de Bolivia": "Banco Nacional de Bolivia",
    "Propacha logo": "Logo de Propacha",
    "Inicio": "Inicio",
    "Quienes somos": "Quiénes somos",
    "Que hacemos": "Qué hacemos",
    "Actividades": "Actividades",
    "CBJLC": "CBJLC",
    "Cursos": "Cursos",
    "Donar": "Donar",
    "PROPACHA": "PROPACHA",
    "Proyectos": "Proyectos",
    "¿Quiénes somos?": "¿Quiénes somos?",
    "¿Qué hacemos?": "¿Qué hacemos?",
    "titleQuienesSomos": "Quienes somos",
    "descriptionQuienesSomos": "Somos una organización ambiental de jóvenes voluntarios que realiza proyectos de triple impacto para la concientización y preservación del medio ambiente. Impulsamos la Conservación y Restauración ecológica y el uso de prácticas sostenibles para combatir el cambio climático en Bolivia y en el planeta.",
    "titleQueEs": "¿Qué es",
    "subtitlePropacha": "Propacha?",
    "definitionPro": "“pro” -. Adjetivo con el sentido de ‘partidario o favorable'.",
    "definitionPacha": "“Pacha” que representa el espacio [materia] y a la vez tiempo [espíritu], plantea un origen espacio-temporal y relativo de todas las cosas. Pues todo está conectado.",
    "definitionHarmony": "Un escenario, en el que la forma/idea de materia-espíritu, se juntan para coexistir, aquella justa vinculación entre lo material e inmaterial en el espacio-tiempo. Entonces, ProPacha es una organización que está en favor de esa armonía entre la naturaleza y el hombre, entre la cultura y el progreso.",
    "historyTitleSup": "Nuestra",
    "historyTitleSub": "Historia",
    "historyParagraph1": "El Movimiento ProPacha nace a principios del 2019 a través de reuniones de diferentes grupos ambientalistas y personas independientes que manifestaron su preocupación por la crisis climática actual y su voluntad por hacer un cambio y crear conciencia en la sociedad a partir de actividades y experiencias que nos conecten con la naturaleza. Unimos fuerzas e intercambiamos conocimientos por una misma causa, limpiamos la basura de un cerro y nos volvimos un equipo inseparable.",
    "historyParagraph2": "Somos un movimiento eco ambiental de voluntarios que busca fomentar el cuidado y respeto a la naturaleza, luchar contra el cambio climático y generar una armoniosa convivencia con la tierra y todos los seres vivos.",
    "historyTeamMembers": "Miembros de nuestro equipo",
    "historyVolunteers": "Voluntarios",
    "aboutUsTitleSup": "Acerca de",
    "aboutUsTitleSub": "Nosotros",
    "missionDescription": "Somos una organización de jóvenes ecologistas que realizan acciones y proyectos para la protección y conservación de flora y fauna",
    "visionDescription": "Ser un movimiento eco-ambiental de referencia a nivel nacional, donde podamos trabajar en distintos proyectos a nivel macro en coordinación con instituciones, organizaciones, empresas y agentes de cambio de la sociedad.",
    "titlePageTitle": "Qué hacemos",
    "titlePageSubtitle": "Realizamos actividades que generen un impacto positivo en la sociedad y en el medio ambiente, mediante proyectos de triple impacto. Tenemos 5 equipos multidisciplinarios que nos permiten realizar acciones de investigación, concientización,  implementación de huertos urbanos, protección de flora y fauna, eco-turismo y derecho ambiental.",
    "titleSectionElements": "Nuestros elementos",
    "tabFire": "Fuego",
    "tabWater": "Agua",
    "tabSoil": "Tierra",
    "tabAir": "Aire",
    "tabTV": "Redes TV",
    "teamFireTitle": "Equipo Fuego",
    "teamFireDescription": "Considerado como símbolo de conocimiento y espiritualidad desde los pueblos de la antigüedad. De esa forma y simbolismo, éste eje de acción, se caracteriza por cultivar el conocimiento fortaleciendo acciones y proyectos. Buscamos promover un activismo consciente y responsable, a partir de “actuar conociendo y conocer actuando” donde el objetivo principal es vincular la investigación teórica y la acción directa bajo un mismo horizonte.",
    "teamWaterTitle": "Equipo Agua",
    "teamWaterDescription": "El elemento que representa la sabiduría, la fluidez y confianza. Enfocados en generar conciencia y educación ambiental mediante prácticas sostenibles en pro del cuidado de la Madre Tierra. Nuestra función es informar, concientizar y educar para afianzar la filosofía de vida de armonía con el medio ambiente y cuidado de la naturaleza, impulsando actividades y talleres que promuevan prácticas sostenibles y saludables. Hablamos de alimentación consciente, estilo de vida sostenible, huertos urbanos, tecnología y eco-innovación.",
    "teamSoilTitle": "Equipo Tierra",
    "teamSoilDescription": "Representa la materia sólida y la estructura del universo, que incluye toda su flora y fauna, toda la creación. Es el conducto a través del cual los otros elementos son parte. Buscamos promover conciencia sobre lo que está a nuestro alrededor y el mundo donde vivimos. Nos dedicamos a realizar talleres de conocimiento sobre fauna y flora y proyectos de desarrollo en favor del medio ambiente.",
    "teamAirTitle": "Equipo Aire",
    "teamAirDescription": "Es el elemento de la libertad. El aire está en todo y su esencia está en relacionarse, disfrutar, aprender y conocer al medio ambiente y la naturaleza. Ésto quiere decir que nuestras acciones deben servir al cuidado del medio ambiente, pero sin dejar de ser divertidas y emocionantes, visitando lugares interesantes o que necesitan soluciones para distintos problemas relacionados al medio ambiente pero también transformamos la ciudad con obras tangibles que afecte a la vida de los ciudadanos y les de un mensaje. Nuestras actividades son  eco-trekings, viajes de conciencia a la naturaleza, ecoturismo, proyectos arquitectónicos y transformación de espacios verdes.",
    "teamTVTitle": "Equipo Redes TV",
    "teamTVDescription": "El departamento creativo que muestra contenido audiovisual y más allá de ser un elemento, es la energía omnipresente en la organización. Busca informar, educar, concientizar, entretener e inspirar al público en general a realizar  prácticas medioambientales, actividades, proyectos, acciones conjuntas, eventos y noticias acerca del medio ambiente mediante medios de comunicación televisivas y plataformas de contenido digital, tales como Facebook, Instagram, Tiktok y YouTube. Movimiento ProPacha está conformada por jóvenes y apunta a la socialización de todo lo que realiza a través de las plataformas más utilizadas en Bolivia y a nivel internacional.",
    "titleSup": "Hazte",
    "titleSub": "Voluntario",
    "volunteerDescription": "Hazte voluntario creando tu cuenta y participando en nuestras actividades mediante el siguiente botón",
    "joinTeamButton": "Únete al equipo",
    "titleSupProjects": "Todos los",
    "titleSubProjects": "Proyectos",
    "projectsComingSoon": "Disponibles próximamente",
    "whatIsCBJLC": "¿QUÉ ES LA CBJLC?",
    "cbjlcDescription": "La CBJLC es un espacio de diálogo, construcción de propuestas y acción juvenil, que reúne a diversas organizaciones de todo el país en torno a la problemática ambiental.",
    "cbjlcYear": "CBJLC 2023",
    "proudOrganization": "Nuestra organización, está muy orgullosa de poder llevar a jóvenes de toda Bolivia de los 9 departamentos, área urbana y rural a este encuentro tan importante. Donde la agenda de la juventud es expuesta basada en soluciones y propuestas de jóvenes líderes y comprometidos con el medio ambiente.",
    "accessInstitutionalMemory": "Conoce la",
    "youthDeclaration": "Declaratoria de la Juventud Cumbre Boliviana de Jóvenes Líderes por el Clima 2023",
    "ndcAnalysis": "Esta publicación comprende un análisis sobre los Compromisos Nacionalmente Determinados de Bolivia (NDC por su sigla en inglés) para el periodo 2021-2030 realizado desde la sociedad civil en los principales sectores identificados como ser agua, energía, agricultura y bosques. El documento comprende un análisis de cada una de las metas establecidas en estos sectores, y evalúa si son viables en función al estado de situación actual. Los insumos sintetizados en este documento inédito en el país fueron recogidos de un ciclo de 6 eventos a nivel nacional donde participaron más de 500 personas en las regiones de La Paz, Santa Cruz, Cochabamba, Tarija, Sucre y Cobija donde se rescata la amplia participación de la Juventud boliviana durante todo el proceso.",
    "learnMore": "Conoce más",
    "secondEditionDescription": "Esta segunda edición mostrará un concepto que enfoca el apoyo a Colectivos ya constituidos y que vienen trabajando constantemente en pro de la conservación del medio ambiente.",
    "cbjlcObjective": "El objetivo de la CBJLC 2022 es generar cadenas de valor que les permitan realizar acciones que sean relevantes ante la sociedad y también pongan a prueba sus capacidades para ser generadores de cambio.",
    "summitDate": "La cumbre se realizó entre el 8 y 9 de octubre de 2022, con una exitosa participación.",
    "collectiveSelection": "Se seleccionaron 10 colectivos de toda Bolivia para ingresar en el programa de fortalecimiento. Que estará disponible en poco tiempo.",
    "preparationForNext": "Nos preparamos para la CBJLC en abril 2023.",
    "waitForIt": "!Esperalo!",
    "firstSummitDescription": "En octubre de 2021, Movimiento ProPacha organizó la 1° Cumbre Nacional de Jóvenes Líderes por el Clima (CBJLC), donde alrededor de 250 jóvenes de áreas urbanas y rurales fueron parte de diversas etapas de este proceso, que desembocó en la elaboración de una Agenda Climática de la Juventud a nivel nacional que se encuentra expresa en la Memoria Institucional.",
    "youthClimateAgenda": "La Agenda Climática de la Juventud - CBJLC 2021 logró avances importantes en el camino de las relaciones diplomáticas entre esferas gubernamentales y los jóvenes. Desde entonces, participamos activamente en:",
    "eventOrganization": "Organización de un ciclo de eventos presenciales por todo el país para la discusión de los nuevos NDCs de Bolivia",
    "participationInCommission": "Participación en la Comisión Técnica Tierra y Territorio en el Senado Nacional para poner en consideración la propuesta de un proyecto de Ley Nacional de Cambio Climático.",
    "start": "INICIAR",
    "session": "SESIÓN",
    "email": "Correo electrónico",
    "password": "Contraseña",
    "loginButton": "Iniciar sesión",
    "createAccountButton": "Crear cuenta",
    "forgotPassword": "Olvidaste tu contraseña",
    "create": "CREAR",
    "account": "CUENTA",
    "fullName": "Nombre completo",
    "cellphone": "Celular",
    "location": "Ubicación",
    "birthdate": "Fecha de nacimiento",
    "repeatPassword": "Repetir contraseña",
    "invalidEmail": "Correo no válido",
    "passwordMismatch": "Las contraseñas no coinciden",
    "acceptTerms": "Acepto los",
    "termsAndConditions": "términos y condiciones de Propacha",
    "selectYour": "SELECCIONA TU",
    "element": "ELEMENTO",
    "tv": "PROPACHA TV",
    "moreAction": "Más acción",
    "lessWords": "menos palabras",
    "planetWarning": "El planeta está entrando en un punto de no retorno y donde nuestra supervivencia cada vez será más difícil en todo el mundo, desde donde estes tu eliges si contribuyes a la destrucción o al cambio.",
    "ourOrigin": "Nuestra organización nació del sueño, de jóvenes con una motivación y una pasión por hacer un cambio donde nadie actuaba, el movimiento propacha es la unión del talento y corazones de jóvenes buscando trabajar para conseguir cambios en la sociedad para cuidar la pachamama.",
    "aboutUs": "Somos una organización sin fines de lucro que trabaja desde el 2019 para proteger el medio ambiente en Bolivia. Somos un equipo de 40 miembros y más de 300 voluntarios que estamos comprometidos a crear un futuro más sostenible para nuestro país.",
    "donationImpactTitle": "Tu donación nos ayudará a seguir trabajando para:",
    "donationImpact1": "Hacer realidad proyectos de triple impacto para preservar y cuidar el medio ambiente",
    "donationImpact2": "Apoyar en iniciativas con diferentes instituciones y organizaciones",
    "donationImpact3": "Nuestra sostenibilidad como organización.",
    "donationImportance": "Tu donación es importante para nosotr@s. Con tu ayuda, podemos seguir trabajando para proteger el medio ambiente de Bolivia para las generaciones futuras.",
    "donateNow": "Dona ahora y sé parte del cambio, Ama la pacha vive propacha.",
    "bankDetails": "Datos bancarios:",
    "bankName": "Banco: Banco Nacional de Bolivia",
    "accountNumber": "Cuenta: 1501007117",
    "accountHolder": "Titular: Luis Guillermo Mallea Morales.",
    "thanksForSupport": "¡Gracias por tu apoyo!",
    "futureInYourHands": "¡El futuro del medio ambiente de Bolivia está en tus manos! Con tu donación, podemos seguir trabajando para crear un futuro más sostenible para nuestro país.",
    "stayUpdated": "Para mantenerte actualizado y saber que tu donación hizo algo por el medio ambiente, siguenos en nuestras redes sociales.",
    "Propacha": "Propacha",
    "Donaciones": "Donaciones",
    "recognitionsAndAwards": "Reconocimientos y premios",
    "currentMembers": "Miembros actuales",
    "activitiesDoneForEnvironment": "Actividades realizadas",
    "alliances": "Alianzas",
    "home-fire":{
        "p1":{
            title:"Proyecto Plan Piloto para la Reactivación Turística Ambiental en el  Municipio de Escoma",
            description:"Nuestro proyecto de Reactivación Turística Sostenible en el Municipio de Escoma se desarrolló en los meses de junio a septiembre de 2023. El objetivo del proyecto fue potenciar las actividades turísticas en el municipio de la mano de la reactivación de actividades productivas con la revitalización de invernaderos de las Unidades Educativas “Sañuta” y “Villa Puni” en las comunidades de Península de Challapata y Villa Puni. Este proyecto logró beneficiar a más de 350 personas de la comunidad.",
            modalcontent:"",
            
        },
        "p2":{
            title:"Foro Mundial de Bosques Urbanos FAO",
            description:"Entre el 16 y el 20 de octubre de 2023 se ha realizado el Foro Mundial de Bosques Urbanos organizado por la FAO en la ciudad de Washington, USA. Nuestro coordinador de investigación Juan Orgaz, tuvo la posibilidad de ir a realizar una presentación acerca del trabajo realizado en el municipio de La Paz sobre  conservación de espacios verdes, el reto ciudad naturaleza y los procesos de gobernanza urbana que promovemos desde diferentes espacios de incidencia y de participación con diferentes actores.",
            modalcontent:"",

        },
        "p3":{
            title:"Regional Conference of Youth RCOY Latinoamerica 2023",
            description:"La Conferencia Regional de las Juventudes es un evento anual en el marco de la Conferencia Global de las Juventudes que se realiza antes de las Conferencias de las Naciones Unidas sobre el cambio climático. En el caso de América Latina, el año 2023 se realizó en Bogotá Colombia con la participación de más de 350 jóvenes de toda la región. Movimiento Propacha tuvo la posibilidad de asistir a este evento a través de dos ",
            modalcontent:"",
            
        },
        "p4":{
            title:"Conversatorio de Economía Circular con Empresas Sostenibles",
            description:"El conversatorio de Economía Ciircular fue realizado a inicios del año 2023. Este fue llevado adelante con empresas sostenibles de la urbe y el Gobierno Autónomo Municipal de La Paz (GAMLP). El evento tuvo por objetivo fortalecer emprendedores juveniles bajo un enfoque circular, en el marco de un proyecto realizado con Fundación Educo para fortalecer la participación de los jóvenes en proyectos sostenibles y su acción por el clima. Participaron jóvenes emprendedores fortalecidos por esta iniciativa que inició el año 2022 y durante esta gestión 2024 ya se encuentra en su 3° edición.",
            modalcontent:"",

        },
        "p5":{
            title:"Programa Municipal “Escuelas Sustentables”",
            description:"En el marco de nuestro trabajo de incidencia, fuimos parte de este programa brindado por la Secretaría Municipal de Gestión Ambiental y Energías Renovables. Escuelas Sustentables es un programa de educación ambiental que pretende llegar a todas las unidades educativas del municipio de La Paz, para enseñar cinco ejes fundamentales de la resiliencia ambiental: Huertos, Forestación y arbolado, Aire y agua, Gestión integral de residuos, Energías Renovables.",
            modalcontent:"",
            
        },
        "p6":{
            title:"El Congreso Nacional de Crisis Climática",
            description:"El Congreso Nacional de Crisis Climática se ha llevado a cabo del 3 al 5 de mayo de 2023 en instalaciones del Hotel Real de la ciudad de La Paz. Ha sido un espacio impulsado por la Universidad Boliviana, la Wildlife Conservation Society (WCS), la Cooperación Suiza en Bolivia y la participación de instancias del gobierno nacional. El objetivo del congreso fue promover espacios de diálogo a través de intercambios de experiencias, reflexión, análisis de resultados de investigación, formación e iniciativas en la gestión de la crisis climática, entre actores de la academia, las instancias gubernamentales, organización civil, grupos voluntarios, así como representantes de la microempresa y empresa privada.",
            modalcontent:"",
        },
    },

    "home-water":{
        "p1":{
            title:"Taller de creación de macetas",
            description:"El día 21 de junio realizamos un taller de macetas recicladas, donde aprendimos a transformar nuestros residuos en un objeto, no solo funcional, pero también bonito como las macetas hechas de papel reciclado.",
            modalcontent:"",
            
        },
        "p2":{
            title:"Centinelas del Agua",
            description:"El proyecto inició el 22 de marzo de 2023, en conmemoración al Día Mundial del Agua, conjuntamente con la Subalcaldía Centro del Municipio de La Paz. Se trabajó con niños y adolescentes de escuelas fiscales y de convenio, teniendo un alcance de más de 10.000 niños concientizados en 25 UEs.",
            modalcontent:"",
            
        },
        "p3":{
            title:"Hampaturi",
            description:"Agosto del 2021. Visitamos las cascadas que dan una gran parte del suministro del agua a la ciudad de La Paz. Considerando la belleza del lugar, estaba muy contaminado y nosotros estábamos listos para limpiar. En la jornada también participamos en actividades con la comunidad de Hampaturi, quienes nos compartieron sus conocimientos de sistemas de cultivo y pudimos disfrutar de una jornada en contacto con la naturaleza y los huertos.",
            modalcontent:"",
            
        },
        "p4":{
            title:"Visita al Huerto Lak’a Uta",
            description:"La visita al huerto Lak’a Uta se realizó en abril del 2023, conjuntamente la Fundación Alternativas, donde tuvimos la oportunidad de ampliar nuestros conocimientos sobre agricultura urbana y sistemas orgánicos y resilientes de producción, así como la importancia de compostaje y lombricompostaje, para la reducción de nuestros residuos en casa.",
            modalcontent:"",
            
        },
        "p5":{
            title:"Taller de Educación Ambiental para personas con capacidades diferentes",
            description:"Una increíble experiencia de aprendizaje ambiental. En octubre del 2023, junto con Bolivia Indaga, se realizaron talleres de educación ambiental dirigidos al proyecto VEM, orientado a brindar espacios de aprendizaje a jóvenes con capacidades diferentes. Aprendimos sobre la importancia de la biodiversidad, la conservación de recursos y la necesidad de acciones sostenibles para un futuro mejor.",
            modalcontent:"",
            
        },
        "p6":{
            title:"Taller de Educación Ambiental",
            description:"Realizado el 21 de octubre del 2023, conjuntamente con la organización Bolivia Indaga. Se realizó con el objetivo de dar a conocer a los miembros de Propacha información referente al agua, de donde proviene el agua que consumimos, las principales causas sobre la escasez del agua y posibles soluciones.",
            modalcontent:"",
            
        },
    },

    "home-soil":{
        "p1":{
            title:"Reto Ciudad Naturaleza",
            description:"“Reto Ciudad Naturaleza” representa un esfuerzo colaborativo a nivel global para documentar las observaciones de plantas y animales en entornos urbanos y naturales. La ciudad de La Paz ha obtenido el reconocimiento mundial en dos ocasiones, demostrando su compromiso con la conservación y la biodiversidad urbana. Propacha fue parte de este concurso dos años consecutivos, aportando a este logro.",
            modalcontent:"",
            
        },
        "p2":{
            title:"1ra Gala de Premios Propacha",
            description:"La Gala de Premios Propacha es un evento elegante y solidario que reconoce la excelencia en la responsabilidad social empresarial y el compromiso ambiental. Es una noche donde se recaudan fondos para causas nobles y se premia el esfuerzo de empresas y personas en la construcción de un mundo más sostenible.",
            modalcontent:"",
            
        },
        "p3":{
            title:"Jornadas de reforestación con especies nativas",
            description:"Plantar es esencial para la salud del planeta, ya que los árboles ayudan a capturar dióxido de carbono, contribuir al ciclo del agua, evitar la erosión del suelo y proporcionan hábitats para la vida silvestre. Igualmente, estos pueden actuar como barreras naturales ante eventos climáticos extremos. Además, las plantaciones pueden ayudar a combatir la deforestación y a mantener el equilibrio ecológico para las generaciones futuras.",
            modalcontent:"",
            
        },
        "p4":{
            title:"Campaña de apoyo a bomberos forestales voluntarios",
            description:"Los incendios forestales amenazan anualmente nuestros bosques y la vida que albergan. Por esta razón, surgió la necesidad de apoyar a nuestros bomberos forestales voluntarios, quienes arriesgan sus vidas para combatir los incendios y proteger nuestra biodiversidad. Para lograr este objetivo, se realizaron diversas actividades para proporcionarles insumos y recursos económicos, garantizando que puedan desempeñar sus funciones de manera más efectiva y segura.",
            modalcontent:"",
            
        },
        "p5":{
            title:"Sembrando vida cosechando futuro 2 en conjunto con aire",
            description:"Como un primer paso en un proyecto de una restauración ecológica de un espacio emplazado en un área protegida municipal, se realizó una plantación de cerca de 500 árboles nativos. Estos no sólo servirían como albergue a fauna visitante dentro de un centro de custodia de fauna rescatada del tráfico, sino que también aportarían muchos beneficios ambientales al lugar.",
            modalcontent:"",
            
        },
        "p6":{
            title:"Expomascota",
            description:"Contar con espacios para concientizar a las personas sobre el tráfico de fauna es vital para disminuir esta gran problemática. Como parte de una invitación, pudimos participar en este evento, apoyando la educación ambiental y la conciencia sobre el tráfico y mascotismo de fauna silvestre entre la población paceña, obteniendo un gran alcance.",
            modalcontent:"",
            
        },
    },

    "home-wind":{
        "p1":{
            title:"GreenGames",
            description:"La actividad se realizo en un lapso de aproximadamente 1 mes donde los involucrador eran estudiantes de diferente unidades educativas.",
            modalcontent:"",
            
        },
        "p2":{
            title:"Reto ciudad naturaleza",
            description:"La actividad inició a las 10 am. Realizamos el registro de flora y fauna, el lugar era un espacio amplio, participaron alrededor de 25 personas, cada integrante del grupo registro alrededor de 50 Registros como mínimo.",
            modalcontent:"",
            
        },
        "p3":{
            title:"Ecotrekking: Muela del diablo",
            description:"Se realizó un ecotreking a la muela, donde al llegar a la cima se impartió cursos básicos de primeros auxilios, los participantes pudieron participar de manera práctica con los materiales y tecnicas.",
            modalcontent:"",
            
        },
        "p4":{
            title:"Ecotrekking: Laguna Arteza",
            description:"Se realizó una caminata partiendo de Bolsa negra hasta la laguna, en el regreso se recogió basura con ayuda de bolsas negras y palos.",
            modalcontent:"",
            
        },
        "p5":{
            title:"Feria de reciclaje",
            description:"La Feria inició a las 10 am. Donde varias instituciones se hicieron presentes, la feria fue organizado por la alcaldía del municipio de La Paz. Además de que estuvieron presentes los personajes emblemáticos 'Las Cebras'.",
            modalcontent:"",
            
        },
        "p6":{
            title:"Ecotrekking: BioParque Vesty Pacos",
            description:"La actividad inició a lasb 10:30 am dentro del BVP iniciamos con un recorrido guiado por los ambientes y ser parte de la nueva actividad con las llamas y alpacas. Posterior al guiado, Realizamos en la sala carteles referidos al cuidado de los animales silvestres. Se realizó 4 carteles que fueron incorporados en distintos espacios del Bioparque Vesty Pakos.",
            modalcontent:"",
            
        },
    },
    
}
