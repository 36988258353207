import { useEffect, useState } from "react";
import { madePost } from "../common/post";
import { ResponseGeneral, User } from "../common/types";
import { createEvent } from "../common/useEvent";
import { getUser } from "../common/user";
import { setItem } from "../common/useStorage";
import Footer from "../components/Footer";
import Info from "../components/Info";
import Login from "../components/Login";
import Register from "../components/Register";
import Profile from "./Profile";

const Account = () => {
    const [user, setUser] = useState(getUser() as User | null)

    const [actualAction, setActualAction] = useState((user === null ? "login" : "account") as "account" | "login" | "register");

    useEffect(() => {
        if (actualAction == "account" && user != null) {
            madePost(
                {
                    key: "get-profile",
                    user_code: user.user_code,
                },
                "account.php"
            ).then(r => {
                let res = r as ResponseGeneral;

                if (res.RESULT) {
                    setUser(res.VALUE);

                    setItem("USER", res.VALUE);
                }
            })
        }
    }, [actualAction])

    return <div className="account-page">
        {
            actualAction === "login" ?
                <Login onRegister={() => setActualAction("register")} onLoginSuccess={() => {
                    createEvent("logged");
                    setActualAction("account")
                }} />
                :
                (
                    actualAction === "register" ?
                        <Register onLogin={() => setActualAction("login")} onRegisterSuccess={() => {
                            createEvent("logged");
                            setActualAction("account")
                        }} />
                        :
                        <Profile />
                )
        }

        <Footer />
    </div>
}
export default Account;