//Get proyectos

import { homeAPI } from "./common/variables";

const axios = require("axios");

export let obtainProyectos = () => {
    const props = {
        asunto: "proyectos"
    };
    
    return new Promise((resolve, reject)=>{
        axios.post(homeAPI+"proyectos.php", props).then((resp:any)=>{
            let data = resp.data;
            resolve(data);
        }).catch((err:any)=>{
            reject(err)
        });
    });
}

/*      OBTENER EVENTOS     */
export let obtainEventos = () => {
    const props = {
        asunto: "eventos"
    };
    
    return new Promise((resolve, reject)=>{
        axios.post(homeAPI+"proyectos.php", props).then((resp:any)=>{
            let data = resp.data;
            resolve(data);
        }).catch((err:any)=>{
            reject(err)
        });
    });
}


/*      OBTENER PATROCINADORES     */
export let obtainPatrocinadores = () => {
    const props = {
        asunto: "patrocinadores"
    };
    
    return new Promise((resolve, reject)=>{
        axios.post(homeAPI+"proyectos.php", props).then((resp:any)=>{
            let data = resp.data;
            resolve(data);
        }).catch((err:any)=>{
            reject(err)
        });
    });
}


/*      VALIDAR CORREO          */
export let validarCorreo = (cr: string) => {
    let re = /^([\da-z_\.-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/;
    if (!re.exec(cr)) {
      return false;
    }
    else return true;
}

/*      ENVIAR MENSAJE      */
export let enviarMensajes = (nombre: string, correo: string, mensaje: string) => {
    const props = {
        asunto: "formulario-alianzas",
        nombre: nombre,
        correo: correo,
        mensaje: mensaje
    };
    return new Promise((resolve, reject)=>{
        axios.post(homeAPI+"contactanos.php", props).then((resp:any) => {
            resolve(resp.data);
        }).catch((err:any)=>{
            reject(err) ;
        });
    })
}

/*      ENVIAR MENSAJE      */
export let enviarMensajeVoluntario = (nombre: string, correo: string, mensaje: string) => {
    const props = {
        asunto: "formulario-voluntario",
        nombre: nombre,
        correo: correo,
        mensaje: mensaje
    };
    return new Promise((resolve, reject)=>{
        axios.post(homeAPI+"contactanos.php", props).then((resp:any) => {
            resolve(resp.data);
        }).catch((err:any)=>{
            reject(err) ;
        });
    })
}