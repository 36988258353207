import { toast } from "bulma-toast";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { cloneObject, onCatch } from "../common/functions";
import { madePost } from "../common/post";
import { Project, ResponseGeneral, User } from "../common/types";
import { getUser, isLogged, setNewElement } from "../common/user";
import { clear } from "../common/useStorage";
import { colors } from "../common/variables";
import { Title } from "../components/Components";
import Loader from "../components/mini_components/Loader";

import "../pages/Account.scss";
import "./Profile.scss";
import { ProjectCard } from "./Proyectos";
import { useTranslation } from "react-i18next";

import "../common/translation";

const Profile = () => {
    const pointsBk = [50, 100, 150, 200];
    const referents = ["Usuario", "Miembro", "Organizador", "Administrador"];

    const [user, setUser] = useState(getUser() as User | null)
    const {t} = useTranslation();

    const navigate = useNavigate();

    const [actualImage, setActualImage] = useState(user!.image as any);
    const [isChangingElement, setIsChangingElement] = useState(false);
    const [selectedElement, setSelectedElement] = useState({ isSelected: false, value: "" });
    const [projects, setProjects] = useState({ values: [] as Project[], isLoaded: false });
    const [isOpen, setIsOpen] = useState({ open: false, id: "" });
    const [isEditOpen, setIsEditOpen] = useState(false);

    const [isShowDropdown1, setIsShowDropdown1] = useState(false);
    const [isShowDropdown2, setIsShowDropdown2] = useState(false);


    const containerProfileRef = useRef({} as HTMLDivElement)
    const projectsSiteContainer = useRef({} as HTMLDivElement)

    const closeModal = () => {
        setSelectedElement({ isSelected: false, value: "" });
        setIsChangingElement(false);
    }

    const onElementSelect = (element: "fire" | "wind" | "soil" | "water" | "tv") => {
        setSelectedElement({ isSelected: true, value: element })

        toast({ message: "Actualizando tu elemento ...", type: "is-info" });

        madePost(
            {
                key: "update-element",
                email: user?.email,
                password: user?.password,
                new_element: element
            },
            "account.php"
        ).then(r => {
            let res = r as ResponseGeneral;

            if (res.RESULT) {
                toast({ message: "Tu elemento se ha actualizado correctamente", type: "is-success" });

                setNewElement(element);

                let clone: User = cloneObject(user!);
                clone.element = element;

                setUser(clone);

                closeModal()
            } else {
                toast({ message: res.EXCEPTION_MESSAGE, type: "is-danger" });

                closeModal()
            }
        }).catch(ee => {
            onCatch(ee);

            closeModal()
        })

    }

    useEffect(() => {
        if (!projects.isLoaded && isLogged()) {
            madePost(
                {
                    key: "get-projects-from-user",
                    user_code: getUser()?.user_code
                },
                "projects.php"
            ).then(r => {
                let res = r as ResponseGeneral;

                if (res.RESULT) {
                    setProjects({ values: res.VALUE, isLoaded: true });
                } else {
                    toast({ message: res.EXCEPTION_MESSAGE, type: "is-danger" });
                }
            }).catch(onCatch)
        }

    }, [projects]);

    const uploadImage = (ev: any) => {
        if (ev.target.files.length > 0) {

            if (ev.target.files[0].size <= 4194304) {
                madePost(
                    {
                        key: "update-profile-image",
                        new_image: ev.target.files![0],
                        email: user?.email,
                        password: user?.password
                    }, "image.php"
                ).then(r => {
                    let res = r as ResponseGeneral;
                    if (res.RESULT) {
                        setUser(getUser());

                        setActualImage(user?.image);

                        window.location.reload();

                        toast({ message: "La imagen se actualizó exitosamente", type: "is-success" })
                    } else {
                        toast({ message: res.EXCEPTION_MESSAGE, type: "is-danger" })
                    }
                }).catch(onCatch)
            } else {
                toast({ message: "La imagen no puede pesar más de 4MB", type: "is-danger" })
            }
        }
    }

    if (user) {
        return <div className={"profile-page " + user.element} style={{ "--color": colors[user.element] } as React.CSSProperties}>
            <div className={"bg " + user.element}></div>

            <div className="profile-content" ref={containerProfileRef}>
                <div className="info-container">
                    <div className="img-profile">
                        <img src={actualImage + "?" + ((new Date).getDay()).toString()} alt="" onError={(ev: any) => {
                            ev.target.src = "/assets/images/default-image.jpg";
                        }} />

                        <div className="update-image">
                            <i className="bi bi-pencil-fill"></i>
                            <input type="file" onChange={uploadImage} />
                        </div>
                    </div>

                    <div className="info-literal">
                        <div className="info-all">
                            <div>
                                <span>{user.name}</span>
                                <p>{user.cellphone}</p>
                                <p>{user.email}</p>
                            </div>
                            <div className="info-user-side-right-points">
                                {
                                    /**
                                    <div>
                                    <p>
                                        {user.points}
                                    </p>
                                    <i className="bi bi-star-fill"></i>
                                </div>
                                     */
                                }
                                <small>
                                    {user.hours} Horas
                                </small>
                                <small style={{ fontSize: ".7rem" }}>
                                    <b>
                                        {
                                            user.points > pointsBk[0] ?
                                                (
                                                    user.points > pointsBk[1] ?
                                                        (
                                                            user.points > pointsBk[2] ?
                                                                (
                                                                    user.points > pointsBk[3] ?
                                                                        400
                                                                        :
                                                                        <>({referents[3]})</>
                                                                )
                                                                :
                                                                <>({referents[2]})</>
                                                        )
                                                        :
                                                        <>({referents[1]})</>
                                                )
                                                :
                                                <>({referents[0]})</>
                                        }
                                    </b>
                                </small>
                            </div>
                        </div>
                        <div className=" points-progress">
                            <progress className="progress is-success mb-0 mr-2" value={user.points} max={
                                user.points > pointsBk[0] ?
                                    (
                                        user.points > pointsBk[1] ?
                                            (
                                                user.points > pointsBk[2] ?
                                                    (
                                                        user.points > pointsBk[3] ?
                                                            400
                                                            :
                                                            pointsBk[3]
                                                    )
                                                    :
                                                    pointsBk[2]
                                            )
                                            :
                                            pointsBk[1]
                                    )
                                    :
                                    pointsBk[0]
                            }></progress>
                            <small>
                                {user.points}/
                                {
                                    user.points > pointsBk[0] ?
                                        (
                                            user.points > pointsBk[1] ?
                                                (
                                                    user.points > pointsBk[2] ?
                                                        (
                                                            user.points > pointsBk[3] ?
                                                                400
                                                                :
                                                                pointsBk[3]
                                                        )
                                                        :
                                                        pointsBk[2]
                                                )
                                                :
                                                pointsBk[1]
                                        )
                                        :
                                        pointsBk[0]
                                }
                                <i className="bi bi-star-fill"></i>

                                <div className={"dropdown " + (isShowDropdown1 ? "is-active" : "")} style={{ zIndex: 1000 }} onClick={() => {
                                    setIsShowDropdown1(!isShowDropdown1);
                                }}>
                                    <div className="dropdown-trigger">
                                        <button onClick={() => {

                                        }} className=""
                                            style={{ border: "none", backgroundColor: "white", cursor: "pointer" }}
                                            aria-haspopup="true" aria-controls="dropdown-menu">
                                            <i className="bi bi-three-dots-vertical"></i>
                                        </button>
                                    </div>
                                    <div className="dropdown-menu" id="dropdown-menu" role="menu" style={{ left: "unset", right: "0" }}  >
                                        <div
                                            onClick={() => {
                                                if ((user.points > pointsBk[0])) {
                                                    //navigate("/certificate", {state: user})
                                                    let newwindow = window.open("/certificate.html");
                                                    (newwindow!['user' as any] as any) = user;

                                                    newwindow?.print();

                                                } else {
                                                    toast({ message: "No tienes los puntos suficientes todavía, necesitas: " + (pointsBk[0] - user.points) + " puntos", type: "is-danger" });
                                                }
                                            }}
                                            className="dropdown-content"
                                            style={{ zIndex: "unset" }}>
                                            <a href="#" className="dropdown-item">
                                                Solicitar certificado
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </small>
                        </div>
                        <div className="toolbar-profile mb-0 mt-2">
                            {/*
                    
                    <button className="has-icons-right">Analizar QR
                        <i className={"bi has-text-black icon is-small is-right bi-qr-code-scan"}></i>
                    </button>
                    */}
                            <button disabled={user.points < 50}
                                className="has-icons-right with-med"
                                onClick={(user.points < 50 ? () => { } : () => { setIsChangingElement(true) })}>Cambiar elemento
                                {/*
                                    user.points ?
                                        user.points <= 50 ?
                                            <small>(Te faltan {50 - (user.points ? user.points : 0)} puntos)</small>
                                            :
                                            <></>
                                        :
                                        <><small>(Te faltan 50 puntos)</small></>
                                */}
                                <i className={"bi has-text-black icon is-small is-right bi-fire"}></i>

                                <div className="med-line" aria-points={user.points ? user.points : 0} style={{ "--points": (user.points ? user.points : 0), "--aria-percent": ((user.points ? ((user.points / 50) * 100).toString() + "%" : "0%")) } as React.CSSProperties}></div>
                            </button>
                            {
                                user.role == "admin" ?
                                    <>


                                        <div className={"dropdown button " + (isShowDropdown2 ? "is-active" : "")} style={{ zIndex: 1000 }} onClick={() => {
                                            setIsShowDropdown2(!isShowDropdown2);
                                        }}>
                                            <div className="dropdown-trigger">
                                                <button onClick={() => {

                                                }} className="has-icons-right"
                                                    style={{ border: "none", backgroundColor: "transparent", cursor: "pointer" }}
                                                    aria-haspopup="true" aria-controls="dropdown-menu">
                                                        Gestionar
                                                        <i className={"bi bi-card-checklist"}></i>
                                                </button>
                                            </div>
                                            <div className="dropdown-menu" id="dropdown-menu" role="menu" style={{ left: "unset", right: "0" }}  >
                                                <div
                                                    className="dropdown-content"
                                                    style={{ zIndex: "unset" }}>
                                                    <a href="/admin" className="dropdown-item">
                                                        Proyectos
                                                    </a>
                                                    <a href="/admin-links" className="dropdown-item">
                                                        Enlaces
                                                    </a>
                                                </div>
                                            </div>
                                        </div>

                                    </>
                                    : <></>
                            }
                            <button className="has-icons-right"
                                onClick={() => {
                                    clear();
                                    navigate("/inicio");
                                }}
                            >Cerrar sesión
                                <i className={"bi has-text-black icon is-small is-right bi-door-open"}></i>
                            </button>
                        </div>

                    </div>

                    <i className="bi bi-pencil edit-info" onClick={() => {
                        setIsEditOpen(true);
                    }}></i>
                </div>


            </div>

            <div className="profile-edit" style={isEditOpen ? { display: "block" } : { display: "none" }}>

                <Title sub="Información" sup="Actualiza tu" color="green" />

                <div className="field pt-4 mt-1 mb-1">
                    <div className="is-flex is-flex-row is-align-items-center is-justify-content-space-between mb-1">
                        <label className="label m-0">Nombre completo</label>
                        {
                            isOpen.id == "2" && isOpen.open ?
                                <button className="button" color="green" style={{ fontSize: ".7rem" }} onClick={() => {
                                    setIsOpen({ open: false, id: "" });
                                    toast({ type: "is-info", message: "Actualizando la información" });

                                    madePost(
                                        {
                                            key: "update",
                                            t: "name",
                                            user_code: getUser()?.user_code,
                                            tk: getUser()?.password,
                                            change: (document.getElementById("name")! as HTMLTextAreaElement).value
                                        },
                                        "account.php"
                                    ).then(r => {
                                        toast({ type: "is-success", message: "Tu información se ha actualizado" });
                                    }).catch(r => {
                                        toast({ type: "is-danger", message: "Ha ocurrido un error" });
                                    })
                                }}>Guardar</button>
                                :
                                <button className="button" color="green" style={{ fontSize: ".7rem" }} onClick={() => {
                                    setIsOpen({ open: true, id: "2" })
                                }}>Cambiar</button>
                        }
                    </div>
                    <div className={"control expanded-com " + ((isOpen.id == "2" && isOpen.open) ? "is-flex" : "")}>
                        <input id="name" className="input" placeholder="Tu nombre" />
                    </div>
                </div>

                <hr style={{ margin: 0, backgroundColor: "rgba(0,0,0,0.1)" }} />

                <div className="field mt-1 mb-1">
                    <div className="is-flex is-flex-row is-align-items-center is-justify-content-space-between mb-1">
                        <label className="label m-0">Celular</label>
                        {
                            isOpen.id == "3" && isOpen.open ?
                                <button className="button" color="green" style={{ fontSize: ".7rem" }} onClick={() => {
                                    setIsOpen({ open: false, id: "" });
                                    toast({ type: "is-info", message: "Actualizando la información" });

                                    madePost(
                                        {
                                            key: "update",
                                            t: "cellphone",
                                            user_code: getUser()?.user_code,
                                            tk: getUser()?.password,
                                            change: (document.getElementById("cellphone")! as HTMLTextAreaElement).value
                                        },
                                        "account.php"
                                    ).then(r => {
                                        toast({ type: "is-success", message: "Tu información se ha actualizado" });
                                    }).catch(r => {
                                        toast({ type: "is-danger", message: "Ha ocurrido un error" });
                                    })
                                }}>Guardar</button>
                                :
                                <button className="button" color="green" style={{ fontSize: ".7rem" }} onClick={() => {
                                    setIsOpen({ open: true, id: "3" })
                                }}>Cambiar</button>
                        }
                    </div>
                    <div className={"control expanded-com " + ((isOpen.id == "3" && isOpen.open) ? "is-flex" : "")}>
                        <input id="cellphone" className="input" placeholder="Tu celular" />
                    </div>
                </div>

                <hr style={{ margin: 0, backgroundColor: "rgba(0,0,0,0.1)" }} />

                <div className="field mt-1 mb-1">
                    <div className="is-flex is-flex-row is-align-items-center is-justify-content-space-between mb-1">
                        <label className="label m-0">Acerca de tí</label>
                        {
                            isOpen.id == "1" && isOpen.open ?
                                <button className="button" color="green" style={{ fontSize: ".7rem" }} onClick={() => {
                                    setIsOpen({ open: false, id: "" });
                                    toast({ type: "is-info", message: "Actualizando la información" });

                                    madePost(
                                        {
                                            key: "update",
                                            t: "description",
                                            user_code: getUser()?.user_code,
                                            tk: getUser()?.password,
                                            change: (document.getElementById("description")! as HTMLTextAreaElement).value
                                        },
                                        "account.php"
                                    ).then(r => {
                                        toast({ type: "is-success", message: "Tu información se ha actualizado" });
                                    }).catch(r => {
                                        toast({ type: "is-danger", message: "Ha ocurrido un error" });
                                    })
                                }}>Guardar</button>
                                :
                                <button className="button" color="green" style={{ fontSize: ".7rem" }} onClick={() => {
                                    setIsOpen({ open: true, id: "1" })
                                }}>Cambiar</button>
                        }
                    </div>
                    <div className={"control expanded-com " + ((isOpen.id == "1" && isOpen.open) ? "is-flex" : "")}>
                        <textarea id="description" className="textarea" placeholder="Escribenos acerca de tí" />
                    </div>
                </div>

                <button className="button mt-4" style={{ fontSize: ".8rem", marginLeft: "auto", display: "block" }} color="" onClick={() => {
                    setIsEditOpen(false);
                }}>Cancelar</button>
            </div>

            <div className="projects-container-profile-sub" ref={projectsSiteContainer} >
                <Title sub="Actividades realizadas" sup="Tus" color="green" />
                {
                    projects.isLoaded ?
                        <>
                            {
                                projects.values.length > 0 ?
                                    <div className="cards-manager-projects">
                                        {
                                            projects.values.map((project: Project) => {
                                                try {
                                                    let participant: string[] = JSON.parse(project.participant as unknown as string);
                                                    let confirmed: string[] = JSON.parse(project.confirmed as unknown as string);
                                                    let user: User = getUser() as User;

                                                    return <ProjectCard project={project} passed={project.passed == 1 ? true : false} participant={participant.includes(user.user_code.toString())} confirmed={confirmed.includes(user.user_code.toString())} />
                                                } catch (er) {
                                                    return <ProjectCard project={project} />
                                                }

                                            })
                                        }
                                    </div>
                                    :
                                    <label htmlFor="">Todavía no has realizado ninguna actividad</label>
                            }
                        </>
                        :
                        <>
                            <Loader />
                        </>
                }

            </div>

            <div className={"modal modal-register " + (isChangingElement ? "is-active" : "")}>
                <div className="modal-background"></div>
                <div className="modal-card">

                    <section className="modal-card-body p-6">
                        <button className="delete" aria-label="close" disabled={selectedElement.isSelected} onClick={() => setIsChangingElement(false)}></button>

                        <div className="columns is-flex is-justify-content-center mb-5">
                            <div className="">
                                <h2 className="title is-5 has-text-weight-light mb-0 has-text-centered">{t('selectYour')}</h2>
                                <h1 className="title is-3  has-text-weight-bold has-text-centered">{t('element')}</h1>
                            </div>
                        </div>

                        <div className="columns elements-to-select">
                            <div className={"column " + (selectedElement.isSelected ? "only-selected" : "") + (selectedElement.value === "fire" ? " active" : "")} onClick={() => {
                                if (!selectedElement.isSelected) {
                                    onElementSelect("fire")
                                }
                            }}>
                                <div>
                                    <img src="/assets/images/elements/fire.png" alt="" className="columns  my-1 mx-0" />
                                    <h2 className="title is-5 has-text-weight-regular has-text-centered mb-0 fire">{t('fire')}</h2>
                                </div>
                            </div>
                            <div className={"column " + (selectedElement.isSelected ? "only-selected" : "") + (selectedElement.value === "soil" ? " active" : "")} onClick={() => {
                                if (!selectedElement.isSelected) {
                                    onElementSelect("soil")
                                }
                            }}>
                                <div>
                                    <img src="/assets/images/elements/soil.png" alt="" className="columns  my-1 mx-0" />
                                    <h2 className="title is-5 has-text-weight-regular has-text-centered mb-0 soil">{t('soil')}</h2>
                                </div>
                            </div>
                            <div className={"column " + (selectedElement.isSelected ? "only-selected" : "") + (selectedElement.value === "wind" ? " active" : "")} onClick={() => {
                                if (!selectedElement.isSelected) {
                                    onElementSelect("wind")
                                }
                            }}>
                                <div>
                                    <img src="/assets/images/elements/wind.png" alt="" className="columns  my-1 mx-0" />
                                    <h2 className="title is-5 has-text-weight-regular has-text-centered mb-0 wind">{t('wind')}</h2>
                                </div>
                            </div>
                            <div className={"column " + (selectedElement.isSelected ? "only-selected" : "") + (selectedElement.value === "water" ? " active" : "")} onClick={() => {
                                if (!selectedElement.isSelected) {
                                    onElementSelect("water")
                                }
                            }}>
                                <div>
                                    <img src="/assets/images/elements/water.png" alt="" className="columns  my-1 mx-0" />
                                    <h2 className="title is-5 has-text-weight-regular has-text-centered mb-0 water">{t('water')}</h2>
                                </div>
                            </div>
                            <div className={"column " + (selectedElement.isSelected ? "only-selected" : "") + (selectedElement.value === "tv" ? " active" : "")} onClick={() => {
                                if (!selectedElement.isSelected) {
                                    onElementSelect("tv")
                                }
                            }}>
                                <div>
                                    <img src="/assets/images/elements/propachatv.png" alt="" className="columns  my-1 mx-0" />
                                    <h2 className="title is-5 has-text-weight-regular has-text-centered mb-0 tv">{t('tv')}</h2>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    } else {
        return <div>
            <Navigate to={"/"} />
        </div>
    }
}

export default Profile;