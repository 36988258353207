import { MutableRefObject, useState } from "react";
import "./Input.scss";

import * as EmailValidator from 'email-validator';

const Input = (props: {
    innerRef: MutableRefObject<HTMLInputElement>,
    title: string,
    placeholder: string,
    type: "password" | "text" | "number" | "email",
    name?: string,
    onInput: (res: any) => any,
    onBlur?: (res: any) => any
}) => {

    const [type, setType] = useState(props.type);
    const [emailValid, setEmailValid] = useState(true);

    return <div className="field">
        <label className="label">{props.title}</label>
        <div className="control has-icons-right">
            <input
                name={props.name}
                className={"input " + (props.type === "email" ? (emailValid ? "is-info" : "is-danger") : "is-info")}
                type={type}
                placeholder={props.placeholder}
                ref={props.innerRef}
                onInput={(ev: any) => {
                    props.onInput(ev);

                    if (props.type === "email") {
                        if (EmailValidator.validate(ev.target.value)) {
                            setEmailValid(true)
                        } else {
                            setEmailValid(false)
                        }
                    }
                }}
                onBlur={props.onBlur ? props.onBlur : () => { }}
            />
            {
                props.type === "password" ?
                    <span className="icon is-small is-right" onClick={() => {
                        setType(type === "text" ? "password" : "text")
                    }}>
                        <i className={"bi has-text-black " + (type === "text" ? " bi-eye-slash" : " bi-eye")}></i>
                    </span>
                    :
                    <></>
            }
        </div>
    </div>
}

export default Input;