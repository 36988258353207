import "./Links.scss";
import { links } from "../common/variables";
import { LinkType } from "../common/types";
import { useEffect, useState } from "react";
import { madePost } from "../common/post";
import { onCatch } from "../common/functions";
import Loader from "../components/mini_components/Loader";

import { Helmet } from "react-helmet";

const Links = () => {
    const [isShowDropdown1, setIsShowDropdown1] = useState(false);
    const [links, setLinks] = useState({ isLoad: false, values: [] as LinkType[] });

    useEffect(() => {
        madePost(
            {
                key: "get-links"
            },
            "links.php"
        ).then(r => {
            setLinks({ isLoad: true, values: (r as any).VALUE })
        }).catch(onCatch)
    }, [])

    return <div className="links links-page">

        <div className="profile-top">
            <img src="https://propacha.org/assets/images/propacha-logo-nobg.png" alt="" />

            <div className={"dropdown " + (isShowDropdown1 ? "is-active" : "")} style={{ zIndex: 20 }} onClick={() => {
                setIsShowDropdown1(!isShowDropdown1);
            }}>
                <div className="dropdown-trigger">
                    <button onClick={() => {

                    }} className=""
                        style={{ border: "none", backgroundColor: "white", cursor: "pointer" }}
                        aria-haspopup="true" aria-controls="dropdown-menu">
                        <i className="bi bi-share " style={{ fontSize: "1.2rem" }}></i>
                    </button>
                </div>
                <div className="dropdown-menu" id="dropdown-menu" role="menu" style={{ left: "unset", right: "0" }} >
                    <div
                        className="dropdown-content"
                        style={{ zIndex: "unset" }}>
                        <a href={"https://api.whatsapp.com/send?text=" + window.location.href} className="dropdown-item">
                            <i style={{ color: "#128C7E" }} className="bi bi-whatsapp mr-2"></i>
                            WhatsApp
                        </a>
                    </div>
                    <div
                        className="dropdown-content"
                        style={{ zIndex: "unset" }}>
                        <a href={"https://www.facebook.com/sharer/sharer.php?u=" + window.location.href} className="dropdown-item">
                            <i style={{ color: "#4267B2" }} className="bi bi-facebook mr-2"></i>
                            Facebook
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <ul className="ul-links">
            {
                links.isLoad ?
                    links.values.map((l: LinkType, index: number) => {
                        return <li key={l.key}>
                            <a href={l.value}>{l.label}</a>
                        </li>
                    })
                    :
                    <Loader />
            }
        </ul>
    </div>
}

export default Links;