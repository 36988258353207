
//Development

import { LinkType } from "./types";

//export const home = "http://192.168.100.176:8080/";
export const home = "https://propacha.org/";
export const homeAPI = home + "api/";
export const homePublic = home + "";

//Production

//DBLOCAL
export const USERDB = "USER";

//Sizes
export const mobile = 768;
export const tablet = 992;
export const mini = 400;

export const colors = {
    fire: "#ff7500",
    wind: "#8dd9f1",
    soil: "#92ba01",
    water: "#0084cc",
    tv: "#faa512"
}

//Personal links

export const links: LinkType[] = [
    {
        id: 1,
        label: "Tiktok",
        value: "https://www.tiktok.com/@propacha_oficial",
        key: "tiktok"
    },
    {
        id: 2,
        label: "Facebook",
        value: "https://www.facebook.com/movimientopropacha",
        key: "fb"
    },
    {
        id: 3,
        label: "Instagram",
        value: "https://www.instagram.com/movimientopropacha/",
        key: "ig"
    },
    {
        id: 4,
        label: "LinkedIn",
        value: "https://bo.linkedin.com/company/propacha",
        key: "in"
    },
    {
        id: 5,
        label: "Twitter",
        value: "https://mobile.twitter.com/propacha",
        key: "tw"
    }
]

export let disponibleLanguages = [
    { name: 'ES', type: "es" },
    { name: 'EN', type: "en" },
];

export let languageicon = <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><path d="M24 44Q19.8 44 16.15 42.425Q12.5 40.85 9.8 38.15Q7.1 35.45 5.55 31.775Q4 28.1 4 23.9Q4 19.7 5.55 16.075Q7.1 12.45 9.8 9.75Q12.5 7.05 16.15 5.525Q19.8 4 24 4Q28.2 4 31.85 5.525Q35.5 7.05 38.2 9.75Q40.9 12.45 42.45 16.075Q44 19.7 44 23.9Q44 28.1 42.45 31.775Q40.9 35.45 38.2 38.15Q35.5 40.85 31.85 42.425Q28.2 44 24 44ZM31.9 16.5H39.4Q37.75 13.05 34.875 10.75Q32 8.45 28.25 7.5Q29.5 9.35 30.375 11.5Q31.25 13.65 31.9 16.5ZM19.2 16.5H28.9Q28.35 13.85 27.05 11.375Q25.75 8.9 24 7Q22.4 8.35 21.3 10.55Q20.2 12.75 19.2 16.5ZM7.6 28.45H15.55Q15.4 27.1 15.375 26.025Q15.35 24.95 15.35 23.9Q15.35 22.65 15.4 21.675Q15.45 20.7 15.6 19.5H7.6Q7.25 20.7 7.125 21.65Q7 22.6 7 23.9Q7 25.2 7.125 26.225Q7.25 27.25 7.6 28.45ZM19.75 40.5Q18.5 38.6 17.6 36.4Q16.7 34.2 16.1 31.45H8.6Q10.5 35 13 37.025Q15.5 39.05 19.75 40.5ZM8.6 16.5H16.15Q16.7 13.8 17.55 11.675Q18.4 9.55 19.7 7.55Q15.95 8.5 13.15 10.75Q10.35 13 8.6 16.5ZM24 41.1Q25.75 39.3 26.925 36.975Q28.1 34.65 28.85 31.45H19.2Q19.9 34.45 21.075 36.85Q22.25 39.25 24 41.1ZM18.65 28.45H29.4Q29.6 26.9 29.65 25.925Q29.7 24.95 29.7 23.9Q29.7 22.9 29.65 21.975Q29.6 21.05 29.4 19.5H18.65Q18.45 21.05 18.4 21.975Q18.35 22.9 18.35 23.9Q18.35 24.95 18.4 25.925Q18.45 26.9 18.65 28.45ZM28.3 40.45Q31.9 39.3 34.775 37Q37.65 34.7 39.4 31.45H31.95Q31.3 34.15 30.425 36.35Q29.55 38.55 28.3 40.45ZM32.4 28.45H40.4Q40.75 27.25 40.875 26.225Q41 25.2 41 23.9Q41 22.6 40.875 21.65Q40.75 20.7 40.4 19.5H32.45Q32.6 21.25 32.65 22.175Q32.7 23.1 32.7 23.9Q32.7 25 32.625 25.975Q32.55 26.95 32.4 28.45Z"/></svg>;